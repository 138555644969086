import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';
import StepsIcon from '../../../icons/StepsIcon';
import SelectIcon from '../../../common/SelectIcon';
import SVG from '../../../common/SVG';
import { useEffect, useState } from 'react';
import SelectedMultipleDate from '../../../common/SelectedMultipleDate';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useTheme } from '@emotion/react';
import StepsBarChart from './Chart';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { UserActivityContainer } from './styled';

interface Props {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

const UserActivity: React.FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const { stepsRangeSuccess } = useSelector((state: RootState) => state.home);
  const theme = useTheme();
  const { UsersDailySuccess } = useSelector((state: RootState) => state.home);

  const { isMobile } = useBreakpoint();

  const [data, setData] = useState([
    ['Step Range', 'Steps'],
    ['', 0],
    ['', 0],
    ['', 0],
    ['', 0],
  ]);

  const options = {
    pieHole: 0.4,
    is3D: false,
    colors: [
      theme.colors.primary,
      theme.colors.green,
      theme.colors.yellow,
      theme.colors.orange,
    ],
    tooltip: { trigger: 'none' },
  };

  useEffect(() => {
    if (stepsRangeSuccess !== null) {
      let newData = [
        ['Step Range', 'Steps'],
        ['', 0],
        ['', 0],
        ['', 0],
        ['', 0],
      ]; // Initialize with small values

      // Determine which range the stepsSum falls into and update the corresponding value in newData
      if (stepsRangeSuccess.stepsSum >= 15000) {
        newData[1][1] = 100; // 'Very High: 15K+ steps'
      } else if (stepsRangeSuccess.stepsSum >= 10000) {
        newData[2][1] = 100; // 'High: 10-15K steps'
      } else if (stepsRangeSuccess.stepsSum >= 5000) {
        newData[3][1] = 100; // 'Moderate: 5-10K steps'
      } else {
        newData[4][1] = 100; // 'Low: 0-5K steps'
      }

      setData(newData);
    }
  }, [stepsRangeSuccess]);

  return (
    <UserActivityContainer>
      <div className="header">
        <h1>User Activity</h1>
      </div>
      <div className="analytics">
        <div className="left-side">
          <h1>Activity Distribution</h1>
          <p>
            Based on your daily average during the timeFrame selected Data only
            includes users synced during that timeFrame.
          </p>
          <div className="filter">
            <div className="step-icon">
              <SelectIcon
                children={
                  <SVG icon={<StepsIcon />} width="20px" height="20px" />
                }
              />
            </div>
            {/* <SelectedMultipleDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} /> */}
          </div>
          {UsersDailySuccess && <StepsBarChart data={UsersDailySuccess.data} />}
        </div>
        <div className="right-side">
          <h1>Step Ranges</h1>
          <p>
            Based on your daily average during the timeFrame selected Data only
            includes users synced during that timeFrame.
          </p>
          <div className="filter">
            <SelectedMultipleDate
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className="chart-container">
            <Chart
              chartType="PieChart"
              className="non-interactive-chart"
              width={isMobile ? '300px' : '500px'}
              height="300px"
              data={data}
              options={options}
            />
            <div className="legend">
              <div className="legend-item">
                <span className="legend-color very-high"></span>
                <span className="legend-label">Very High: 15K+ steps</span>
              </div>
              <div className="legend-item">
                <span className="legend-color high"></span>
                <span className="legend-label">High: 10-15K steps</span>
              </div>
              <div className="legend-item">
                <span className="legend-color moderate"></span>
                <span className="legend-label">Moderate: 5-10K steps</span>
              </div>
              <div className="legend-item">
                <span className="legend-color low"></span>
                <span className="legend-label">Low: 0-5K steps</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserActivityContainer>
  );
};

export default UserActivity;

const TargetIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_2)">
        <path
          d="M12.4719 450.398C12.4719 494.619 21.1819 538.408 38.1046 579.263C55.0274 620.118 79.8315 657.24 111.101 688.509C142.37 719.778 179.492 744.582 220.347 761.505C261.202 778.428 304.99 787.138 349.212 787.138C393.433 787.138 437.221 778.428 478.076 761.505C518.931 744.582 556.053 719.778 587.323 688.509C618.592 657.24 643.396 620.118 660.319 579.263C677.241 538.408 685.951 494.619 685.951 450.398C685.951 406.177 677.241 362.389 660.319 321.533C643.396 280.678 618.592 243.556 587.323 212.287C556.053 181.018 518.931 156.214 478.076 139.291C437.221 122.368 393.433 113.658 349.212 113.658C304.99 113.658 261.202 122.368 220.347 139.291C179.492 156.214 142.37 181.018 111.101 212.287C79.8315 243.556 55.0274 280.678 38.1046 321.533C21.1819 362.389 12.4719 406.177 12.4719 450.398Z"
          fill="white"
        />
        <path
          d="M349.212 799.61C156.522 799.61 1.52588e-05 643.088 1.52588e-05 450.398C1.52588e-05 257.708 156.522 101.187 349.212 101.187C541.902 101.187 698.423 257.708 698.423 450.398C698.423 643.088 541.902 799.61 349.212 799.61ZM349.212 126.13C170.241 126.13 24.9437 271.427 24.9437 450.398C24.9437 629.369 170.241 774.666 349.212 774.666C528.183 774.666 673.48 629.369 673.48 450.398C673.48 271.427 528.183 126.13 349.212 126.13Z"
          fill="#191919"
        />
        <path
          d="M96.0332 450.398C96.0332 483.646 102.582 516.568 115.305 547.285C128.029 578.002 146.678 605.913 170.187 629.422C193.697 652.932 221.607 671.581 252.324 684.304C283.041 697.028 315.964 703.577 349.212 703.577C382.459 703.577 415.382 697.028 446.099 684.304C476.816 671.581 504.726 652.932 528.236 629.422C551.746 605.913 570.395 578.002 583.118 547.285C595.841 516.568 602.39 483.646 602.39 450.398C602.39 417.15 595.841 384.228 583.118 353.511C570.395 322.794 551.746 294.884 528.236 271.374C504.726 247.864 476.816 229.215 446.099 216.492C415.382 203.768 382.459 197.22 349.212 197.22C315.964 197.22 283.041 203.768 252.324 216.492C221.607 229.215 193.697 247.864 170.187 271.374C146.678 294.884 128.029 322.794 115.305 353.511C102.582 384.228 96.0332 417.15 96.0332 450.398Z"
          fill="#F86C6C"
        />
        <path
          d="M349.212 716.672C202.667 716.672 83.5614 597.566 83.5614 451.022C83.5614 304.478 202.667 185.372 349.212 185.372C495.756 185.372 614.862 304.478 614.862 451.022C614.862 597.566 495.756 716.672 349.212 716.672ZM349.212 209.692C216.386 209.692 108.505 317.573 108.505 450.398C108.505 583.223 216.386 691.105 349.212 691.105C482.037 691.105 589.918 583.223 589.918 450.398C589.918 317.573 482.037 209.692 349.212 209.692Z"
          fill="#191919"
        />
        <path
          d="M186.454 450.398C186.454 493.564 203.602 534.962 234.125 565.485C264.648 596.008 306.046 613.156 349.212 613.156C392.378 613.156 433.776 596.008 464.299 565.485C494.822 534.962 511.969 493.564 511.969 450.398C511.969 407.232 494.822 365.834 464.299 335.311C433.776 304.788 392.378 287.641 349.212 287.641C306.046 287.641 264.648 304.788 234.125 335.311C203.602 365.834 186.454 407.232 186.454 450.398Z"
          fill="white"
        />
        <path
          d="M349.212 626.251C252.555 626.251 173.982 547.679 173.982 451.022C173.982 354.365 252.555 275.169 349.212 275.169C445.868 275.169 524.441 353.741 524.441 450.398C524.441 547.055 445.868 626.251 349.212 626.251ZM349.212 300.112C266.274 300.112 198.926 367.46 198.926 450.398C198.926 533.336 266.274 600.684 349.212 600.684C432.149 600.684 499.497 533.336 499.497 450.398C499.497 367.46 432.149 300.112 349.212 300.112Z"
          fill="#191919"
        />
        <path
          d="M270.015 450.398C270.015 460.798 272.064 471.097 276.044 480.705C280.024 490.314 285.857 499.044 293.211 506.398C300.565 513.752 309.296 519.586 318.905 523.566C328.513 527.546 338.811 529.594 349.212 529.594C359.612 529.594 369.91 527.546 379.519 523.566C389.127 519.586 397.858 513.752 405.212 506.398C412.566 499.044 418.399 490.314 422.379 480.705C426.359 471.097 428.408 460.798 428.408 450.398C428.408 429.394 420.064 409.25 405.212 394.398C390.36 379.546 370.216 371.202 349.212 371.202C328.207 371.202 308.064 379.546 293.211 394.398C278.359 409.25 270.015 429.394 270.015 450.398Z"
          fill="#F86C6C"
        />
        <path
          d="M349.212 542.066C298.701 542.066 257.544 500.909 257.544 450.398C257.544 399.887 298.701 358.73 349.212 358.73C399.723 358.73 440.88 399.887 440.88 450.398C440.88 501.533 399.723 542.066 349.212 542.066ZM349.212 384.297C312.42 384.297 282.487 414.23 282.487 451.022C282.487 487.814 312.42 517.122 349.212 517.122C386.004 517.122 415.936 487.19 415.936 450.398C415.936 413.606 386.004 384.297 349.212 384.297Z"
          fill="#191919"
        />
        <path
          d="M786.973 89.9619L685.951 190.36L607.379 192.231L609.25 113.658L710.271 13.2601L707.154 93.0799L786.973 89.9619Z"
          fill="#C2CDDC"
        />
        <path
          d="M608.002 204.703C604.884 204.703 601.766 203.456 599.272 200.961C596.778 198.467 595.53 195.349 595.53 191.607L597.401 113.035C597.401 109.917 598.648 106.799 601.143 104.305L702.165 3.9062C705.906 0.164643 711.519 -0.458948 715.884 1.41183C720.872 3.2826 723.367 8.27134 723.367 13.2601L720.872 79.3608L786.973 76.8665C791.962 76.8665 796.951 79.3608 798.822 84.3496C800.692 89.3383 800.069 94.3271 796.327 98.0686L695.305 198.467C692.811 200.961 689.693 202.208 686.575 202.208L608.002 204.703ZM621.721 119.271L620.474 179.759L680.963 178.512L755.794 103.681L707.777 105.552C704.036 105.552 700.918 104.305 698.423 101.81C695.929 99.3158 694.682 96.1978 694.682 92.4563L696.552 44.4397L621.721 119.271Z"
          fill="#191919"
        />
        <path
          d="M355.448 453.516C352.33 453.516 349.212 452.269 346.717 449.775C341.728 444.786 341.728 437.303 346.717 432.314L757.041 21.9904C762.03 17.0016 769.513 17.0016 774.501 21.9904C779.49 26.9791 779.49 34.4622 774.501 39.4509L364.178 449.775C361.683 452.269 358.565 453.516 355.448 453.516Z"
          fill="#191919"
        />
        <path
          d="M735.839 147.332H661.631C654.772 147.332 649.159 141.72 649.159 134.861V61.9003C649.159 55.0407 654.772 49.4284 661.631 49.4284C668.491 49.4284 674.103 55.0407 674.103 61.9003V122.389H735.839C742.698 122.389 748.311 128.001 748.311 134.861C748.311 141.72 742.698 147.332 735.839 147.332Z"
          fill="#191919"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_2">
          <rect width="800" height="800" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TargetIcon;

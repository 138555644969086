type Props = {
  dashboard: {
    name: string;
    path: string;
  };
  users: {
    name: string;
    path: string;
  };
  challenges: {
    name: string;
    path: string;
  };
  reporting: {
    name: string;
    path: string;
  };
};

export const navbarMapping: Props = {
  dashboard: {
    name: 'Dashboard',
    path: '/',
  },
  users: {
    name: 'Users',
    path: '/users',
  },
  challenges: {
    name: 'Challenges',
    path: '/challenges',
  },
  reporting: {
    name: 'Reporting',
    path: '/reporting',
  },
};

import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

interface StyleProps {
  color?: string;
  bgcolor?: string;
  borderradius?: string;
}

const LinkButtonContainer = styled(Link)<StyleProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
  border-radius: ${({ borderradius }) => borderradius};
  cursor: pointer;
  transition: background-color 0.3s;
  span {
    color: ${({ color }) => color};
  }
`;

interface Props {
  label: string;
  link: string;
  color?: string;
  bgcolor?: string;
  borderradius?: string;
  icon?: React.ReactNode;
}

const LinkButton: React.FC<Props> = ({
  label,
  link,
  color,
  bgcolor,
  borderradius,
  icon,
}) => {
  return (
    <LinkButtonContainer
      to={link}
      color={color}
      bgcolor={bgcolor}
      borderradius={borderradius}
    >
      {icon}
      <span>{label}</span>
    </LinkButtonContainer>
  );
};

export default LinkButton;

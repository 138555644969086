import { DataGrid, GridRowsProp, GridRowId } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import IconButton from '../../../common/buttons/IconButton';
import SVG from '../../../common/SVG';
import MinusIcon from '../../../icons/MinusIcon';
import { useTheme } from '@emotion/react';
import DownArrowIcon from '../../../icons/DownArrowIcon';
import { UserType } from '../../../../types/user/user.type';
import FilterInput from '../../../common/inputs/FilterInput';
import { removeEmployees } from '../../../../store/userPage/userPageAction';
import { useDispatch } from 'react-redux';
import { TableWrapper } from './style';
import { useUserTable } from '../../../hooks/useUserTable';

interface Props {
  departmentId: number;
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  setDepartmentId: React.Dispatch<React.SetStateAction<number>>;
}

const TableContainer: React.FC<Props> = ({
  departmentId,
  location,
  setLocation,
  setDepartmentId,
}) => {
  const [active, setActive] = useState(false);
  const { employeesSuccess, employeesLoading, departmentsSuccess } =
    useSelector((state: RootState) => state.userPage);

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { columns } = useUserTable();

  const theme = useTheme();
  const [rows, setRows] = useState<GridRowsProp>([]);

  const mapEmployeesToRows = (employees: UserType[]) => {
    return employees.map((employee) => ({
      id: employee.userprofile.id, // Use userprofile.id as the unique identifier
      avatar: employee.userprofile.avatar,
      userprofile: employee.userprofile.id,
      username: employee.userprofile.username,
      email: employee.userprofile.user.email,
    }));
  };
  useEffect(() => {
    if (employeesSuccess) {
      const mappedRows = mapEmployeesToRows(employeesSuccess);
      setRows(mappedRows);
    }
  }, [employeesSuccess]);

  return (
    <TableWrapper>
      <div className="filters">
        <div className="filter">
          <div className="filter-header">
            <h1>Department</h1>
            <div className="dropdown-container">
              <SVG
                icon={
                  <DownArrowIcon
                    active={active}
                    onClick={() => setActive(!active)}
                  />
                }
                width="20px"
                height="20px"
                color={theme.colors.text}
                cursor="pointer"
              />
            </div>
          </div>
          {active && (
            <ul>
              {departmentsSuccess &&
                departmentsSuccess.map((department: any) => (
                  <li key={department.department_id}>
                    <input
                      type="radio"
                      name={`department_${department.department_id}`} // Use a unique name for each radio group
                      value={department.department_id}
                      checked={department.department_id === departmentId} // Check if value matches departmentId
                      onChange={() => setDepartmentId(department.department_id)}
                    />
                    <span>{department.department.name}</span>
                  </li>
                ))}
            </ul>
          )}
        </div>
        <div className="filter">
          <div className="filter-header input-filter">
            <h1>Location</h1>
            <FilterInput
              width="100%"
              height="40px"
              placeholder="Search Location..."
              value={location}
              setValue={setLocation}
            />
          </div>
        </div>
      </div>
      <div className="table">
        <div className="header">
          <h3>{selectionModel.length} users selected</h3>
          <div className="icon-container">
            <IconButton
              icon={
                <SVG
                  icon={<MinusIcon />}
                  width="20px"
                  height="20px"
                  color={theme.colors.secondary}
                />
              }
              text="Remove"
              border={`2px solid ${theme.colors.secondary}`}
              onClick={() => {
                dispatch(removeEmployees({ ids: selectionModel }));
                window.location.reload();
                // alert('Not Implemented');
              }}
            />
          </div>
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          checkboxSelection
          loading={employeesLoading}
          onRowSelectionModelChange={(newSelection: GridRowId[]) => {
            setSelectionModel(newSelection);
          }}
          rowSelectionModel={selectionModel}
          sx={{
            borderTop: 'none',
            backgroundColor: theme.colors.white,
          }}
        />
      </div>
    </TableWrapper>
  );
};

export default TableContainer;

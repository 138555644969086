import React from 'react';
import styled from '@emotion/styled';
import { mobile } from '../../../../../styles/media';

interface ReportCardProps {
  title: string;
  description: string;
}

interface propType {
  isTrue: boolean;
}

const Symbol = styled.span<propType>`
  color: ${({ isTrue, theme }) =>
    isTrue ? theme.colors.green : theme.colors.error};
`;

const CardWrapper = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: start;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.iceBlue};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  margin-bottom: 1rem;
  ${mobile(`margin: 1rem;`)}

  .row-wrapper-container {
    display: flex;
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    ${mobile(`flex-direction: column; padding: 0.5rem; gap: 0.5rem;`)}
    .column-title-container {
      display: flex;
      justify-content: start;
      flex-direction: column;
      span {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
        color: ${({ theme }) => theme.colors.gray};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      }
      h4 {
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
      }
    }
    button {
      width: 150px;
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      background-color: ${({ theme }) => theme.colors.primary};
      border: 2px solid ${({ theme }) => theme.colors.primary};
      padding: 0.7rem 0;
      border-radius: 0.7rem;
      cursor: pointer;
      ${mobile(`
        padding: 0.5rem 0;
    `)}
      transition: background-color 0.3s;
      &:hover {
        border: 2px solid ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.link};
      }
    }
    .paragraph-box {
      width: 40%;
      font-size: 0.9rem;
      text-align: justify;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.lightText};
      ${mobile(`width: 100%;`)}
    }

    .check-details-container {
      width: 260px;
      background-color: ${({ theme }) => theme.colors.iceBlue};
      font-size: 0.8rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      color: ${({ theme }) => theme.colors.lightText};
      border: 2px solid ${({ theme }) => theme.colors.iceBlue};
      border-radius: ${({ theme }) => theme.borderRadius.s};

      ${mobile(`width: 100%;`)}

      h3 {
        padding: 0.7rem;
        text-transform: uppercase;
        font-size: ${({ theme }) => theme.fontSizes.s};
        color: ${({ theme }) => theme.colors.text};
        font-weight: 600;
      }
      display: flex;
      flex-direction: column;

      .symbol-title-wrapper {
        display: flex;
        gap: 0.3rem;
        flex-direction: row;
      }
      .table-row {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.2rem 1rem;
      }
    }
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.iceBlue};
  }
`;

const ReportCard: React.FC<ReportCardProps> = ({ title, description }) => {
  const tableData = [
    {
      sentence1: 'Sentence 1',
      isTrue1: false,
      sentence2: 'Sentence 2',
      isTrue2: false,
    },
    {
      sentence1: 'Sentence 3',
      isTrue1: true,
      sentence2: 'Sentence 4',
      isTrue2: false,
    },
    {
      sentence1: 'Sentence 5',
      isTrue1: true,
      sentence2: 'Sentence 6',
      isTrue2: true,
    },
    {
      sentence1: 'Sentence 7',
      isTrue1: true,
      sentence2: 'Sentence 8',
      isTrue2: false,
    },
    {
      sentence1: 'Sentence 7',
      isTrue1: true,
      sentence2: 'Sentence 8',
      isTrue2: false,
    },
    {
      sentence1: 'Sentence 7',
      isTrue1: true,
      sentence2: 'Sentence 8',
      isTrue2: false,
    },
  ];

  return (
    <CardWrapper>
      <div className="row-wrapper-container">
        <div className="column-title-container">
          <span>User Report</span>
          <h4>{title}</h4>
        </div>
        <button>{title}</button>
      </div>
      <div className="line" />
      <div className="row-wrapper-container">
        <p className="paragraph-box">{description}</p>
        <div className="check-details-container">
          <h3>Data Included:</h3>
          {tableData.map((row, index) => (
            <div key={index} className="table-row">
              <div className="symbol-title-wrapper">
                <Symbol isTrue={row.isTrue1}>{row.isTrue1 ? '✓' : '✗'}</Symbol>

                <span>{row.sentence1}</span>
              </div>
              <div className="symbol-title-wrapper">
                <Symbol isTrue={row.isTrue2}>{row.isTrue2 ? '✓' : '✗'}</Symbol>
                <span>{row.sentence2}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CardWrapper>
  );
};

export default ReportCard;

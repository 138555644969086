import styled from '@emotion/styled';
import SearchInput from '../../../common/inputs/SearchInput';
import IconButton from '../../../common/buttons/IconButton';
import SVG from '../../../common/SVG';
import MailIcon from '../../../icons/MailIcon';
import { useTheme } from '@emotion/react';
import { mobile } from '../../../../styles/media';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';

const UserSearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.link};
  ${mobile(`
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        align-items: flex-start;
    `)}
  h1 {
    color: ${({ theme }) => theme.colors.white};
    width: 20%;
  }
  .search-container {
    width: 80%;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 80%;
  }
  .button-container {
    width: 250px;
    height: 60px;
  }
`;
interface Props {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const UserSearch: React.FC<Props> = ({ search, setSearch }) => {
  return (
    <UserSearchContainer>
      <h1>Users</h1>
      <div className="content">
        <SearchInput
          placeholder="Search user"
          value={search}
          setValue={setSearch}
        />
        {/* <div className="button-container">
          <IconButton
            icon={<
              SVG
              icon={<MailIcon />}
              color="white"
              width="20px"
              height="20px"
              cursor="pointer"
            />}
            text="Invite"
            backgroundColor={theme.colors.link}
            border={`2px solid ${theme.colors.white}`}
            onClick={() => { }}
          />
        </div> */}
      </div>
    </UserSearchContainer>
  );
};

export default UserSearch;

import styled from '@emotion/styled';
import { mobile } from '../../../styles/media';

export const LoginPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  .content {
    width: 700px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${mobile(`
    width: 90%;
  `)}
    .text-wrapper {
      width: 100%;
      padding: 3rem 0 0 0;
      ${mobile(`
      padding: 2rem 0 0 0;
    `)}
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      h1 {
        font-weight: ${({ theme }) => theme.fontWeights.light} !important;
        font-size: ${({ theme }) => theme.fontSizes.xl};
        text-align: center;
        padding: 0 0.4rem;
        ${({ theme }) =>
          mobile(`
        font-size:  ${theme.fontSizes.m};
      `)}
      }
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.3rem;
        font-size: ${({ theme }) => theme.fontSizes.s};
        color: ${({ theme }) => theme.colors.gray};
        ${({ theme }) =>
          mobile(`
        font-size:  ${theme.fontSizes.xs};
      `)}
      }
      a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.3rem;
        ${({ theme }) =>
          mobile(`
        font-size:  ${theme.fontSizes.xs};
      `)}
      }
    }
    .form-input-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2rem 0 0 0;
      .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        width: 80%;
      }
      .button-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        margin: 1rem 0 0 0;
        background-color: ${({ theme }) => theme.colors.secondary};
        border-bottom-left-radius: ${({ theme }) => theme.borderRadius.s};
        border-bottom-right-radius: ${({ theme }) => theme.borderRadius.s};
        .submit-button-container {
          width: 70%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getChallenges,
  leaderBoardUsers,
  stepsGraphRange,
  stepsRange,
  userStatic,
  userStaticDaily,
} from '../../store/home/homeAction';
import useBodyOverflow from './useBodyOverFlow';

const useHomePageData = (initialDaysAgo = 5) => {
  const dispatch = useDispatch<AppDispatch>();

  // Date Range State
  const today = new Date();
  const initialDate = new Date(today);
  initialDate.setDate(today.getDate() - initialDaysAgo);

  const [startRangeDate, setStartRangeDate] = useState(
    initialDate.toISOString().slice(0, 10),
  );
  const [endRangeDate, setEndRangeDate] = useState(
    today.toISOString().slice(0, 10),
  );
  const [startDaysRangeDate, setStartDaysRangeDate] = useState(
    initialDate.toISOString().slice(0, 10),
  );
  const [endDaysRangeDate, setEndDaysRangeDate] = useState(
    today.toISOString().slice(0, 10),
  );

  // Loading State
  const { companyLoading } = useSelector((state: RootState) => state.user);

  // Initial Data Fetch
  useEffect(() => {
    dispatch(userStatic());
    dispatch(userStaticDaily());
    dispatch(getChallenges());
  }, [dispatch]);

  // Steps Data Fetch
  useEffect(() => {
    dispatch(stepsRange({ startRangeDate, endRangeDate }));
    dispatch(stepsGraphRange({ startDaysRangeDate, endDaysRangeDate }));
    dispatch(leaderBoardUsers());
  }, [
    dispatch,
    startRangeDate,
    endRangeDate,
    startDaysRangeDate,
    endDaysRangeDate,
  ]);

  useBodyOverflow(companyLoading);

  return {
    startRangeDate,
    endRangeDate,
    setStartRangeDate,
    setEndRangeDate,
    startDaysRangeDate,
    endDaysRangeDate,
    setStartDaysRangeDate,
    setEndDaysRangeDate,
    companyLoading,
  };
};

export default useHomePageData;

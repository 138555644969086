import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';

const ChallengeStepsSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  ${mobile(`padding: 1rem 0;`)}
  .steps-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid ${({ theme }) => theme.colors.textGray};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.s};
    border-right: none;
    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 200px;
      border-right: 3px solid ${({ theme }) => theme.colors.textGray};
      border-left: none;
      border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius.s};
      color: ${({ theme }) => theme.colors.textGray};
      transition: all 0.2s ease-in-out;
      white-space: nowrap;
      ${mobile(`width: 93px; height: 30px; `)}
      ${({ theme }) =>
        mobile(`
      font-size: ${theme.fontSizes.xxs};
      `)}
    }
    .active {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      border: none;
      position: relative;
      transform: scaleX(1.1) scaleY(1.1);
      border-radius: ${({ theme }) => theme.borderRadius.s};
    }
  }
`;

interface Props {
  steps: {
    step1: {
      number: number;
      title: string;
      active: boolean;
    };
    step2: {
      number: number;
      title: string;
      active: boolean;
    };
    step3: {
      number: number;
      title: string;
      active: boolean;
    };
    step4: {
      number: number;
      title: string;
      active: boolean;
    };
  };
}

const ChallengeStepsSection: React.FC<Props> = ({ steps }) => {
  return (
    <ChallengeStepsSectionContainer>
      <div className="steps-container">
        {Object.values(steps).map((step, index) => (
          <div className={'step' + (step.active ? ' active' : '')} key={index}>
            <span>
              {step.number}. {step.title}
            </span>
          </div>
        ))}
      </div>
    </ChallengeStepsSectionContainer>
  );
};

export default ChallengeStepsSection;

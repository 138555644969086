import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';

const LogoutButtonContainer = styled.button`
  width: 150px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.text};
  padding: 0.7rem 0;
  border-radius: 0.7rem;
  cursor: pointer;
  ${mobile(`
        padding: 0.5rem 0;
    `)}
  transition: background-color 0.3s;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.link};
  }
`;

interface Props {
  label: string;
  onClick: () => void;
}

const LogoutButton: React.FC<Props> = ({ label, onClick }) => {
  return (
    <LogoutButtonContainer onClick={onClick}>{label}</LogoutButtonContainer>
  );
};

export default LogoutButton;

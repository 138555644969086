import styled from '@emotion/styled';
import SVG from '../SVG';
import LoadingIcon from '../../icons/LoadingIcon';
import { useTheme } from '@emotion/react';

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingText = styled.div`
  color: white;
  font-size: 24px;
  margin-top: 16px;
`;

const LoadingScreen = ({ text = 'Loading...' }) => {
  const theme = useTheme();
  return (
    <LoadingOverlay>
      <SVG
        width="50px"
        height="50px"
        icon={<LoadingIcon />}
        color={theme.colors.white}
        cursor="pointer"
      />
      <LoadingText>{text}</LoadingText>
    </LoadingOverlay>
  );
};

export default LoadingScreen;

import React from 'react';

const CoverPlusIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13_2)">
        <path
          d="M400 0C179.1 0 0 179.075 0 400C0 620.925 179.075 800 400 800C620.925 800 800 620.925 800 400C800 179.075 620.925 0 400 0ZM400 750.8C207 750.8 50 593 50 400C50 207 207 50 400 50C593 50 750 207 750 400C750 593 593 750.8 400 750.8ZM575 375H425V225C425 211.2 413.8 200 400 200C386.2 200 375 211.2 375 225V375H225C211.2 375 200 386.2 200 400C200 413.8 211.2 425 225 425H375V575C375 588.8 386.2 600 400 600C413.8 600 425 588.8 425 575V425H575C588.8 425 600 413.8 600 400C600 386.2 588.8 375 575 375Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_2">
          <rect width="800" height="800" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoverPlusIcon;

import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface reportState {
  userStatisticLoading: boolean;
  userStatisticError: any;
  userStatisticSuccess: {
    joinedEmployeeCount?: number;
    activeChallengeMembersCount?: number;
    employeesWithDailyStepsCount?: number;
    statusCode?: number;
    success?: boolean;
  };
}

const initialState: reportState = {
  userStatisticLoading: false,
  userStatisticError: null,
  userStatisticSuccess: {},
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // userStatisticRequest: (state) => {
    //   state.userStatisticLoading = true;
    //   state.userStatisticError = null;
    //   state.userStatisticSuccess = {};
    // },
    // userStatisticSuccess: (state, action) => {
    //   state.userStatisticLoading = false;
    //   state.userStatisticError = null;
    //   state.userStatisticSuccess = action.payload;
    // },
    // userStatisticFailure: (state, action) => {
    //   state.userStatisticLoading = false;
    //   state.userStatisticError = action.payload;
    // },
  },
});

export const {
  // userStatisticRequest,
  // userStatisticSuccess,
  // userStatisticFailure,
} = reportSlice.actions;

export const selectReport = (state: RootState) => state.report;

export default reportSlice.reducer;

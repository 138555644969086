import styled from '@emotion/styled';

const LineContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

const Line = () => {
  return <LineContainer />;
};

export default Line;

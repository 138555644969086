import React from 'react';

const EmailIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125 175L100 200V600L125 625H675L700 600V200L675 175H125ZM150 256.517V575H650V256.508L399.997 483.787L150 256.517ZM610.33 225H189.662L399.997 416.213L610.33 225Z"
        fill="#080341"
      />
    </svg>
  );
};

export default EmailIcon;

import styled from '@emotion/styled';
import SVG from '../../../../common/SVG';
import { useTheme } from '@emotion/react';
import { mobile } from '../../../../../styles/media';
import { Card, CardContent, Stack, Typography, Avatar } from '@mui/material';

interface Props {
  color?: string;
  icon?: React.ReactNode;
  centerNumber?: string;
  centerText?: string;
  bottomText?: React.ReactNode;
  buttonLabel?: string;
}

const UserCardContainer = styled.div<Props>`
  width: 80%;
  box-shadow:0 5px 22px 0 rgba(0, 0, 0, 0.04),0 0 0 1px rgba(0, 0, 0, 0.06)
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 200px;
  ${mobile(`
        width: 100%;
        max-width: 350px;
        height: 250px;
    `)}
  .line {
    background-color: ${({ color }) => color};
    height: 10px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.m};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.m};
    width: 100%;
  }
  .icon {
    background-color: ${({ color }) => color};
    border-radius: 50%;
    padding: 0.3rem;
    transform: translateY(1rem);
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      font-size: ${({ theme }) => theme.fontSizes.xxxxxl};
      font-weight: ${({ theme }) => theme.fontWeights.light};
      color: ${({ theme }) => theme.colors.text};
    }
    p {
      font-size: ${({ theme }) => theme.fontSizes.xs};
      font-weight: ${({ theme }) => theme.fontWeights.bolder};
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .bottom {
    width: 90%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    height: 60px;
    .text {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      font-size: ${({ theme }) => theme.fontSizes.s};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs};`)}
    }
    button {
      background-color: ${({ theme }) => theme.colors.primary};
      border: none;
      color: ${({ theme }) => theme.colors.white};
      padding: 0.5rem 1.2rem;
      border-radius: ${({ theme }) => theme.borderRadius.s};
      cursor: pointer;
      width: 150px;
      ${mobile(`
                width: 120px;
                padding: 0.5rem 0.8rem;
            `)}
    }
  }
`;

const UserCard: React.FC<Props> = ({
  color,
  icon,
  centerNumber,
  centerText,
  bottomText,
  buttonLabel,
}) => {
  return (
    <UserCardContainer color={color}>
      <Card
        sx={{
          minWidth: 350,
          width: '100%',
          height: '100%',
          borderRadius: 5,
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Stack spacing={2} style={{ width: '100%' }}>
            <Stack
              direction="row"
              className="stckrow"
              spacing={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
              }}
            >
              <Stack spacing={1}>
                <Typography color="text.secondary" variant="h6">
                  {centerText}
                </Typography>
                <Typography style={{ fontWeight: 'bold' }} variant="h4">
                  {centerNumber}
                </Typography>
              </Stack>
              <Avatar
                sx={{
                  backgroundColor: 'var(--mui-palette-success-main)',
                  height: '56px',
                  width: '56px',
                }}
              >
                {icon}
              </Avatar>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </UserCardContainer>
    /*   <UserCardContainer color={color}>
      <div className="line" />
      <div className="icon">
        <SVG
          width="20px"
          height="20px"
          icon={icon}
          color={theme.colors.white}
        />
      </div>
      <div className="center">
        <h3>{centerNumber}</h3>
        <p>{centerText}</p>
      </div>
      {/* 
            <div className="bottom">
                <div className="text">{bottomText}</div>
                <button>{buttonLabel}</button>
            </div> 

      <div />
    </UserCardContainer>*/
  );
};

export default UserCard;

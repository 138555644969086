import styled from '@emotion/styled';

interface PropsType {
  isActive: boolean;
  width: string;
}

const PrimaryButtonContainer = styled.div<PropsType>`
  width: ${({ width }) => width};
  padding: 0.5rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.text : theme.colors.white};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.white : theme.colors.text};
  border: 2px solid ${({ theme }) => theme.colors.text};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  cursor: pointer;
  transition: 0.3s all ease;
  &:hover {
    background-color: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.white};
  }
`;

interface Props {
  onClick?: () => void;
  isActive: boolean;
  text: string;
  width: string;
}

const PrimaryButton: React.FC<Props> = ({ onClick, isActive, text, width }) => {
  return (
    <PrimaryButtonContainer width={width} onClick={onClick} isActive={isActive}>
      {text}
    </PrimaryButtonContainer>
  );
};

export default PrimaryButton;

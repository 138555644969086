import styled from '@emotion/styled';
import Layout from '../../common/wrappers/Layout';
import Navbar from '../../common/navbar';
import SVG from '../../common/SVG';
import RightArrowIcon from '../../icons/RightArrowIcon';
import { useTheme } from '@emotion/react';

const ViewChallengeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  min-height: 100vh;
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.3rem;
    background-color: ${({ theme }) => theme.colors.link};
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ViewChallenge = () => {
  const theme = useTheme();
  return (
    <Layout>
      <Navbar active={'Challenges'} />
      <ViewChallengeContainer>
        <div className="header">
          <span>Challenges</span>
          <SVG
            icon={<RightArrowIcon />}
            color={theme.colors.white}
            width="17px"
            height="17px"
          />
          <span>Lily Massey</span>
        </div>
      </ViewChallengeContainer>
    </Layout>
  );
};

export default ViewChallenge;

import styled from '@emotion/styled';

interface PropsType {
  color?: string;
  backgroundColor?: string;
  border?: string;
}

const IconButtonContainer = styled.button<PropsType>`
  width: 100%;
  color: ${({ theme, color }) => (color ? color : theme.colors.white)};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.primary};
  border: none;
  padding: 0.7rem 0;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: ${({ border }) => (border ? border : `none`)};
  height: 100%;
`;

interface Props {
  icon: React.ReactNode;
  text: string;
  color?: string;
  backgroundColor?: string;
  border: string;
  onClick?: () => void;
  type?: 'button' | 'submit';
}

const IconButton: React.FC<Props> = ({
  icon,
  text,
  color,
  backgroundColor,
  border,
  onClick,
  type,
}) => {
  return (
    <IconButtonContainer
      color={color}
      backgroundColor={backgroundColor}
      border={border}
      onClick={onClick && (() => onClick())}
      type={type ? type : 'button'}
    >
      {icon}
      {text}
    </IconButtonContainer>
  );
};

export default IconButton;

import styled from '@emotion/styled';
import SelectIcon from '../../../common/SelectIcon';
import SVG from '../../../common/SVG';
import StepsIcon from '../../../icons/StepsIcon';
import SelectedMultipleDate from '../../../common/SelectedMultipleDate';
import { useEffect, useState } from 'react';
import { mobile } from '../../../../styles/media';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useTheme } from '@emotion/react';

const GroupActivityContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary};
  .wrapper {
    width: 90%;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .line {
      width: 90%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.lightGray};
    }
    .header {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 1rem 1rem 1rem;
      h1 {
        font-size: ${({ theme }) => theme.fontSizes.xl};
        color: ${({ theme }) => theme.colors.text};
        ${({ theme }) => mobile(`font-size: ${theme.fontSizes.m}`)}
      }
    }
    .body {
      width: 100%;
    }
    .date {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 1rem 0;
      ${mobile(`flex-direction: column;`)}
    }
  }
`;

interface Props {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

interface stepsRangeType {
  date: string;
  stepsSum: string;
}

const GroupActivity: React.FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const { stepsGraphDaysSuccess } = useSelector(
    (state: RootState) => state.home,
  );
  const theme = useTheme();
  const [data, setData] = useState<Array<[string, number | string]>>([
    ['Steps', '0'],
    ['Steps', 0],
  ]);

  const options = {
    title: 'Company Performance',
    curveType: 'none',
    legend: 'none',
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45, // You can adjust this value to change the slant angle
    },
    series: {
      0: { color: theme.colors.orange }, // Change the color of the line
    },
    pointsVisible: true, // Make a point at every step data
  };

  useEffect(() => {
    if (stepsGraphDaysSuccess !== null) {
      const newData: Array<[string, number | string]> = [['Steps', '0']];

      stepsGraphDaysSuccess.stepsByDate.forEach((item: stepsRangeType) => {
        const stepsSum = parseInt(item.stepsSum);
        if (stepsSum > 0) {
          newData.push([item.date, stepsSum]);
        }
      });

      setData(newData);
    }
  }, [stepsGraphDaysSuccess]);

  return (
    <GroupActivityContainer>
      <div className="wrapper">
        <div className="line" />
        <div className="header">
          <h1>Group Activity Averages</h1>
          <div className="icon-container">
            <SelectIcon
              children={<SVG icon={<StepsIcon />} width="20px" height="20px" />}
            />
          </div>
          <div className="calender-container">
            <SelectedMultipleDate
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isVertical={true}
            />
          </div>
        </div>
        <div className="body">
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </div>
      </div>
    </GroupActivityContainer>
  );
};

export default GroupActivity;

import styled from '@emotion/styled';
import { mobile, tablet } from '../../../../styles/media';
import LogoutButton from '../../../common/buttons/LogoutButton';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../../common/DefaultAvatar';

const HeaderContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  padding-left: 5rem;
  padding-right: 5rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  ${tablet(`
    height: 180px;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    `)}
  ${mobile(`
    height: 150px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    `)}
    .avatar-with-title-wrapper {
    width: 400px;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    ${mobile(`
       width: 300px;
        `)}
    ${tablet(`
       width: 350px;
        `)}
    .avatar-container {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${mobile(`
      width: 50px;
        `)}
      ${tablet(`
      width: 70px;
        `)}
      img {
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    h3 {
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.m}`)}
      color:  ${({ theme }) => theme.colors.text};
    }
  }
`;

interface props {
  existImage: string;
  userName: string;
}

const Header: React.FC<props> = ({ existImage, userName }) => {
  const { logout } = useAuth();
  return (
    <HeaderContainer>
      <div className="avatar-with-title-wrapper">
        <div className="avatar-container">
          {existImage === '' ? (
            <Avatar
              firstName={userName}
              width="90px"
              height="90px"
              fontSize="32px"
            />
          ) : (
            <img src={existImage} alt="User" />
          )}
        </div>
        <h3>{userName}</h3>
      </div>
      <LogoutButton
        label={'Log Out'}
        onClick={() => {
          logout();
        }}
      />
    </HeaderContainer>
  );
};

export default Header;

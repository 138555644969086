import { createContext, useState, useEffect, ReactNode } from 'react';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../common/loading';

interface InitialStateType {
  isLoggedIn: boolean;
  LoginFunction: (token: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<InitialStateType>({
  isLoggedIn: true,
  LoginFunction: () => {},
  logout: () => {},
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode<JwtPayload>(token);
      if (
        decodedToken &&
        decodedToken.exp &&
        decodedToken.exp * 1000 < Date.now()
      ) {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    }
    setLoading(false);
  }, []);

  const LoginFunction = (token: string) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    navigate('/');
  };

  const logout = () => {
    delete axios.defaults.headers.common['Authorization'];
    localStorage.clear();
    setIsLoggedIn(false);
    window.location.href = '/login';
  };

  if (loading) return <LoadingScreen />;

  return (
    <AuthContext.Provider value={{ isLoggedIn, LoginFunction, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteChallengesFailure,
  deleteChallengesRequest,
  deleteChallengesSuccess,
  getChallengeFailure,
  getChallengeRequest,
  getChallengeSuccess,
} from './challengePageReducer';
import { toast } from 'react-toastify';
import { GridRowId } from '@mui/x-data-grid';

export const getChallenges = createAsyncThunk(
  'challenge/getChallengesByCompany',
  async (
    data: {
      status: string;
      search: string;
    },
    { dispatch },
  ) => {
    try {
      dispatch(getChallengeRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/challenge/getChallengesByCompany?${data.status === 'Active' ? 'status=Active' : data.status === 'Completed' ? 'status=Completed' : ''}&search=${data.search}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(getChallengeSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(getChallengeFailure(err?.response?.data?.message));
      } else {
        dispatch(getChallengeFailure((err as Error).message));
      }
    }
  },
);

export const deleteChallenges = createAsyncThunk(
  'Challenge/deletechallengesbyCompany',
  async (
    data: {
      challengesIds: GridRowId[];
    },
    { dispatch },
  ) => {
    try {
      dispatch(deleteChallengesRequest());

      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/Challenge/deletechallengesbyCompany`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: {
            challengesIds: data.challengesIds,
          },
        },
      );

      if (response && response.data) {
        dispatch(deleteChallengesSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(deleteChallengesFailure(err?.response?.data?.message));
      } else {
        dispatch(deleteChallengesFailure((err as Error).message));
        toast.error((err as Error).message);
      }
    }
  },
);

import styled from '@emotion/styled';
import React from 'react';
import SVG from '../../../../common/SVG';
import RightArrowIcon from '../../../../icons/RightArrowIcon';
import { useTheme } from '@emotion/react';
import { mobile } from '../../../../../styles/media';

const CardContainer = styled.div<{ active: boolean | undefined }>`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  ${mobile(`width: 100%;flex-direction: column;`)}

  .image {
    padding: 1.2rem 0rem 1.2rem 1.2rem;
  }
  .left-side {
    padding: 1.2rem 0rem 1.2rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    h3 {
      font-size: ${({ theme }) => theme.fontSizes.xxl};
      ${mobile(`width: 100%;padding: 0rem 1rem;`)}
    }
    .description {
      line-height: 1.4rem;
      ${mobile(`width: 100%;padding: 0rem 1rem;`)}
    }
    .example {
      color: ${({ theme }) => theme.colors.textGray};
      ${mobile(`width: 100%;padding: 0rem 1rem;`)}
      b {
        color: ${({ theme }) => theme.colors.text};
      }
    }
    .categories {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      ${mobile(`width: 100%;padding: 0rem 1rem; flex-wrap: wrap;`)}
      .category {
        background-color: ${({ theme }) => theme.colors.secondary};
        color: ${({ theme }) => theme.colors.text};
        padding: 0.5rem 0.5rem;
        border-radius: ${({ theme }) => theme.borderRadius.s};
        text-transform: capitalize;
      }
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 250px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      outline: none;
      border: none;
      border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius.s};
      background-color: ${({ theme, active }) =>
        active ? theme.colors.primary : theme.colors.white};
      transition: all 0.2s ease-in-out;
      border-left: 3px solid ${({ theme }) => theme.colors.lightGrayBackground};
      text-transform: uppercase;
      cursor: pointer;
      ${mobile(`width: 100%; height: 100px;`)}

      span {
        color: ${({ theme, active }) =>
          active ? theme.colors.white : theme.colors.primary};
        font-size: ${({ theme }) => theme.fontSizes.s};
      }
      svg {
        path {
          fill: ${({ theme, active }) =>
            active ? theme.colors.white : theme.colors.white};
        }
      }
    }
    button:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      span {
        color: ${({ theme }) => theme.colors.white};
      }
      svg {
        path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;

interface props {
  icon: React.ReactNode;
  title: string;
  description: string;
  categories: string[];
  example: string;
  onClick?: () => void;
  active?: boolean;
}

const Card: React.FC<props> = ({
  icon,
  title,
  description,
  categories,
  example,
  onClick,
  active,
}) => {
  const theme = useTheme();
  return (
    <CardContainer active={active}>
      <div className="image">{icon}</div>

      <div className="left-side">
        <h3>{title}</h3>
        <p className="description">{description}</p>
        <p className="example">
          <b>Example: </b>
          {example}
        </p>
        <div className="categories">
          <span className="category-title">Good for: </span>
          {categories.map((category, index) => (
            <span className="category" key={index}>
              {category}
            </span>
          ))}
        </div>
      </div>
      <div className="right-side">
        <button onClick={onClick}>
          <span>Select</span>
          <SVG
            icon={<RightArrowIcon />}
            color={theme.colors.primary}
            width="15px"
            height="15px"
          />
        </button>
      </div>
    </CardContainer>
  );
};

export default Card;

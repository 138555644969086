import styled from '@emotion/styled';
import { toast } from 'react-toastify';

type PropsType = {
  isAllowed: boolean;
};

const NextButtonContainer = styled.button<PropsType>`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, isAllowed }) =>
    !isAllowed ? theme.colors.gray : theme.colors.primary};
  border: none;
  padding: 0.7rem 0;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 100%;
  transition: all 0.3s ease-in-out;
`;

interface props {
  onClick: () => void;
  isAllowed: boolean;
  message: string;
}

const NextButton: React.FC<props> = ({ onClick, isAllowed, message }) => {
  return (
    <NextButtonContainer
      onClick={
        isAllowed
          ? onClick
          : () => {
              toast.error(message);
            }
      }
      isAllowed={isAllowed}
    >
      Next
    </NextButtonContainer>
  );
};

export default NextButton;

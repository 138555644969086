export const dateFormatter = (date: string): string => {
  const parsedDate = new Date(date);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthIndex = parsedDate.getMonth();
  const day = parsedDate.getDate();

  return `${monthNames[monthIndex]} ${day}`;
};

export const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${month}/${day}/${year}`;
};

export const formatTimeUserProfile = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate.replace(',', ' at');
};

export const formatDateByDash = (date: string | Date) => {
  if (date instanceof Date) {
    // Format Date object to 'xx-xx-xxxx'
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  } else if (typeof date === 'string') {
    // Replace '/' with '-'
    return date.split('/').join('-');
  }
  return ''; // Return an empty string instead of null
};

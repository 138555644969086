import styled from '@emotion/styled';
import Calendar from 'react-calendar';
import { useEffect, useState } from 'react';
import SelectedMultipleDate from '../SelectedMultipleDate';
import { formatDate } from '../../../../../utils/dateFormater';
import { mobile } from '../../../../../styles/media';

const CalenderSectionContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 3rem 1rem;
  ${mobile(`width: 100%;`)}
  h1 {
    text-align: center;
  }
  .thick-line {
    width: 100%;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.text};
    margin-top: -0.8rem;
  }
  .calender-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${mobile(`overflow-x: scroll;`)}
  }
`;

const CustomCalendar = styled(Calendar)`
  border: none;
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #a0a096;
  }
  .react-calendar__tile--active {
    background-color: ${({ theme }) => theme.colors.lightPrimary};
  }
  .react-calendar__tile--rangeEnd {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .react-calendar__tile--rangeStart {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

type ValuePiece = Date | null | string;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface Props {
  timestart: ValuePiece;
  setTimestart: React.Dispatch<React.SetStateAction<ValuePiece>>;
  timeend: ValuePiece;
  setTimeend: React.Dispatch<React.SetStateAction<ValuePiece>>;
  setDidDateChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalendarSection: React.FC<Props> = ({
  timestart,
  setTimestart,
  timeend,
  setTimeend,
  setDidDateChanged,
}) => {
  const [value, onChange] = useState<Value>();

  useEffect(() => {
    if (value && Array.isArray(value) && value.length === 2) {
      const formattedStartDate =
        value[0] instanceof Date ? formatDate(value[0]) : null;
      const formattedEndDate =
        value[1] instanceof Date ? formatDate(value[1]) : null;
      setTimestart(formattedStartDate);
      setTimeend(formattedEndDate);
      if (setDidDateChanged) {
        setDidDateChanged(true);
      }
    }
  }, [value, setTimestart, setTimeend, setDidDateChanged]);

  return (
    <CalenderSectionContainer>
      <h1>Select your date range</h1>
      <div className="thick-line" />
      <div className="calender-container">
        <div className="center-content">
          <SelectedMultipleDate
            startDate={timestart ? timestart.toString() : ''}
            endDate={timeend ? timeend.toString() : ''}
            isVertical={true}
          />
        </div>
      </div>
      <CustomCalendar
        onChange={onChange}
        value={value}
        showDoubleView={true}
        selectRange={true}
        defaultValue={[
          timestart ? new Date(timestart) : null,
          timeend ? new Date(timeend) : null,
        ]}
      />
    </CalenderSectionContainer>
  );
};

export default CalendarSection;

import styled from '@emotion/styled';
import Navbar from '../../common/navbar';
import Layout from '../../common/wrappers/Layout';
import SVG from '../../common/SVG';
import RightArrowIcon from '../../icons/RightArrowIcon';
import { useTheme } from '@emotion/react';
import EmailIcon from '../../icons/EmailIcon';
import UserIcon from '../../icons/UserIcon';
import RoundedDirectionIcon from '../../icons/RoundedDirectionIcon';
import StaticItem from './StaticItem';
import LocationIcon from '../../icons/LocationIcon';
import PlusIcon from '../../icons/PlusIcon';
import PrimaryStepIcon from '../../icons/PrimaryStepIcon';
import { mobile } from '../../../styles/media';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getEmployeeById,
  removeEmployees,
} from '../../../store/userPage/userPageAction';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../common/loading';
import { formatDate, formatTimeUserProfile } from '../../../utils/dateFormater';
import CalenderIcon from '../../icons/CalenderIcon';
import StepsIcon from '../../icons/StepsIcon';
import IconButton from '../../common/buttons/IconButton';
import MinusIcon from '../../icons/MinusIcon';
import { GridRowId } from '@mui/x-data-grid';

const UserProfileContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  min-height: 100vh;
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.3rem;
    background-color: ${({ theme }) => theme.colors.link};
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  .top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem 0rem 3rem;
    ${mobile(
      `flex-direction: column; gap: 1rem; padding: 2rem 1rem 0rem 1rem; align-items: flex-end;`,
    )}
    .left-side {
      display: flex;
      align-items: center;
      gap: 2rem;
      img {
        border-radius: 50%;
        object-fit: cover;
        width: 100px;
        height: 100px;
        border: ${({ theme }) => `3px solid ${theme.colors.primary}`};
      }
      h1 {
        color: ${({ theme }) => theme.colors.text};
        font-size: ${({ theme }) => theme.fontSizes.xxxxl};
        ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xxl}`)};
      }
    }
    .right-side {
      width: 160px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
    ${mobile(`flex-direction: column; gap: 1rem; padding: 1rem;`)}
    .left-side {
      flex-grow: 1;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: ${({ theme }) => theme.borderRadius.s};
      padding: 1rem;
      ${mobile(`width: 100%; padding: 0rem;`)}
      h1 {
        color: ${({ theme }) => theme.colors.text};
        font-size: ${({ theme }) => theme.fontSizes.xl};
        text-align: center;
        margin: 1.3rem 0 1.5rem 0;
      }
      .information-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        padding: 1rem;
      }
    }
    .right-side {
      flex-grow: 1;
      width: 200px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: ${({ theme }) => theme.borderRadius.s};
      padding: 1rem;
      ${mobile(`width: 100%;`)}
      h1 {
        color: ${({ theme }) => theme.colors.text};
        font-size: ${({ theme }) => theme.fontSizes.xl};
        text-align: center;
        margin: 1.3rem 0 1.5rem 0;
      }
      .center-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        padding: 1rem;
        .item {
          display: flex;
          gap: 5rem;
          justify-content: space-between;

          span {
            color: ${({ theme }) => theme.colors.text};
            font-size: ${({ theme }) => theme.fontSizes.l};
            width: 100px;
            text-transform: uppercase;
            font-weight: ${({ theme }) => theme.fontWeights.bold};
          }
          .first-line {
            color: ${({ theme }) => theme.colors.orange};
          }
          .title {
            color: ${({ theme }) => theme.colors.gray};
            font-size: ${({ theme }) => theme.fontSizes.m};
          }
        }
        .line {
          width: 100%;
          height: 2px;
          background-color: ${({ theme }) => theme.colors.gray};
          margin: 1rem 0 0rem 0;
          border-radius: ${({ theme }) => theme.borderRadius.l};
        }
      }
    }
    .days-date-steps-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: ${({ theme }) => theme.borderRadius.s};
      padding: 1rem;
      h1 {
        text-align: center;
        padding: 2rem 0;
        font-size: ${({ theme }) => theme.fontSizes.xl};
      }
      .table {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .header {
          background-color: ${({ theme }) => theme.colors.white} !important;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10rem;
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            text-transform: uppercase;
          }
          .item-calender {
            ${mobile(`margin-left: 2rem;`)}
            span {
              color: ${({ theme }) => theme.colors.text} !important;
            }
          }
          .item-steps {
            ${mobile(`margin-right: 2rem;`)}
            span {
              color: ${({ theme }) => theme.colors.orange} !important;
            }
          }
        }
        .body-table {
          background-color: ${({ theme }) => theme.colors.white} !important;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10rem;
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.text};
          }
        }
        .line {
          width: 100%;
          height: 2px;
          background-color: ${({ theme }) => theme.colors.gray};
          border-radius: ${({ theme }) => theme.borderRadius.l};
        }
      }
    }
  }
`;

const UserProfile = () => {
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { getEmployeeByIdSuccess, getEmployeeByIdLoading } = useSelector(
    (state: RootState) => state.userPage,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    try {
      if (id) {
        dispatch(
          getEmployeeById({
            id: parseInt(id),
          }),
        );
      }
    } catch (err) {}
  }, [dispatch, id]);

  if (getEmployeeByIdLoading) {
    return <LoadingScreen text="Loading..." />;
  }

  return (
    <Layout>
      <UserProfileContainer>
        <Navbar active={'Users'} />
        <div className="header">
          <span>Users</span>
          <SVG
            icon={<RightArrowIcon />}
            color={theme.colors.white}
            width="17px"
            height="17px"
          />
          <span>{getEmployeeByIdSuccess?.Employee.username}</span>
        </div>
        <div className="top-section">
          <div className="left-side">
            <img src={getEmployeeByIdSuccess?.Employee.avatar} alt="user" />
            <h1>{getEmployeeByIdSuccess?.Employee.username}</h1>
          </div>
          <div className="right-side">
            <IconButton
              backgroundColor={theme.colors.orange}
              border="none"
              text="Remove User"
              icon={
                <SVG
                  icon={<MinusIcon />}
                  color={theme.colors.white}
                  width="20px"
                  height="20px"
                />
              }
              onClick={() => {
                // alert("Are you sure you want to remove this user?")
                dispatch(removeEmployees({ ids: [id] as GridRowId[] }));
                navigate('/users');
              }}
            />
          </div>
        </div>
        <div className="content">
          <div className="left-side">
            <h1>User Information</h1>
            <div className="information-wrapper">
              <StaticItem
                title="Email"
                value={
                  getEmployeeByIdSuccess
                    ? getEmployeeByIdSuccess?.Employee.user.email
                    : ''
                }
                icon={
                  <SVG
                    icon={<EmailIcon />}
                    color={theme.colors.gray}
                    width="25px"
                    height="25px"
                  />
                }
              />
              <StaticItem
                title="Username"
                value={
                  getEmployeeByIdSuccess
                    ? getEmployeeByIdSuccess?.Employee.username
                    : ''
                }
                icon={
                  <SVG
                    icon={<UserIcon />}
                    stockColor={theme.colors.gray}
                    width="25px"
                    height="25px"
                  />
                }
              />
              <StaticItem
                title="Last Synced"
                value={
                  getEmployeeByIdSuccess
                    ? formatTimeUserProfile(
                        new Date(
                          getEmployeeByIdSuccess?.Employee.lastConnection,
                        ),
                      )
                    : ''
                }
                icon={
                  <SVG
                    icon={<RoundedDirectionIcon />}
                    color={theme.colors.gray}
                    width="25px"
                    height="25px"
                  />
                }
              />
              <StaticItem
                title="Location"
                value={
                  getEmployeeByIdSuccess
                    ? getEmployeeByIdSuccess?.Employee.location
                    : ''
                }
                icon={
                  <SVG
                    icon={<LocationIcon />}
                    stockColor={theme.colors.gray}
                    width="25px"
                    height="25px"
                  />
                }
              />
              <StaticItem
                title="Joined"
                value={
                  getEmployeeByIdSuccess
                    ? formatDate(
                        new Date(getEmployeeByIdSuccess?.Employee.createdAt),
                      )
                    : ''
                }
                icon={
                  <SVG
                    icon={<PlusIcon />}
                    stockColor={theme.colors.gray}
                    width="25px"
                    height="25px"
                  />
                }
              />
            </div>
          </div>
          <div className="right-side">
            <h1>Activity Summary</h1>
            <div className="center-container">
              <div className="item">
                <span />
                <SVG
                  icon={<PrimaryStepIcon />}
                  color={theme.colors.orange}
                  width="40px"
                  height="40px"
                />
              </div>
              <div className="line" />
              <div className="item">
                <span className="title">
                  Today's
                  <br />
                  Activity
                </span>
                <span className="first-line">
                  {getEmployeeByIdSuccess
                    ? getEmployeeByIdSuccess?.todaySteps
                    : ''}
                </span>
              </div>
              <div className="line" />
              <div className="item">
                <span className="title">
                  30 Day
                  <br />
                  Average
                </span>
                <span className="first-line">
                  {getEmployeeByIdSuccess
                    ? getEmployeeByIdSuccess?.averageSteps.toFixed(2)
                    : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="days-date-steps-container">
            <h1>You can modify the last 30 days of data</h1>
            <div className="table">
              <div className="header">
                <div className="item item-calender">
                  <SVG
                    icon={<CalenderIcon />}
                    color={theme.colors.text}
                    width="20px"
                    height="20px"
                  />
                  <span>Date</span>
                </div>
                <div className="item item-steps">
                  <SVG
                    icon={<PrimaryStepIcon />}
                    color={theme.colors.orange}
                    width="20px"
                    height="20px"
                  />
                  <span>steps</span>
                </div>
              </div>
              <div className="line" />

              {getEmployeeByIdSuccess?.steps.map((item: any, index: any) => (
                <>
                  <div className="body-table">
                    <div className="item">
                      <span>{formatDate(new Date(item?.createdAt))}</span>
                    </div>
                    <div className="item">
                      <span>{item?.steps}</span>
                    </div>
                  </div>
                  <div className="line" />
                </>
              ))}
            </div>
          </div>
        </div>
      </UserProfileContainer>
    </Layout>
  );
};

export default UserProfile;

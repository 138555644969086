import styled from '@emotion/styled';
import Header from './Header';
import CardsContainer from './CardsContainer';

import CalenderSection from './CalenderSection';
import ChallengeInput from '../../../common/inputs/ChallengeInput';
import NextButton from '../../../common/buttons/NextButton';
import { useEffect, useState } from 'react';
import { mobile } from '../../../../styles/media';

const SelectChallengeGiftContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  .input-container {
    width: 100%;
    padding: 3rem 5rem 1rem 5rem;
    ${mobile(`padding:1rem;`)}
  }
`;

type ValuePiece = Date | null | string;

interface Props {
  timestart: ValuePiece;
  setTimestart: React.Dispatch<React.SetStateAction<ValuePiece>>;
  timeend: ValuePiece;
  setTimeend: React.Dispatch<React.SetStateAction<ValuePiece>>;
  setCreateChallengeSteps: React.Dispatch<React.SetStateAction<any>>;
  challengeStatusType: string;
  setChallengeStatusType: React.Dispatch<React.SetStateAction<string>>;
  streaks: string;
  setStreaks: React.Dispatch<React.SetStateAction<string>>;
  goal: string;
  setGoal: React.Dispatch<React.SetStateAction<string>>;
  setDidDateChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectChallengeGift: React.FC<Props> = ({
  timestart,
  setTimestart,
  timeend,
  setTimeend,
  setCreateChallengeSteps,
  challengeStatusType,
  setChallengeStatusType,
  streaks,
  setStreaks,
  goal,
  setDidDateChanged,
  setGoal,
}) => {
  const [active, setActive] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (
      challengeStatusType &&
      ((challengeStatusType === 'Streaks' &&
        streaks &&
        !isNaN(parseInt(streaks)) &&
        parseInt(streaks) > 0) ||
        (challengeStatusType !== 'Streaks' &&
          goal &&
          !isNaN(parseInt(goal)) &&
          parseInt(goal) > 0)) &&
      timestart &&
      timeend
    ) {
      setActive(true);
    } else {
      if (!challengeStatusType) setMessage('Please select challenge type');
      else if (
        challengeStatusType === 'Streaks' &&
        (!streaks || isNaN(parseInt(streaks)))
      )
        setMessage('Please enter a valid streaks goal');
      else if (challengeStatusType === 'Streaks' && parseInt(streaks) <= 0)
        setMessage('Streaks goal cannot be negative');
      else if (
        challengeStatusType !== 'Streaks' &&
        (!goal || isNaN(parseInt(goal)) || parseInt(goal) < 0)
      )
        setMessage('Please enter a valid goal');
      else if (!timestart) setMessage('Please enter start date');
      else if (!timeend) setMessage('Please enter end date');
      else setMessage('Check all fields and try again');
      setActive(false);
    }
  }, [challengeStatusType, goal, streaks, timeend, timestart]);

  return (
    <SelectChallengeGiftContainer>
      <Header />
      <CardsContainer
        challengeStatusType={challengeStatusType}
        setChallengeStatusType={setChallengeStatusType}
      />
      <div className="input-container">
        {challengeStatusType.length > 0 &&
          (challengeStatusType === 'Streaks' ? (
            <ChallengeInput
              placeholder="Enter streaks goal"
              name={'streaks'}
              type={'text'}
              error={undefined}
              value={streaks}
              onChange={(e) => setStreaks(e.target.value)}
            />
          ) : (
            <ChallengeInput
              placeholder="Enter target goal"
              name={'goal'}
              type={'text'}
              error={undefined}
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
            />
          ))}
      </div>
      <CalenderSection
        timestart={timestart}
        setTimestart={setTimestart}
        timeend={timeend}
        setTimeend={setTimeend}
        setDidDateChanged={setDidDateChanged}
      />

      <div className="next-button-container">
        <NextButton
          message={message}
          onClick={() => {
            setCreateChallengeSteps({
              step1: {
                number: 1,
                title: 'Challenge Details',
                active: false,
              },
              step2: {
                number: 2,
                title: 'Challenge Details',
                active: false,
              },
              step3: {
                number: 3,
                title: 'Challenge Details',
                active: true,
              },
              step4: {
                number: 4,
                title: 'Challenge Details',
                active: false,
              },
            });
          }}
          isAllowed={active}
        />
      </div>
    </SelectChallengeGiftContainer>
  );
};

export default SelectChallengeGift;

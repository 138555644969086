import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';
import RightArrowIcon from '../../../icons/RightArrowIcon';
import SVG from '../../../common/SVG';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import Challenge from './Challenge';
import LinkButton from '../../../common/buttons/LinkButton';
import PlusIcon from '../../../icons/PlusIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

const ChallengeContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
  .header {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 1rem 1rem 1rem;
    ${mobile(` padding: 2rem 0rem 1rem 0rem;`)}
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.xl};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.text};
      text-transform: uppercase;
      white-space: nowrap;
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.m}`)}
    }
    a {
      font-size: ${({ theme }) => theme.fontSizes.s};
      color: ${({ theme }) => theme.colors.text};
      font-weight: ${({ theme }) => theme.fontWeights.light};
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
      white-space: nowrap;
      ${mobile(`
                gap: 0.3rem;
            `)}
      span {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      }
    }
  }
  .cards-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 0 2rem;
    .create-card {
      flex-grow: 1;
      min-width: 100%;
      height: 250px;
      background-color: ${({ theme }) => theme.colors.lightGrayBackground};
      border-radius: ${({ theme }) => theme.borderRadius.s};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      h1 {
        font-size: ${({ theme }) => theme.fontSizes.l};
        color: ${({ theme }) => theme.colors.gray};
        ${({ theme }) => mobile(`font-size: ${theme.fontSizes.s}`)}
      }
      .button-container {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

interface challengesType {
  id: number;
  title: string;
  description: string;
  challengesMembers: {
    id: number;
    user_id: number;
    challenges_id: number;
    finished_steps: number;
    createdAt: string;
    updatedAt: string;
  }[];
  timestart: string;
  timeend: string;
}

const ChallengeSection = () => {
  const { challengeSuccess } = useSelector((state: RootState) => state.home);
  const theme = useTheme();

  return (
    <ChallengeContainer>
      <div className="header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h1>Recent Challenges</h1>
        </div>
        <div className="button-container">
          <LinkButton
            label="Create Challenge"
            link="/create-challenge"
            color={theme.colors.white}
            bgcolor={theme.colors.success}
            borderradius={theme.borderRadius.s}
            icon={
              <SVG
                icon={<PlusIcon />}
                color={theme.colors.white}
                stockColor={theme.colors.white}
                width="20px"
                height="20px"
              />
            }
          />
        </div>
      </div>
      <div className="cards-container">
        {challengeSuccess &&
          challengeSuccess.challenges.map((challenge: challengesType) => (
            <Challenge
              key={challenge.id}
              title={challenge.title}
              count={challenge.challengesMembers.length}
              startDate={challenge.timestart}
              endDate={challenge.timeend}
              id={challenge.id}
            />
          ))}
        {(!challengeSuccess ||
          (challengeSuccess !== null &&
            challengeSuccess.challenges.length === 0)) && (
          <div className="create-card">
            <h1>You do not have any more challenges.</h1>
            <div className="button-container">
              <LinkButton
                label="Create Challenge"
                link="/create-challenge"
                color={theme.colors.white}
                bgcolor={theme.colors.success}
                borderradius={theme.borderRadius.s}
                icon={
                  <SVG
                    icon={<PlusIcon />}
                    color={theme.colors.white}
                    stockColor={theme.colors.white}
                    width="20px"
                    height="20px"
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        ></div>
        <Link
          to="/users"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 300,
            fontWeight: 'bold',
            justifyContent: 'end',
          }}
        >
          <span>View All Challenges</span>
          <SVG
            width="15px"
            height="15px"
            icon={<RightArrowIcon />}
            color={theme.colors.text}
            cursor="pointer"
            mobileWidth="10px"
            mobileHeight="10px"
          />
        </Link>
      </div>
    </ChallengeContainer>
  );
};

export default ChallengeSection;

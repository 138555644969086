import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface settingsState {
  editUserLoading: boolean;
  editUserError: null | {
    statusCode?: number;
    success?: boolean;
    message?: string;
  };
  editUserSuccess: null | {
    statusCode?: number;
    success?: boolean;
    message?: string;
  };
}

const initialState: settingsState = {
  editUserLoading: false,
  editUserError: null,
  editUserSuccess: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    editUserRequest: (state) => {
      state.editUserLoading = true;
      state.editUserError = null;
      state.editUserSuccess = null;
    },
    editUserSuccess: (state, action) => {
      state.editUserLoading = false;
      state.editUserError = null;
      state.editUserSuccess = action.payload;
    },
    editUserFailure: (state, action) => {
      state.editUserLoading = false;
      state.editUserError = action.payload;
    },
  },
});

export const { editUserRequest, editUserSuccess, editUserFailure } =
  settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;

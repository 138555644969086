import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';

export const UserActivityContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 1rem 1rem 1rem;
    ${mobile(` padding: 2rem 0rem 1rem 0rem;`)}
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.xl};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.text};
      text-transform: uppercase;
      white-space: nowrap;
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.m}`)}
    }
  }
  .analytics {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 90%;
    padding: 2rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.s};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
    gap: 3rem;
    position: relative;
    ${mobile(`flex-direction: column;`)}
    .left-side {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      gap: 1rem;
      ${mobile(`width: 100%;`)}
      h1 {
        font-size: ${({ theme }) => theme.fontSizes.xl};
        color: ${({ theme }) => theme.colors.text};
      }
      .filter {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        align-items: center;
        ${mobile(`gap: 0.5rem;`)}
      }
    }
    .right-side {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      gap: 1rem;
      ${mobile(`width: 100%;`)}
      .non-interactive-chart {
        pointer-events: none;
      }
      h1 {
        font-size: ${({ theme }) => theme.fontSizes.xl};
        color: ${({ theme }) => theme.colors.text};
      }
      .chart-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .legend {
          background-color: ${({ theme }) => theme.colors.white};
          position: absolute;
          transform: translate(9rem, -2rem);
          gap: 1rem;
          padding-left: 2rem;
          display: flex;
          justify-content: center;
          flex-direction: column;
          ${mobile(`transform: translate(0, 7.5rem);`)}
          .legend-item {
            display: flex;
            align-items: center;
            gap: 1rem;
            width: 100%;
            .legend-color {
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
            .very-high {
              background-color: ${({ theme }) => theme.colors.primary};
            }
            .high {
              background-color: ${({ theme }) => theme.colors.green};
            }
            .moderate {
              background-color: ${({ theme }) => theme.colors.yellow};
            }
            .low {
              background-color: ${({ theme }) => theme.colors.orange};
            }
          }
        }
      }
    }
  }
  .analytics::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 90%;
    background-color: ${({ theme }) => theme.colors.lightGray};
    ${mobile(`display: none;`)}
  }
`;

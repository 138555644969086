import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  UsersDailyFailure,
  UsersDailyRequest,
  UsersDailySuccess,
  challengeFailure,
  challengeRequest,
  challengeSuccess,
  leaderBoardUsersFailure,
  leaderBoardUsersRequest,
  leaderBoardUsersSuccess,
  stepsGraphStepsFailure,
  stepsGraphStepsRequest,
  stepsGraphStepsSuccess,
  stepsRangeFailure,
  stepsRangeRequest,
  stepsRangeSuccess,
  userStatisticFailure,
  userStatisticRequest,
  userStatisticSuccess,
} from './homeReducer';

export const userStatic = createAsyncThunk(
  'company/getStatistics/1',
  async (data, { dispatch }) => {
    try {
      dispatch(userStatisticRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/company/getStatistics/${localStorage.getItem('id')}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      if (response && response.data && response.data) {
        dispatch(userStatisticSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(userStatisticFailure(err?.response?.data?.message));
      } else {
        dispatch(userStatisticFailure((err as Error).message));
      }
    }
  },
);

export const userStaticDaily = createAsyncThunk(
  'company/getDailyStepsStatistics',
  async (_, { dispatch }) => {
    try {
      dispatch(UsersDailyRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/company/getDailyStepsStatistics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      if (response && response.data) {
        dispatch(UsersDailySuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        dispatch(UsersDailyFailure(err?.response?.data?.message));
      } else {
        dispatch(UsersDailyFailure((err as Error).message));
      }
    }
  },
);

export const getChallenges = createAsyncThunk(
  'challenge/getChallengesByCompany?status=Active',
  async (data, { dispatch }) => {
    try {
      dispatch(challengeRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/challenge/getChallengesByCompany?status=Active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(challengeSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(challengeFailure(err?.response?.data?.message));
      } else {
        dispatch(challengeFailure((err as Error).message));
      }
    }
  },
);

export const stepsRange = createAsyncThunk(
  'company/getStepsRange/1',
  async (
    data: { startRangeDate: string; endRangeDate: string },
    { dispatch },
  ) => {
    try {
      dispatch(stepsRangeRequest());
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/company/getStepsRange/${localStorage.getItem('id')}`,
        {
          startDate: data.startRangeDate,
          endDate: data.endRangeDate,
        }, // Add your data object here
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(stepsRangeSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        dispatch(stepsRangeFailure(err?.response?.data?.message));
      } else {
        dispatch(stepsRangeFailure((err as Error).message));
      }
    }
  },
);

export const stepsGraphRange = createAsyncThunk(
  'company/getStepDaysatistics/1',
  async (
    data: { startDaysRangeDate: string; endDaysRangeDate: string },
    { dispatch },
  ) => {
    try {
      dispatch(stepsGraphStepsRequest());
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/company/getStepDaysatistics/${localStorage.getItem('id')}`,
        {
          startDate: data.startDaysRangeDate,
          endDate: data.endDaysRangeDate,
        }, // Add your data object here
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(stepsGraphStepsSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        dispatch(stepsGraphStepsFailure(err?.response?.data?.message));
      } else {
        dispatch(stepsGraphStepsFailure((err as Error).message));
      }
    }
  },
);

export const leaderBoardUsers = createAsyncThunk(
  'company/getcompanyLeaderBoard/13?period=daily&type=Company',
  async (data, { dispatch }) => {
    try {
      dispatch(leaderBoardUsersRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/company/getcompanyLeaderBoard/${localStorage.getItem('id')}?period=daily&type=Company`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(leaderBoardUsersSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        dispatch(leaderBoardUsersFailure(err?.response?.data?.message));
      } else {
        dispatch(leaderBoardUsersFailure((err as Error).message));
      }
    }
  },
);

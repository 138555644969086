import Layout from '../../common/wrappers/Layout';
import Navbar from '../../common/navbar';
import Header from './Header';
import UsersSection from './UsersSection';
import ChallengeSection from './ChallengeSection';
import UserActivity from './UserActivity';
import styled from '@emotion/styled';
import GroupActivity from './GroupActivity';
import SuperStars from './SuperStars';
import LoadingScreen from '../../common/loading';
import useHomePageData from '../../hooks/useHomePageData';

const HomePageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const HomePage = () => {
  const {
    startRangeDate,
    endRangeDate,
    setStartRangeDate,
    setEndRangeDate,
    startDaysRangeDate,
    endDaysRangeDate,
    setStartDaysRangeDate,
    setEndDaysRangeDate,
    companyLoading,
  } = useHomePageData();

  return (
    <Layout>
      {companyLoading && <LoadingScreen />}
      <HomePageWrapper>
        <Navbar active={'Dashboard'} />
        <UsersSection />
        <ChallengeSection />
        <UserActivity
          startDate={startRangeDate}
          endDate={endRangeDate}
          setStartDate={setStartRangeDate}
          setEndDate={setEndRangeDate}
        />
        <GroupActivity
          startDate={startDaysRangeDate}
          endDate={endDaysRangeDate}
          setStartDate={setStartDaysRangeDate}
          setEndDate={setEndDaysRangeDate}
        />
        <SuperStars />
        <br />
        <br />
      </HomePageWrapper>
    </Layout>
  );
};

export default HomePage;

import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface ChallengePageState {
  getChallengeLoading: boolean;
  getChallengeError: string | null;
  getChallengeSuccess: null | {
    challenges: {
      id: number;
      image: string;
      title: string;
      description: string;
      timeend: string;
      types: string;
    }[];
  };
  deleteChallengesLoading: boolean;
  deleteChallengesError: string | null;
  deleteChallengesSuccess: null | {};
}

const initialState: ChallengePageState = {
  getChallengeLoading: false,
  getChallengeError: null,
  getChallengeSuccess: null,

  deleteChallengesLoading: false,
  deleteChallengesError: null,
  deleteChallengesSuccess: null,
};

export const challengePageSlice = createSlice({
  name: 'challengePage',
  initialState,
  reducers: {
    getChallengeRequest: (state) => {
      state.getChallengeLoading = true;
      state.getChallengeError = null;
      state.getChallengeSuccess = null;
    },
    getChallengeSuccess: (state, action) => {
      state.getChallengeLoading = false;
      state.getChallengeError = null;
      state.getChallengeSuccess = action.payload;
    },
    getChallengeFailure: (state, action) => {
      state.getChallengeLoading = false;
      state.getChallengeError = action.payload;
    },
    deleteChallengesRequest: (state) => {
      state.deleteChallengesLoading = true;
      state.deleteChallengesError = null;
      state.deleteChallengesSuccess = null;
    },
    deleteChallengesSuccess: (state, action) => {
      state.deleteChallengesLoading = false;
      state.deleteChallengesError = null;
      state.deleteChallengesSuccess = action.payload;
    },
    deleteChallengesFailure: (state, action) => {
      state.deleteChallengesLoading = false;
      state.deleteChallengesError = action.payload;
    },
  },
});

export const {
  getChallengeRequest,
  getChallengeSuccess,
  getChallengeFailure,
  deleteChallengesRequest,
  deleteChallengesSuccess,
  deleteChallengesFailure,
} = challengePageSlice.actions;

export const selectChallengePage = (state: RootState) => state.challengePage;

export default challengePageSlice.reducer;

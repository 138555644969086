import { DataGrid, GridRowsProp, GridRowId } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import IconButton from '../../../common/buttons/IconButton';
import SVG from '../../../common/SVG';
import MinusIcon from '../../../icons/MinusIcon';
import { useTheme } from '@emotion/react';
import DownArrowIcon from '../../../icons/DownArrowIcon';
import { ChallengeType } from '../../../../types/challenge/steps.type';
import { useDispatch } from 'react-redux';
import { deleteChallenges } from '../../../../store/challenge/challengePageAction';
import { TableWrapper } from './style';
import { useChallengeTable } from '../../../hooks/useChallengeTable';
import ChallengeFilterInput from '../../../common/inputs/ChallengeFilterInput';

interface Props {
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

const TableContainer: React.FC<Props> = ({ status, setStatus }) => {
  const [active, setActive] = useState(false);
  const { getChallengeSuccess, getChallengeLoading } = useSelector(
    (state: RootState) => state.challengePage,
  );
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { columns } = useChallengeTable();

  // console.log(getChallengeSuccess);

  const theme = useTheme();
  const [rows, setRows] = useState<GridRowsProp>([]);

  const mapChallengesToRows = (challenges: ChallengeType[]) => {
    return challenges.map((item) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      image: item.image,
      timeend: item.timeend,
      types: item.types,
    }));
  };

  useEffect(() => {
    if (getChallengeSuccess) {
      const mappedRows = mapChallengesToRows(getChallengeSuccess.challenges);
      setRows(mappedRows);
    }
  }, [getChallengeSuccess]);

  return (
    <TableWrapper>
      <div className="filters">
        <div className="filter">
          <div className="filter-header">
            <h1>Status</h1>
            <div className="dropdown-container">
              <SVG
                icon={
                  <DownArrowIcon
                    active={active}
                    onClick={() => setActive(!active)}
                  />
                }
                width="20px"
                height="20px"
                color={theme.colors.text}
                cursor="pointer"
              />
            </div>
          </div>
          {active && (
            <ul>
              <li>
                <ChallengeFilterInput
                  status={status}
                  setStatus={setStatus}
                  type="radio"
                  name={`status`}
                  value={'All'}
                />
                <span>All</span>
              </li>
              <li>
                <ChallengeFilterInput
                  status={status}
                  setStatus={setStatus}
                  type="radio"
                  name={`status`}
                  value={'Completed'}
                />
                <span>Completed</span>
              </li>
              <li>
                <ChallengeFilterInput
                  status={status}
                  setStatus={setStatus}
                  type="radio"
                  name={`status`}
                  value={'Active'}
                />
                <span>Active</span>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="table">
        <div className="header">
          <h3>{selectionModel.length} challenges selected</h3>
          <div className="icon-container">
            <IconButton
              icon={
                <SVG
                  icon={<MinusIcon />}
                  width="20px"
                  height="20px"
                  color={theme.colors.secondary}
                />
              }
              text="Remove"
              border={`2px solid ${theme.colors.secondary}`}
              onClick={() => {
                dispatch(deleteChallenges({ challengesIds: selectionModel }));
              }}
            />
          </div>
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          checkboxSelection
          loading={getChallengeLoading}
          onRowSelectionModelChange={(newSelection: GridRowId[]) => {
            setSelectionModel(newSelection);
          }}
          rowSelectionModel={selectionModel}
          sx={{
            borderTop: 'none',
            backgroundColor: theme.colors.white,
          }}
        />
      </div>
    </TableWrapper>
  );
};

export default TableContainer;

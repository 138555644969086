import styled from '@emotion/styled';

interface Props {
  width: string;
  height: string;
  color?: string;
  cursor?: string;
  mobileWidth?: string;
  mobileHeight?: string;
  stockColor?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  hoverColor?: string;
}

const SVGContainer = styled.div<Props>`
  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
    fill: ${({ color }) => color}!important;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      stroke: ${({ stockColor }) => stockColor};
      fill: ${({ color }) => color};
    }
  }
  &:hover {
    fill: ${({ hoverColor }) => (hoverColor ? hoverColor : '')};
  }
`;

const SVG: React.FC<Props> = ({
  onClick,
  width,
  height,
  color,
  cursor,
  mobileWidth,
  mobileHeight,
  stockColor,
  icon,
  hoverColor,
}) => {
  return (
    <SVGContainer
      hoverColor={hoverColor}
      width={mobileWidth ? mobileWidth : width}
      height={mobileHeight ? mobileHeight : height}
      color={color}
      cursor={cursor}
      onClick={() => onClick}
      stockColor={stockColor}
    >
      {icon}
    </SVGContainer>
  );
};

export default SVG;

import styled from '@emotion/styled';
import React from 'react';

const SelectElementContainer = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

interface Props {
  children: React.ReactNode;
}

const SelectIcon: React.FC<Props> = ({ children }) => {
  return <SelectElementContainer>{children}</SelectElementContainer>;
};

export default SelectIcon;

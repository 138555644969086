const StepsIcon = () => {
  return (
    <svg
      width="151"
      height="151"
      viewBox="0 0 151 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="75.5" cy="75.5" r="75.5" fill="#FF8F45" />
      <path
        d="M86.424 100.272C93.8208 102.101 100.352 103.266 109.736 105.348C106.131 138.236 63.0562 125.175 86.424 100.272ZM124.883 70.0607C124.398 60.1859 121.989 47.2322 102.799 49.262C93.8035 50.9569 87.1569 58.0895 84.0309 70.3955C82.314 77.1617 83.3124 86.6589 85.3376 92.352C87.1867 96.364 86.5579 96.1174 88.5453 96.8793C96.2376 98.1304 103.853 99.5154 111.608 100.928C119.486 96.911 125.956 75.5869 124.883 70.0607ZM65.6632 69.3029C67.6868 63.609 68.6852 54.1117 66.9691 47.3463C63.8454 35.0398 57.1981 27.906 48.201 26.2122C29.0115 24.1825 26.6024 37.1359 26.1166 47.0114C25.0443 52.5366 31.5153 73.862 39.394 77.8779C47.1483 76.4656 54.7624 75.0818 62.4571 73.8296C64.4421 73.0684 63.8133 73.3148 65.6632 69.3029ZM41.2629 82.2995C44.8667 115.187 87.9417 102.126 64.5743 77.2239C57.1776 79.0529 50.6468 80.2176 41.2629 82.2995Z"
        fill="white"
      />
    </svg>
  );
};

export default StepsIcon;

import styled from '@emotion/styled';
import ChallengeInput from '../../../../common/inputs/ChallengeInput';
import { mobile } from '../../../../../styles/media';

const FormDataContainer = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  ${mobile(`width: 100%;`)}
`;

interface props {
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  goal: string;
  setGoal: React.Dispatch<React.SetStateAction<string>>;
}

const FormData: React.FC<props> = ({ amount, setAmount, goal, setGoal }) => {
  return (
    <FormDataContainer>
      <ChallengeInput
        name="amount"
        type="number"
        placeholder="Amount *"
        error=""
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <ChallengeInput
        name="goal"
        type="number"
        placeholder="Goal *"
        error=""
        value={goal}
        onChange={(e) => setGoal(e.target.value)}
      />
    </FormDataContainer>
  );
};

export default FormData;

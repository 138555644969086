import {
  Box,
  Button,
  Checkbox,
  Container,
  createTheme,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { z } from 'zod';
import DonaLebLogoRounded from '../../../assets/donarounded.jpg';
import { AppDispatch, RootState } from '../../../store/store';
import { login } from '../../../store/user/userAction';
import { LoginFormInput } from '../../../types/auth/login';
import { useAuth } from '../../hooks/useAuth';
import { LoginPageContainer } from './styled';

const LOGIN_SCHEMA = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Email is invalid' }),
  password: z.string().min(1, { message: 'Password is required' }),
});

const theme = createTheme();

const LoginPage = () => {
  const { loginSuccess, loginError, loginLoading } = useSelector(
    (state: RootState) => state.user,
  );

  const { LoginFunction } = useAuth();

  const dispatch = useDispatch<AppDispatch>();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (
      loginSuccess &&
      loginSuccess.success === true &&
      loginSuccess.token &&
      loginSuccess.token.length > 0
    ) {
      toast.success('Login successful');
      LoginFunction(loginSuccess.token);
    }
    if (loginError) {
      toast.error(loginError);
    }
  }, [LoginFunction, loginError, loginSuccess]);

  const onSubmit = (data: LoginFormInput) => {
    dispatch(login(data));
  };
  return (
    <LoginPageContainer>
      <div>
        <Container maxWidth="lg">
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${DonaLebLogoRounded})`,
                    margin: 1,
                    width: 100,
                    height: 100,
                    backgroundSize: 'contain',
                  }}
                ></div>
                <Typography component="h1" variant="h5">
                  Admin Panel
                </Typography>

                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(evt) => setEmail(evt.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(evt) => setPassword(evt.target.value)}
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={email.trim() === '' || password.trim() === ''}
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit({ email: email, password: password });
                    }}
                  >
                    Login
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Button
                        onClick={() => {
                          window.location.href = 'mailto:admin@donaleb.com';
                        }}
                      >
                        Forgot password?
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Container>
      </div>
    </LoginPageContainer>
  );
};

export default LoginPage;

import styled from '@emotion/styled';
import IconButton from '../../../../common/buttons/IconButton';
import RightArrowIcon from '../../../../icons/RightArrowIcon';
import SVG from '../../../../common/SVG';
import { useTheme } from '@emotion/react';
import PlusIcon from '../../../../icons/PlusIcon';
import PrimaryButton from '../../../../common/buttons/PrimaryButton';
import { useState } from 'react';
import Model from './Model';
import { mobile } from '../../../../../styles/media';

interface propsType {
  isSelected: boolean;
}

const NgoCardContainer = styled.div<propsType>`
  width: 250px;
  height: 400px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px;
  gap: 1rem;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  ${mobile(`width: 100%;`)}

  .top {
    .image-container {
      width: 100%;
      height: 200px;
      background-color: ${({ isSelected, theme }) =>
        isSelected ? theme.colors.white : 'transparent'};

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1;
      }
    }
    .text-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.5rem;
      margin-top: 1rem;
      p {
        width: 100%;
        font-size: ${({ theme }) => theme.fontSizes.s};
        color: ${({ theme, isSelected }) =>
          isSelected ? theme.colors.white : theme.colors.text};
      }
    }
  }

  .bottom {
    width: 100%;
    height: 40px;
    .button-container {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 1rem;
    }
  }
`;

interface props {
  ngo: {
    id: number;
    description: string;
    user_id: number;
    url: string;
    logo: string;
    location: string;
    name: string;
  };
  isSelected: boolean;
  setNgoId?: React.Dispatch<React.SetStateAction<string>>;
}

const NgoCard: React.FC<props> = ({ ngo, isSelected, setNgoId }) => {
  const theme = useTheme();
  const [active, setActive] = useState(false);
  return (
    <NgoCardContainer isSelected={isSelected}>
      <div className="top">
        <div className="image-container">
          <img
            src={`${process.env.REACT_APP_URL}/ngo/${ngo.logo}`}
            alt={ngo.name}
          />
        </div>
        <div className="text-container">
          <p>
            <b>name:</b> {ngo.name}
          </p>
          <p>
            <b>description:</b>{' '}
            {ngo.description.length > 50
              ? ngo.description.substring(0, 50) + '...'
              : ngo.description.substring(0, 80)}
          </p>
        </div>
      </div>
      <div className="bottom">
        <div className="button-container">
          <PrimaryButton
            text="More"
            isActive={false}
            width={'100%'}
            onClick={() => {
              setActive(true);
            }}
          />

          {!isSelected && (
            <IconButton
              icon={
                <SVG
                  icon={<PlusIcon />}
                  color={theme.colors.white}
                  stockColor={
                    isSelected ? theme.colors.text : theme.colors.white
                  }
                  width="15px"
                  height="15px"
                  cursor="pointer"
                />
              }
              text="Select"
              type="button"
              border={isSelected ? `1px solid ${theme.colors.text}` : ''}
              onClick={() => {
                setNgoId && setNgoId(ngo.user_id.toString());
              }}
              backgroundColor={
                isSelected ? theme.colors.white : theme.colors.text
              }
              color={isSelected ? theme.colors.text : theme.colors.white}
            />
          )}
        </div>
      </div>
      {active && <Model ngo={ngo} active={active} setActive={setActive} />}
    </NgoCardContainer>
  );
};

export default NgoCard;

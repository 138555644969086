import styled from '@emotion/styled';
import React from 'react';

const Input = styled.input``;

interface Props {
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  name: string;
  value: string;
}

const ChallengeFilterInput: React.FC<Props> = ({
  status,
  setStatus,
  type,
  name,
  value,
}) => {
  return (
    <Input
      type={type}
      name={name}
      value={value}
      checked={status === value}
      onChange={() => setStatus(value)}
    />
  );
};

export default ChallengeFilterInput;

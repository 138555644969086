import Layout from '../../common/wrappers/Layout';
import UserSearch from './UserSearch';
import Navbar from '../../common/navbar';
import FilterHeader from './FilterHeader';
import TableContainer from './TableContainer';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useEffect, useState } from 'react';
import {
  getDepartments,
  getEmployees,
} from '../../../store/userPage/userPageAction';
import { useSelector } from 'react-redux';
import LoadingScreen from '../../common/loading';
import useBodyOverflow from '../../hooks/useBodyOverFlow';

const UserPageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  min-height: 100vh;
`;

const UserPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { departmentsSuccess } = useSelector(
    (state: RootState) => state.userPage,
  );
  const [departmentId, setDepartmentId] = useState(
    departmentsSuccess ? departmentsSuccess[0].department_id : 1,
  );
  const [location, setLocation] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    dispatch(
      getEmployees({
        department_id: departmentId,
        location: location,
        username: search,
      }),
    );
  }, [departmentId, dispatch, location, search]);

  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);
  const { companyLoading } = useSelector((state: RootState) => state.user);
  useBodyOverflow(companyLoading);
  return (
    <Layout>
      {companyLoading && <LoadingScreen />}
      <Navbar active={'Users'} />
      <UserPageContainer>
        <UserSearch search={search} setSearch={setSearch} />
        <FilterHeader setSearch={setSearch} setLocation={setLocation} />
        <TableContainer
          departmentId={departmentId}
          location={location}
          setLocation={setLocation}
          setDepartmentId={setDepartmentId}
        />
      </UserPageContainer>
    </Layout>
  );
};

export default UserPage;

import React from 'react';

const RewardIcon = () => {
  return (
    <svg
      fill="#000000"
      height="800px"
      width="800px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M426.898,122.355l-39.455-29.696l-14.465-47.216c-2.085-6.803-8.313-11.492-15.427-11.611l-49.376-0.833L268.72,3.302
			c-5.686-4.279-13.481-4.411-19.306-0.325l-40.435,28.347l-49.376-0.833c-7.104-0.148-13.498,4.355-15.81,11.085l-16.052,46.699
			l-40.435,28.348c-5.826,4.084-8.36,11.457-6.275,18.26l14.465,47.216L79.446,228.8c-2.313,6.73-0.03,14.182,5.656,18.462
			l39.455,29.696l21.649,56.435v162.182c0,5.566,2.82,10.754,7.49,13.782c4.672,3.029,10.558,3.484,15.64,1.212L256,471.817
			l86.664,38.752c2.142,0.958,4.427,1.431,6.704,1.431c3.125,0,6.234-0.891,8.936-2.643c4.671-3.028,7.49-8.216,7.49-13.782V332.574
			l18.464-51.232l40.435-28.348c5.827-4.084,8.36-11.457,6.275-18.26l-14.465-47.216l16.05-46.701
			C434.867,134.088,432.583,126.636,426.898,122.355z M332.944,470.239l-70.239-31.407c-2.133-0.954-4.419-1.431-6.705-1.431
			c-2.285,0-4.572,0.477-6.705,1.431l-70.24,31.407V336.202l24.769,0.418l39.455,29.696c5.635,4.24,13.449,4.433,19.306,0.325
			l40.435-28.347l29.923,0.505V470.239z M383.53,192.039l12.533,40.909l-35.034,24.561c-2.84,1.991-4.977,4.83-6.105,8.111
			l-13.906,40.462l-42.779-0.722c-3.468-0.066-6.867,0.982-9.706,2.974l-35.033,24.561l-34.185-25.729
			c-2.777-2.09-6.139-3.241-9.6-3.299l-42.779-0.723l-12.532-40.908c-1.016-3.317-3.056-6.226-5.828-8.312l-34.185-25.729
			l13.907-40.462c1.128-3.281,1.187-6.834,0.172-10.149l-12.533-40.909l35.034-24.561c2.84-1.991,4.977-4.83,6.105-8.111
			l13.906-40.462l42.779,0.723c3.491,0.036,6.866-0.982,9.706-2.974L258.5,36.728l34.185,25.729c2.771,2.086,6.132,3.24,9.6,3.299
			l42.779,0.723l12.532,40.908c1.016,3.317,3.056,6.226,5.828,8.312l34.185,25.729l-13.906,40.462
			C382.574,185.169,382.515,188.722,383.53,192.039z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,82.992c-56.142,0-101.817,45.674-101.817,101.817c0,56.143,45.674,101.817,101.817,101.817
			c56.143,0,101.817-45.674,101.817-101.817C357.818,128.667,312.142,82.992,256,82.992z M256,253.776
			c-38.028,0-68.967-30.938-68.967-68.967c0-38.029,30.938-68.967,68.967-68.967c38.029,0,68.967,30.938,68.967,68.967
			C324.968,222.838,294.029,253.776,256,253.776z"
          />
        </g>
      </g>
    </svg>
  );
};

export default RewardIcon;

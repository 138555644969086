import React from 'react';

const EditIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M607.92 140.8C620.311 129.008 636.879 122.622 653.978 123.046C671.078 123.47 687.309 130.669 699.1 143.06C710.892 155.451 717.278 172.018 716.854 189.118C716.43 206.218 709.231 222.448 696.84 234.24L253.84 656.84L139.68 671.84L186.48 540.96L607.88 140.84L607.92 140.8ZM756.92 87.92C730.519 60.2093 694.201 44.1066 655.939 43.1467C617.677 42.1867 580.598 56.448 552.84 82.8L124.4 489.52C119.859 493.83 116.392 499.146 114.28 505.04L42.2801 706.52C39.9738 712.973 39.3643 719.911 40.5099 726.668C41.6555 733.424 44.5178 739.773 48.8223 745.106C53.1269 750.438 58.7293 754.576 65.0923 757.121C71.4553 759.666 78.3655 760.533 85.1601 759.64L277.16 734.44C285.57 733.345 293.414 729.605 299.56 723.76L752 292.16C779.726 265.721 795.816 229.351 796.732 191.05C797.647 152.75 783.352 115.653 756.92 87.92ZM480 680C469.391 680 459.217 684.214 451.716 691.716C444.214 699.217 440 709.391 440 720C440 730.609 444.214 740.783 451.716 748.284C459.217 755.786 469.391 760 480 760H720C730.609 760 740.783 755.786 748.284 748.284C755.786 740.783 760 730.609 760 720C760 709.391 755.786 699.217 748.284 691.716C740.783 684.214 730.609 680 720 680H480Z"
        fill="black"
      />
    </svg>
  );
};

export default EditIcon;

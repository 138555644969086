import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../../styles/media';

const HeaderContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  ${mobile(`width: 100%;`)}

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xxxxl};
    ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xxl}`)}
  }
  p {
    text-align: center;
    line-height: 1.7rem;
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.fontSizes.m};
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <h1>Select your challenge mode</h1>
      <p>
        Customize your module mix by first selecting a challenge mode, then
        picking from a list of additional modules in the next step.
      </p>
    </HeaderContainer>
  );
};

export default Header;

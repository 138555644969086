import React from 'react';

const PrimaryStepIcon = () => {
  return (
    <svg
      width="99"
      height="99"
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.424 74.272C67.8208 76.101 74.352 77.266 83.736 79.348C80.131 112.236 37.0562 99.175 60.424 74.272ZM98.883 44.0607C98.398 34.1859 95.989 21.2322 76.799 23.262C67.8035 24.9569 61.1569 32.0895 58.0309 44.3955C56.314 51.1617 57.3124 60.6589 59.3376 66.352C61.1867 70.364 60.5579 70.1174 62.5453 70.8793C70.2376 72.1304 77.853 73.5154 85.608 74.928C93.486 70.911 99.956 49.5869 98.883 44.0607ZM39.6632 43.3029C41.6868 37.609 42.6852 28.1117 40.9691 21.3463C37.8454 9.0398 31.1981 1.906 22.201 0.212204C3.01153 -1.8175 0.602427 11.1359 0.116627 21.0114C-0.955673 26.5366 5.51533 47.862 13.394 51.8779C21.1483 50.4656 28.7624 49.0818 36.4571 47.8296C38.4421 47.0684 37.8133 47.3148 39.6632 43.3029ZM15.2629 56.2995C18.8667 89.187 61.9417 76.126 38.5743 51.2239C31.1776 53.0529 24.6468 54.2176 15.2629 56.2995Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default PrimaryStepIcon;

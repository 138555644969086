import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createChallengeFailure,
  createChallengeRequest,
  createChallengeSuccess,
  editChallengeByIdFailure,
  editChallengeByIdRequest,
  editChallengeByIdSuccess,
  getChallengeByIdFailure,
  getChallengeByIdRequest,
  getChallengeByIdSuccess,
  getChallengeFailure,
  getChallengeRequest,
  getChallengeSuccess,
  getNgosFailure,
  getNgosRequest,
  getNgosSuccess,
} from './createChallengePageReducer';
import { toast } from 'react-toastify';

export const getChallenges = createAsyncThunk(
  'challenge/getChallengesByCompany',
  async (
    data: {
      status: string;
    },
    { dispatch },
  ) => {
    try {
      dispatch(getChallengeRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/challenge/getChallengesByCompany?${data.status === 'Active' ? 'status=Active' : data.status === 'Completed' ? 'status=Completed' : ''}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(getChallengeSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(getChallengeFailure(err?.response?.data?.message));
      } else {
        dispatch(getChallengeFailure((err as Error).message));
      }
    }
  },
);

export const getNgos = createAsyncThunk(
  'NGO/getNGOs',
  async (_, { dispatch }) => {
    try {
      dispatch(getNgosRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/NGO/getNGOs`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(getNgosSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(getNgosFailure(err?.response?.data?.message));
      } else {
        dispatch(getNgosFailure((err as Error).message));
      }
    }
  },
);

export const getChallengeById = createAsyncThunk(
  'Challenge/updatechallengebyid',
  async (
    data: {
      id: number;
    },
    { dispatch },
  ) => {
    try {
      dispatch(getChallengeByIdRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/Challenge/getchallengebyid/${data.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data) {
        dispatch(getChallengeByIdSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(getChallengeByIdFailure(err?.response?.data?.message));
      } else {
        dispatch(getChallengeByIdFailure((err as Error).message));
      }
    }
  },
);

export const createChallenge = createAsyncThunk(
  'challenge/getChallengesByCompany',
  async (
    data: {
      goal: string;
      description: string;
      timestart: string;
      timeend: string;
      amount: string;
      ngo_id: string;
      // sponsoredby: string;
      // sponsored_image: File | undefined;
      title: string;
      image: File | undefined; // Change 'string' to 'File' for the image
    },
    { dispatch },
  ) => {
    try {
      dispatch(createChallengeRequest());

      const formData = new FormData();
      formData.append('goal', data.goal);
      formData.append('description', data.description);
      formData.append('timestart', data.timestart);
      formData.append('timeend', data.timeend);
      formData.append('amount', data.amount);
      formData.append('ngo_id', data.ngo_id);
      // formData.append('sponsoredby', data.sponsoredby);
      // formData.append('sponsored_image', data.sponsored_image  as File);
      formData.append('title', data.title);
      formData.append('image', data.image as File); // Append the image file

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/Challenge/createcompanydonationChallenge`,
        formData, // Pass the FormData instead of 'data'
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data', // Set Content-Type for FormData
          },
        },
      );

      if (response && response.data) {
        dispatch(createChallengeSuccess(response.data));
        window.location.href = '/challenges';
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(createChallengeFailure(err?.response?.data?.message));
      } else {
        dispatch(createChallengeFailure((err as Error).message));
        toast.error((err as Error).message);
      }
    }
  },
);

export const editDonationChallenge = createAsyncThunk(
  'Challenge/updatechallengebyid',
  async (
    data: {
      id: number;
      goal: string;
      description: string;
      timestart: string;
      timeend: string;
      amount: string;
      ngo_id: number; // Keep it as a number
      title: string;
      image: File | undefined;
      didDateChanged: boolean;
    },
    { dispatch },
  ) => {
    try {
      dispatch(editChallengeByIdRequest());

      const formatDate = (dateString: string) => {
        const [day, month, year] = dateString.split('-').map(Number);

        // Validate month and day ranges
        // if (month < 1 || month > 12 || day < 1 || day > 31) {
        //   throw new Error('Invalid date format');
        // }

        // Ensure month and day are padded with leading zeros if necessary
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = String(month).padStart(2, '0');
        const formattedYear = String(year);

        return `${formattedYear}-${formattedDay}-${formattedMonth} 00:00:00`;
      };

      const formatDateDefault = (dateString: string) => {
        const [month, day, year] = dateString.split('-').map(Number);

        // Validate month and day ranges
        // if (month < 1 || month > 12 || day < 1 || day > 31) {
        //   throw new Error('Invalid date format');
        // }

        // Ensure month and day are padded with leading zeros if necessary
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = String(month).padStart(2, '0');
        const formattedYear = String(year);

        return `${formattedYear}-${formattedDay}-${formattedMonth} 00:00:00`;
      };

      const formData = new FormData();
      formData.append('goal', data.goal);
      formData.append('description', data.description);
      formData.append(
        'timestart',
        data.didDateChanged
          ? formatDate(data.timestart)
          : formatDateDefault(data.timestart),
      );
      formData.append(
        'timeend',
        data.didDateChanged
          ? formatDate(data.timeend)
          : formatDateDefault(data.timeend),
      );
      formData.append('amount', data.amount);
      formData.append('ngo_id', data.ngo_id.toString());
      formData.append('title', data.title);
      data.image && formData.append('image', data.image as File);

      const response = await axios.put(
        `${process.env.REACT_APP_URL}/Challenge/updatechallengebyid/${data.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response && response.data) {
        dispatch(editChallengeByIdSuccess(response.data));
        window.location.href = '/challenges';
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(editChallengeByIdFailure(err?.response?.data?.message));
      } else {
        dispatch(editChallengeByIdFailure((err as Error).message));
        toast.error((err as Error).message);
      }
    }
  },
);

export const createGiftChallenge = createAsyncThunk(
  'Challenge/CreateGiftChallenge',
  async (
    data: {
      title: string;
      description: string;
      image: File | undefined;
      goal: string;
      streaks: string;
      winnerNumber: string;
      giftCode: string;
      emaildescription: string;
      timestart: string;
      timeend: string;
      challengeStatusType: string;
    },
    { dispatch },
  ) => {
    try {
      dispatch(editChallengeByIdRequest());

      // const formatDate = (dateString: string) => {
      //   const [day, month, year] = dateString.split('-').map(Number);

      //   // Validate month and day ranges
      //   // if (month < 1 || month > 12 || day < 1 || day > 31) {
      //   //   throw new Error('Invalid date format');
      //   // }

      //   // Ensure month and day are padded with leading zeros if necessary
      //   const formattedDay = String(day).padStart(2, '0');
      //   const formattedMonth = String(month).padStart(2, '0');
      //   const formattedYear = String(year);

      //   return `${formattedYear}-${formattedDay}-${formattedMonth} 00:00:00`;
      // };

      // const formatDateDefault = (dateString: string) => {
      //   const [month,day, year] = dateString.split('-').map(Number);

      //   // Validate month and day ranges
      //   // if (month < 1 || month > 12 || day < 1 || day > 31) {
      //   //   throw new Error('Invalid date format');
      //   // }

      //   // Ensure month and day are padded with leading zeros if necessary
      //   const formattedDay = String(day).padStart(2, '0');
      //   const formattedMonth = String(month).padStart(2, '0');
      //   const formattedYear = String(year);

      //   return `${formattedYear}-${formattedDay}-${formattedMonth} 00:00:00`;
      // };

      const formData = new FormData();
      // data.image && formData.append('image', data.image as File);

      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('winnerNumber', data.winnerNumber);
      formData.append('giftcode', data.giftCode);
      formData.append('emaildescription', data.emaildescription);
      formData.append('timestart', data.timestart);
      formData.append('timeend', data.timeend);
      data.image && formData.append('image', data.image as File);

      data.challengeStatusType === 'Target'
        ? formData.append('goal', data.goal)
        : formData.append('streak', data.streaks);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/Challenge/CreateGiftChallenge`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response && response.data) {
        dispatch(editChallengeByIdSuccess(response.data));
        window.location.href = '/challenges';
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(editChallengeByIdFailure(err?.response?.data?.message));
        toast.error(err?.response?.data?.message);
      } else {
        dispatch(editChallengeByIdFailure((err as Error).message));
        toast.error((err as Error).message);
      }
    }
  },
);

export const editGiftChallenge = createAsyncThunk(
  'Challenge/updatechallengebyid',
  async (
    data: {
      id: number;
      title: string;
      description: string;
      image: File | undefined;
      goal: string;
      streaks: string;
      winnerNumber: string;
      giftCode: string;
      emaildescription: string;
      timestart: string;
      timeend: string;
      challengeStatusType: string;
      didDateChanged: boolean;
    },
    { dispatch },
  ) => {
    try {
      dispatch(editChallengeByIdRequest());

      const formatDate = (dateString: string) => {
        const [day, month, year] = dateString.split('-').map(Number);

        // Validate month and day ranges
        // if (month < 1 || month > 12 || day < 1 || day > 31) {
        //   throw new Error('Invalid date format');
        // }

        // Ensure month and day are padded with leading zeros if necessary
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = String(month).padStart(2, '0');
        const formattedYear = String(year);

        return `${formattedYear}-${formattedDay}-${formattedMonth} 00:00:00`;
      };

      const formatDateDefault = (dateString: string) => {
        const [month, day, year] = dateString.split('-').map(Number);

        // Validate month and day ranges
        // if (month < 1 || month > 12 || day < 1 || day > 31) {
        //   throw new Error('Invalid date format');
        // }

        // Ensure month and day are padded with leading zeros if necessary
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = String(month).padStart(2, '0');
        const formattedYear = String(year);

        return `${formattedYear}-${formattedDay}-${formattedMonth} 00:00:00`;
      };

      const formData = new FormData();

      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('winnerNumber', data.winnerNumber);
      formData.append('giftcode', data.giftCode);
      formData.append('emaildescription', data.emaildescription);
      formData.append(
        'timestart',
        data.didDateChanged
          ? formatDate(data.timestart)
          : formatDateDefault(data.timestart),
      );
      formData.append(
        'timeend',
        data.didDateChanged
          ? formatDate(data.timeend)
          : formatDateDefault(data.timeend),
      );
      data.image && formData.append('image', data.image as File);

      data.challengeStatusType === 'Target'
        ? formData.append('goal', data.goal)
        : formData.append('streak', data.streaks);

      const response = await axios.put(
        `${process.env.REACT_APP_URL}/Challenge/updatechallengebyid/${data.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response && response.data) {
        dispatch(editChallengeByIdSuccess(response.data));
        window.location.href = '/challenges';
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(editChallengeByIdFailure(err?.response?.data?.message));
        toast.error(err?.response?.data?.message);
      } else {
        dispatch(editChallengeByIdFailure((err as Error).message));
        toast.error((err as Error).message);
      }
    }
  },
);

import React from 'react';

const UserIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M400 333.333C473.638 333.333 533.333 273.638 533.333 200C533.333 126.362 473.638 66.6667 400 66.6667C326.362 66.6667 266.667 126.362 266.667 200C266.667 273.638 326.362 333.333 400 333.333Z"
        stroke="#1C274C"
        strokeWidth="50"
      />
      <path
        d="M666.583 600C666.667 594.527 666.667 588.967 666.667 583.333C666.667 500.49 547.277 433.333 400 433.333C252.724 433.333 133.333 500.49 133.333 583.333C133.333 666.177 133.333 733.333 400 733.333C474.367 733.333 527.993 728.11 566.667 718.78"
        stroke="#1C274C"
        strokeWidth="50"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UserIcon;

import styled from '@emotion/styled';
import SVG from '../../../../common/SVG';
import StepsIcon from '../../../../icons/StepsIcon';
import { useTheme } from '@emotion/react';
import QuestionIcon from '../../../../icons/QuestionIcon';
import Item from './Item';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { Link } from 'react-router-dom';

const CardContainer = styled.div`
  width: 300px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  .top {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primaryOrange};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top-left-radius: ${({ theme }) => theme.borderRadius.s};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
    span {
      color: ${({ theme }) => theme.colors.white};
    }
    .question-icon {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: ${({ theme }) => '50%'};
    }
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    h2 {
      font-weight: ${({ theme }) => theme.fontWeights.light};
    }
    h2,
    span {
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const Card = () => {
  const theme = useTheme();
  const { leaderBoardUsersSuccess } = useSelector(
    (state: RootState) => state.home,
  );

  return (
    <CardContainer>
      <div className="top">
        <SVG
          icon={<StepsIcon />}
          color={theme.colors.white}
          width="20px"
          height="20px"
        />
        <span>Top Steppers</span>
        <div className="question-icon">
          <SVG
            icon={<QuestionIcon />}
            stockColor={theme.colors.primaryOrange}
            width="20px"
            height="20px"
          />
        </div>
      </div>
      <Link
        to={`/user/${leaderBoardUsersSuccess?.users[0]?.id}`}
        className="header"
      >
        <img src={leaderBoardUsersSuccess?.users[0]?.avatar_url} alt="user" />
        <br />
        <h1>{leaderBoardUsersSuccess?.users[0]?.username}</h1>
        <h2>{leaderBoardUsersSuccess?.users[0]?.steps}</h2>
        <span>Step average</span>
      </Link>
      <div className="bottom">
        {leaderBoardUsersSuccess?.users
          .slice(1)
          .map((item: any, index: any) => (
            <Item
              key={index}
              id={item.id}
              index={index + 2}
              avatar={item.avatar_url}
              steps={item.steps}
              name={item.username}
            />
          ))}
      </div>
    </CardContainer>
  );
};

export default Card;

import React from 'react';

const RoundedDirectionIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_9)">
        <path
          d="M666.667 233.333H533.333V200H614C585.538 169.378 550.84 145.215 512.248 129.14C473.655 113.064 432.064 105.451 390.279 106.812C348.495 108.174 307.488 118.479 270.023 137.032C232.559 155.586 199.508 181.957 173.1 214.367L155.567 184.033C185.183 150.303 221.457 123.065 262.108 104.032C302.76 84.9984 346.909 74.5813 391.782 73.4355C436.654 72.2896 481.278 80.4399 522.848 97.3737C564.418 114.308 602.035 139.659 633.333 171.833V100H666.667V233.333ZM4.53336 322.367L21.2 351.267L85.1 314.333C73 358.063 70.1885 403.841 76.8464 448.723C83.5043 493.604 99.4842 536.595 123.758 574.928C148.032 613.262 180.061 646.089 217.786 671.299C255.511 696.508 298.096 713.54 342.8 721.3L360.667 690.333C318.947 684.823 278.91 670.367 243.294 647.954C207.677 625.542 177.321 595.702 154.301 560.475C131.282 525.247 116.142 485.464 109.917 443.845C103.692 402.227 106.529 359.754 118.233 319.333L157.8 387.9L186.667 371.233L120 255.733L4.53336 322.367ZM538.667 782.067L555.333 753.2L487.933 714.3C530.28 702.542 569.812 682.341 604.15 654.912C638.489 627.483 666.926 593.393 687.752 554.692C708.577 515.99 721.36 473.477 725.332 429.708C729.305 385.939 724.384 341.818 710.867 300H675.4C689.271 337.732 695.203 377.922 692.823 418.053C690.444 458.183 679.804 497.391 661.571 533.219C643.337 569.047 617.903 600.725 586.86 626.269C555.818 651.813 519.835 670.673 481.167 681.667L518.733 616.6L489.867 599.933L423.2 715.4L538.667 782.067Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_9">
          <rect width="800" height="800" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundedDirectionIcon;

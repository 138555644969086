import styled from '@emotion/styled';
import ChallengeInput from '../../../common/inputs/ChallengeInput';
import NextButton from '../../../common/buttons/NextButton';
import { useEffect, useState } from 'react';
import { mobile } from '../../../../styles/media';

const AddModuleGiftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  .input-container {
    width: 100%;
    padding: 1rem 5rem;
    ${mobile(`padding: 1rem;`)}
  }
`;

interface Props {
  giftCode: string;
  setGiftCode: React.Dispatch<React.SetStateAction<string>>;
  setWinnerNumber: React.Dispatch<React.SetStateAction<string>>;
  winnerNumber: string;
  emailDescription: string;
  setEmailDescription: React.Dispatch<React.SetStateAction<string>>;
  setCreateChallengeSteps: React.Dispatch<React.SetStateAction<any>>;
}

const AddModuleGift: React.FC<Props> = ({
  giftCode,
  setGiftCode,
  setWinnerNumber,
  winnerNumber,
  emailDescription,
  setEmailDescription,
  setCreateChallengeSteps,
}) => {
  const isNumber = (value: string) => !isNaN(Number(value));

  const [isAllowed, setIsAllowed] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (
      giftCode.length > 0 &&
      winnerNumber.length > 0 &&
      isNumber(winnerNumber) &&
      emailDescription.length > 0
    ) {
      setIsAllowed(true);
    } else {
      if (giftCode.length === 0) setMessage('Gift Code cannot be empty');
      else if (winnerNumber.length === 0)
        setMessage('Winner Number cannot be empty');
      else if (parseInt(winnerNumber) < 0)
        setMessage('Winner Number cannot be negative');
      else if (!isNumber(winnerNumber))
        setMessage('Winner Number must be a number');
      else if (emailDescription.length === 0)
        setMessage('Email Description cannot be empty');
      else setMessage('Please fill in all required fields');
      setIsAllowed(false);
    }
  }, [
    emailDescription,
    emailDescription.length,
    giftCode,
    isAllowed,
    winnerNumber,
  ]);

  return (
    <AddModuleGiftContainer>
      <div className="input-container">
        <ChallengeInput
          value={giftCode}
          onChange={(e) => setGiftCode(e.target.value)}
          name={'giftCode'}
          type={'text'}
          placeholder={'Gift Code'}
          error={undefined}
        />

        <ChallengeInput
          value={winnerNumber}
          onChange={(e) => setWinnerNumber(e.target.value)}
          name={'winnerNumber'}
          type={'text'}
          placeholder={'Winner Number'}
          error={undefined}
        />

        <ChallengeInput
          value={emailDescription}
          onChange={(e) => setEmailDescription(e.target.value)}
          name={'emailDescription'}
          type={'text'}
          placeholder={'Email Description'}
          error={undefined}
        />

        <NextButton
          message={message}
          onClick={() => {
            if (isAllowed) {
              setCreateChallengeSteps({
                step1: {
                  number: 1,
                  title: 'Challenge Details',
                  active: false,
                },
                step2: {
                  number: 2,
                  title: 'Challenge Details',
                  active: false,
                },
                step3: {
                  number: 3,
                  title: 'Challenge Details',
                  active: false,
                },
                step4: {
                  number: 4,
                  title: 'Challenge Details',
                  active: true,
                },
              });
            }
          }}
          isAllowed={isAllowed}
        />
      </div>
    </AddModuleGiftContainer>
  );
};

export default AddModuleGift;

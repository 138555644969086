import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';

const StaticItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  ${mobile(`width: 100%;`)}
  .static-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    span {
      color: ${({ theme }) => theme.colors.gray};
    }
  }
  span {
    color: ${({ theme }) => theme.colors.text};
  }
`;

interface props {
  title: string;
  value: string;
  icon: React.ReactNode;
}

const StaticItem: React.FC<props> = ({ title, value, icon }) => {
  return (
    <StaticItemContainer>
      <div className="static-container">
        {icon}
        <span>{title}</span>
      </div>
      <span>{value}</span>
    </StaticItemContainer>
  );
};

export default StaticItem;

import styled from '@emotion/styled';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { mobile } from '../../../../styles/media';
import { toast } from 'react-toastify';

const ChallengeInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${mobile(`
        gap: 0.3rem;
    `)}
  .header-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
    .placeholder {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      text-transform: uppercase;
    }
    .character-reminded {
      color: ${({ theme }) => theme.colors.textGray};
      text-transform: uppercase;
      font-size: ${({ theme }) => theme.fontSizes.s};
    }
  }
  input {
    width: 100%;
    outline: none;
    border: none;
    color: ${({ theme }) => theme.colors.text};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    padding: 0.5rem 0;
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

interface PropsType {
  name: string;
  type: string;
  placeholder: string;
  characterReminded?: number;
  error: string | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  disabled?: boolean;
}

const ChallengeInput: React.FC<PropsType> = ({
  name,
  type,
  placeholder,
  error,
  characterReminded,
  value,
  onChange,
  disabled,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    if (type === 'number' && Number(value) < 0) {
      event.preventDefault();
      return;
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <ChallengeInputContainer>
      <div className="header-input">
        <span className="placeholder">{placeholder}</span>
        {characterReminded !== undefined && (
          <span className="character-reminded">
            {characterReminded} characters remained
          </span>
        )}
      </div>
      <input
        name={name}
        id={name}
        type={type}
        value={value}
        onChange={handleChange}
        disabled={disabled || false}
      />
      <span>{error}</span>
    </ChallengeInputContainer>
  );
};

export default ChallengeInput;

import Layout from '../../common/wrappers/Layout';
import ChallengeSearch from './ChallengeSearch';
import Navbar from '../../common/navbar';
import FilterHeader from './FilterHeader';
import TableContainer from './TableContainer';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useEffect, useState } from 'react';
import { getDepartments } from '../../../store/userPage/userPageAction';
import { getChallenges } from '../../../store/challenge/challengePageAction';
import { useSelector } from 'react-redux';
import useBodyOverflow from '../../hooks/useBodyOverFlow';
import LoadingScreen from '../../common/loading';

const ChallengePageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  min-height: 100vh;
`;

const UserPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState('All');
  const [search, setSearch] = useState<string>('');
  const { deleteChallengesLoading } = useSelector(
    (state: RootState) => state.challengePage,
  );
  const { companyLoading } = useSelector((state: RootState) => state.user);
  useBodyOverflow(companyLoading);

  useEffect(() => {
    dispatch(
      getChallenges({
        status,
        search,
      }),
    );
  }, [dispatch, search, status, deleteChallengesLoading]);

  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  return (
    <Layout>
      {companyLoading && <LoadingScreen />}
      <Navbar active={'Challenges'} />
      <ChallengePageContainer>
        <ChallengeSearch search={search} setSearch={setSearch} />
        <FilterHeader setSearch={setSearch} setStatus={setStatus} />
        <TableContainer setStatus={setStatus} status={status} />
      </ChallengePageContainer>
    </Layout>
  );
};

export default UserPage;

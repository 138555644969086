import { Provider } from 'react-redux';
import store from '../store/store';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes/routes';
import { AuthProvider } from './contexts/authContext';
import GlobalStyles from '../styles/global';
import { theme } from '../styles/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

const AppWrapper = () => {
  const MuiTheme = createTheme();
  return (
    <MuiThemeProvider theme={MuiTheme}>
      <Router>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Provider store={store}>
              <Routes />
              <GlobalStyles />
              <ToastContainer />
            </Provider>
          </AuthProvider>
        </ThemeProvider>
      </Router>
    </MuiThemeProvider>
  );
};

export default AppWrapper;

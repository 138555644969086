import styled from '@emotion/styled';
import Layout from '../../common/wrappers/Layout';
import Navbar from '../../common/navbar';
import SVG from '../../common/SVG';
import RightArrowIcon from '../../icons/RightArrowIcon';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import ChallengeStepsSection from './ChallengeStepsSection';
import ChallengeDetails from './ChallengeDetails';
import SelectChallengeDonation from './SelectChallengeDonation';
import ReviewDonation from './ReviewDonation';
import {
  createChallenge,
  createGiftChallenge,
  editDonationChallenge,
  editGiftChallenge,
  getChallengeById,
  getNgos,
} from '../../../store/createChallenge/createChallengePageAction';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch } from 'react-redux';
import AddModuleDonation from './AddModuleDonation';
import { formatDateByDash } from '../../../utils/dateFormater';
import SelectChallengeGift from './SelectChallengeGift';
import AddModuleGift from './AddModuleGift';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingScreen from '../../common/loading';
import ReviewGift from './ReviewGift';

const CreateChallengePageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  min-height: 100vh;
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.3rem;
    background-color: ${({ theme }) => theme.colors.link};
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

type ValuePiece = Date | null | string;

const CreateChallengePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [challengeType, setChallengeType] = useState('donation'); //gift or donation
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState<File | undefined>();

  const [amount, setAmount] = useState('0');
  const [goal, setGoal] = useState('0');

  const [timestart, setTimestart] = useState<ValuePiece>(null);
  const [timeend, setTimeend] = useState<ValuePiece>(null);

  // const [sponserBy, setSponserBy] = useState("");
  // const [sponserImage, setSponserImage] = useState<File | undefined>();

  const [ngoId, setNgoId] = useState('');

  const [challengeStatusType, setChallengeStatusType] = useState('');
  const [streaks, setStreaks] = useState('');
  const [giftCode, setGiftCode] = useState('');
  const [emailDescription, setEmailDescription] = useState('');
  const [winnerNumber, setWinnerNumber] = useState('');
  // get the one challenge by id data
  const {
    getChallengeByIdSuccess,
    getChallengeByIdError,
    getChallengeByIdLoading,
  } = useSelector((state: RootState) => state.createChallengePage);
  const [isEdit, setIsEdit] = useState(false);
  // const [sponsoredImageView, setSponsoredImageView] = useState<string>("");
  const [challengeImageView, setChallengeImageView] = useState<string>('');

  const [didDateChanged, setDidDateChanged] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getNgos());
  }, [dispatch]);

  const [createChallengeSteps, setCreateChallengeSteps] = useState({
    step1: {
      number: 1,
      title: 'Challenge Details',
      active: true,
    },
    step2: {
      number: 2,
      title: 'Select Challenge',
      active: false,
    },
    step3: {
      number: 3,
      title: 'Add Modules',
      active: false,
    },
    step4: {
      number: 4,
      title: 'Review',
      active: false,
    },
  });

  const handleCreate = () => {
    //console log all the data in a good formate

    if (!isEdit) {
      if (challengeType === 'donation') {
        if (
          !title ||
          !description ||
          !image ||
          !amount ||
          !goal ||
          !timestart ||
          !timeend ||
          !ngoId
        ) {
          return;
        }
        dispatch(
          createChallenge({
            title,
            description,
            image,
            amount,
            goal,
            timestart: timestart ? formatDateByDash(timestart) : '',
            timeend: timeend ? formatDateByDash(timeend) : '',
            ngo_id: ngoId,
          }),
        );
        setTitle('');
        setDescription('');
        setImage(undefined);
        setAmount('0');
        setGoal('0');
        setTimestart(null);
        setTimeend(null);
        setNgoId('');
      } else {
        if (
          !title ||
          !description ||
          !image ||
          !timestart ||
          !timeend ||
          !winnerNumber ||
          !giftCode ||
          !emailDescription
        ) {
          return;
        }
        dispatch(
          createGiftChallenge({
            title,
            description,
            image,
            goal,
            streaks,
            winnerNumber,
            giftCode,
            emaildescription: emailDescription,
            timestart: timestart ? formatDateByDash(timestart) : '',
            timeend: timeend ? formatDateByDash(timeend) : '',
            challengeStatusType,
          }),
        );
        setTitle('');
        setDescription('');
        setImage(undefined);
        setAmount('0');
        setGoal('0');
        setTimestart(null);
        setTimeend(null);
        setNgoId('');
      }
    } else {
      if (challengeType === 'donation') {
        if (
          !title ||
          !description ||
          !amount ||
          !goal ||
          !timestart ||
          !timeend ||
          !ngoId
        ) {
          return;
        }
        dispatch(
          editDonationChallenge({
            id: Number(id),
            title,
            description,
            image: image || undefined,
            amount,
            goal,
            timestart: timestart ? formatDateByDash(timestart) : '',
            timeend: timeend ? formatDateByDash(timeend) : '',
            ngo_id: Number(ngoId),
            didDateChanged,
          }),
        );
      } else {
        dispatch(
          editGiftChallenge({
            id: Number(id),
            title,
            description,
            image: image || undefined,
            goal,
            streaks,
            winnerNumber,
            giftCode,
            emaildescription: emailDescription,
            timestart: timestart ? formatDateByDash(timestart) : '',
            timeend: timeend ? formatDateByDash(timeend) : '',
            challengeStatusType,
            didDateChanged,
          }),
        );
      }
    }
  };

  //If The user is editing a challenge
  useEffect(() => {
    if (id) {
      dispatch(
        getChallengeById({
          id: Number(id),
        }),
      );
      setCreateChallengeSteps({
        ...createChallengeSteps,
        step1: {
          ...createChallengeSteps.step1,
          active: false,
        },
        step4: {
          ...createChallengeSteps.step4,
          active: true,
        },
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (
      getChallengeByIdSuccess &&
      getChallengeByIdSuccess.challenge.types === 'Gift'
    ) {
      setChallengeType('gift');
    } else {
      setChallengeType('donation');
    }
  }, [getChallengeByIdSuccess]);

  //fill the data if it is editing
  useEffect(() => {
    if (getChallengeByIdSuccess && id) {
      //set the edit status
      setIsEdit(true);
      if (getChallengeByIdSuccess.challenge.types === 'CompanyDonation') {
        //set the challenge type
        setChallengeType('donation');
        setTitle(getChallengeByIdSuccess.challenge.title);
        setDescription(getChallengeByIdSuccess.challenge.description);
        setChallengeImageView(getChallengeByIdSuccess.challenge.image);
        setAmount(
          getChallengeByIdSuccess.challenge.companydonationchallenge.amount.toString(),
        );
        setGoal(getChallengeByIdSuccess.challenge.goal.toString());
        setTimestart(new Date(getChallengeByIdSuccess.challenge.timestart));
        setTimeend(new Date(getChallengeByIdSuccess.challenge.timeend));
        // setSponserBy(getChallengeByIdSuccess.challenge.sponsoredby || "")
        // setSponsoredImageView(getChallengeByIdSuccess.challenge.sponsored_image || "")
        setNgoId(
          getChallengeByIdSuccess.challenge.companydonationchallenge.ngo_id.toString(),
        );
      } else {
        setTitle(getChallengeByIdSuccess.challenge.title);
        setDescription(getChallengeByIdSuccess.challenge.description);
        setChallengeImageView(getChallengeByIdSuccess.challenge.image);
        setTimestart(new Date(getChallengeByIdSuccess.challenge.timestart));
        setTimeend(new Date(getChallengeByIdSuccess.challenge.timeend));
        setGoal(getChallengeByIdSuccess.challenge.goal.toString());
        setStreaks(getChallengeByIdSuccess.challenge.streak.toString());
        setWinnerNumber(
          getChallengeByIdSuccess?.challenge?.giftchallenge?.winnerNumber.toString() ||
            '',
        );
        setGiftCode(
          getChallengeByIdSuccess?.challenge?.giftchallenge?.giftcode.toString() ||
            '',
        );
        setEmailDescription(
          getChallengeByIdSuccess?.challenge?.giftchallenge?.emaildescription.toString(),
        );
        if (
          getChallengeByIdSuccess &&
          getChallengeByIdSuccess.challenge.goal > 0
        ) {
          setChallengeStatusType('Target');
        }
        if (
          getChallengeByIdSuccess &&
          getChallengeByIdSuccess.challenge.streak > 0
        ) {
          setChallengeStatusType('Streaks');
        }
      }
    }
  }, [getChallengeByIdSuccess, id]);

  //Loading till the challenge is created
  if (id && getChallengeByIdLoading) return <LoadingScreen />;

  return (
    <Layout>
      <Navbar active={'Challenges'} />
      {/* if the challenge is not found */}
      {id && getChallengeByIdError ? (
        <h1>Challenge Not Found</h1>
      ) : (
        <CreateChallengePageContainer>
          <div className="header">
            <span>Challenges</span>
            <SVG
              icon={<RightArrowIcon />}
              color={theme.colors.white}
              width="17px"
              height="17px"
            />
            <span>Create a new challenge</span>
          </div>
          <ChallengeStepsSection steps={createChallengeSteps} />

          {createChallengeSteps.step1.active && (
            <ChallengeDetails
              challengeType={challengeType}
              setChallengeType={setChallengeType}
              title={title}
              setTitle={setTitle}
              description={description}
              setDescription={setDescription}
              image={image}
              setImage={setImage}
              setCreateChallengeSteps={setCreateChallengeSteps}
              challengeImageView={challengeImageView}
              isEdit={isEdit}
            />
          )}
          {createChallengeSteps.step2.active &&
            (challengeType === 'donation' ? (
              <SelectChallengeDonation
                amount={amount}
                setAmount={setAmount}
                goal={goal}
                setGoal={setGoal}
                timestart={timestart}
                setTimestart={setTimestart}
                timeend={timeend}
                setTimeend={setTimeend}
                setCreateChallengeSteps={setCreateChallengeSteps}
                isEdit={isEdit}
                setDidDateChanged={setDidDateChanged}
              />
            ) : (
              <SelectChallengeGift
                timestart={timestart}
                setTimestart={setTimestart}
                timeend={timeend}
                setTimeend={setTimeend}
                setCreateChallengeSteps={setCreateChallengeSteps}
                setChallengeStatusType={setChallengeStatusType}
                challengeStatusType={challengeStatusType}
                streaks={streaks}
                setStreaks={setStreaks}
                goal={goal}
                setGoal={setGoal}
                setDidDateChanged={setDidDateChanged}
              />
            ))}
          {createChallengeSteps.step3.active &&
            (challengeType === 'donation' ? (
              <AddModuleDonation
                setCreateChallengeSteps={setCreateChallengeSteps}
                ngoId={ngoId}
                setNgoId={setNgoId}
                isEdit={isEdit}
              />
            ) : (
              <AddModuleGift
                giftCode={giftCode}
                setGiftCode={setGiftCode}
                emailDescription={emailDescription}
                setEmailDescription={setEmailDescription}
                winnerNumber={winnerNumber}
                setWinnerNumber={setWinnerNumber}
                setCreateChallengeSteps={setCreateChallengeSteps}
              />
            ))}
          {createChallengeSteps.step4.active &&
            (challengeType === 'donation' ? (
              <ReviewDonation
                setCreateChallengeSteps={setCreateChallengeSteps}
                title={title}
                description={description}
                image={image}
                amount={amount}
                goal={goal}
                timestart={timestart}
                timeend={timeend}
                // sponserBy={sponserBy}
                // sponserImage={sponserImage}
                ngoId={ngoId}
                handleCreate={handleCreate}
                challengeImageView={challengeImageView}
                isEdit={isEdit}
              />
            ) : (
              <ReviewGift
                setCreateChallengeSteps={setCreateChallengeSteps}
                title={title}
                description={description}
                image={image}
                goal={goal}
                timestart={timestart}
                timeend={timeend}
                giftCode={giftCode}
                emailDescription={emailDescription}
                winnerNumber={winnerNumber}
                handleCreate={handleCreate}
                challengeImageView={challengeImageView}
                isEdit={isEdit}
                streaks={streaks}
                challengeStatusType={challengeStatusType}
              />
            ))}
        </CreateChallengePageContainer>
      )}
    </Layout>
  );
};

export default CreateChallengePage;

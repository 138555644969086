import styled from '@emotion/styled';
import ChallengeInput from '../../../common/inputs/ChallengeInput';
import IconButton from '../../../common/buttons/IconButton';
import SVG from '../../../common/SVG';
import EditIcon from '../../../icons/EditIcon';
import { useTheme } from '@emotion/react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import NgoCard from '../AddModuleDonation/NgoCard';
import PlusIcon from '../../../icons/PlusIcon';
import {
  dateFormatter,
  formatDateByDash,
} from '../../../../utils/dateFormater';
import { mobile } from '../../../../styles/media';

const ReviewDonationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  gap: 3rem;
  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xxxxl};
  }
  .section {
    width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    ${mobile(`width: 100%;`)}
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: ${({ theme }) => theme.fontSizes.xl};
        color: ${({ theme }) => theme.colors.text};
      }
      .edit {
        width: 150px;
      }
    }
    .image-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      .image-cover {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        border: 1px solid #ccc;
        position: relative;
        overflow: hidden;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(
            0,
            0,
            0,
            0.1
          ); /* Semi-transparent black background */
          z-index: 1;
          transition: all 0.3s ease-in-out;
        }
        .icon {
          font-size: 2rem;
          color: ${({ theme }) => theme.colors.primary};
          z-index: 2; /* Ensure the icon is above the pseudo-element */
        }
        input[type='file'] {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          z-index: 3; /* Ensure the input is clickable */
        }
      }
    }
    .thick-line {
      width: 100%;
      height: 4px;
      background-color: ${({ theme }) => theme.colors.text};
    }
  }
  .create-button {
    width: 900px;
    ${mobile(`width: 100%;`)}
  }
`;

type ValuePiece = Date | null | string;

interface Props {
  setCreateChallengeSteps: any;
  ngoId: string;
  // sponserImage: File | undefined;
  // sponserBy: string;
  timeend: ValuePiece;
  timestart: ValuePiece;
  image: File | undefined;
  title: string;
  description: string;
  amount: string;
  goal: string;
  handleCreate: () => void;
  challengeImageView?: string;
  isEdit?: boolean;
}

const ReviewDonation: React.FC<Props> = ({
  setCreateChallengeSteps,
  ngoId,
  // sponserImage,
  // sponserBy,
  timeend,
  timestart,
  image,
  title,
  description,
  amount,
  goal,
  handleCreate,
  challengeImageView,
  isEdit,
}) => {
  const theme = useTheme();
  const { getNgosSuccess } = useSelector(
    (state: RootState) => state.createChallengePage,
  );

  return (
    <ReviewDonationContainer>
      <h1>Review Details</h1>
      <div className="section">
        <div className="title">
          <h1>Challenge Details</h1>
          <div className="edit">
            <IconButton
              icon={
                <SVG
                  icon={<EditIcon />}
                  color={theme.colors.text}
                  width="20px"
                  height="20px"
                />
              }
              onClick={() => {
                setCreateChallengeSteps({
                  step1: {
                    number: 1,
                    title: 'Challenge Details',
                    active: true,
                  },
                  step2: {
                    number: 2,
                    title: 'Select Challenge',
                    active: false,
                  },
                  step3: { number: 3, title: 'Add Modules', active: false },
                  step4: { number: 4, title: 'Review', active: false },
                });
              }}
              text={'Edit Details'}
              border={`3px solid ${theme.colors.text}`}
              color={theme.colors.text}
              backgroundColor={theme.colors.white}
            />
          </div>
        </div>
        <div className="thick-line" />
        <ChallengeInput
          name="title"
          type="text"
          placeholder="Title"
          value={title}
          error={undefined}
          disabled={true}
        />
        <ChallengeInput
          name="description"
          type="text"
          placeholder="Description"
          value={description}
          error={undefined}
          disabled={true}
        />
        <div className="image-container">
          <h2>Challenge Image</h2>
          <div
            className="image-cover"
            style={{
              backgroundImage: image
                ? `url(${URL.createObjectURL(image)})`
                : challengeImageView
                  ? `url(${challengeImageView})`
                  : 'none',
            }}
          ></div>
        </div>
      </div>
      <br />
      <br />
      <br />

      <div className="section">
        <div className="title">
          <h1>Select Challenge Details</h1>
          <div className="edit">
            <IconButton
              icon={
                <SVG
                  icon={<EditIcon />}
                  color={theme.colors.text}
                  width="20px"
                  height="20px"
                />
              }
              onClick={() => {
                setCreateChallengeSteps({
                  step1: {
                    number: 1,
                    title: 'Challenge Details',
                    active: false,
                  },
                  step2: { number: 2, title: 'Select Challenge', active: true },
                  step3: { number: 3, title: 'Add Modules', active: false },
                  step4: { number: 4, title: 'Review', active: false },
                });
              }}
              text={'Edit Details'}
              border={`3px solid ${theme.colors.text}`}
              color={theme.colors.text}
              backgroundColor={theme.colors.white}
            />
          </div>
        </div>
        <div className="thick-line" />
        <ChallengeInput
          name="amount"
          type="text"
          placeholder="Amount"
          value={amount}
          error={undefined}
          disabled={true}
        />
        <ChallengeInput
          name="goal"
          type="text"
          placeholder="Goal"
          value={goal}
          error={undefined}
          disabled={true}
        />
        <ChallengeInput
          name="timestart"
          type="text"
          placeholder="Start Time"
          value={formatDateByDash(timestart as string)}
          error={undefined}
          disabled={true}
        />
        <ChallengeInput
          name="timeend"
          type="text"
          placeholder="end Time"
          value={formatDateByDash(timeend as string)}
          error={undefined}
          disabled={true}
        />
      </div>

      <br />
      <br />
      <br />

      {!isEdit && (
        <div className="section">
          <div className="title">
            <h1>Add Module Details</h1>
            <div className="edit">
              <IconButton
                icon={
                  <SVG
                    icon={<EditIcon />}
                    color={theme.colors.text}
                    width="20px"
                    height="20px"
                  />
                }
                onClick={() => {
                  setCreateChallengeSteps({
                    step1: {
                      number: 1,
                      title: 'Challenge Details',
                      active: false,
                    },
                    step2: {
                      number: 2,
                      title: 'Select Challenge',
                      active: false,
                    },
                    step3: { number: 3, title: 'Add Modules', active: true },
                    step4: { number: 4, title: 'Review', active: false },
                  });
                }}
                text={'Edit Details'}
                border={`3px solid ${theme.colors.text}`}
                color={theme.colors.text}
                backgroundColor={theme.colors.white}
              />
            </div>
          </div>
          <div className="thick-line" />
          {/* <ChallengeInput
            name="sponsorBy"
            type="text"
            placeholder="sponsorBy"
            value={sponserBy}
            error={undefined}
            disabled={true}
          />
          <div className="image-container">
            <h2>Sponsor Image</h2>
            <div className="image-cover" style={{ backgroundImage: sponserImage ? `url(${URL.createObjectURL(sponserImage)})` : 'none' }} />
          </div> */}
          {getNgosSuccess?.ngos.rows.map(
            (ngo: {
              id: number;
              name: string;
              user_id: number;
              url: string;
              logo: string;
              location: string;
              description: string;
            }) => {
              if (ngo.user_id.toString() === ngoId)
                return (
                  <NgoCard
                    ngo={ngo}
                    key={ngo.id}
                    isSelected={ngo.user_id.toString() === ngoId}
                  />
                );
            },
          )}
        </div>
      )}

      <div className="create-button">
        <IconButton
          icon={
            <SVG
              icon={<PlusIcon />}
              color={theme.colors.white}
              stockColor={theme.colors.white}
              width="20px"
              height="20px"
            />
          }
          onClick={() => {
            handleCreate();
          }}
          text={'Submit'}
          border={''}
          color={theme.colors.white}
          backgroundColor={theme.colors.green}
        />
      </div>
    </ReviewDonationContainer>
  );
};

export default ReviewDonation;

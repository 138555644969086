import styled from '@emotion/styled';
import Header from './Header';

import CalenderSection from './CalenderSection';
import FormData from './FormData';
import NextButton from '../../../common/buttons/NextButton';
import { useEffect, useState } from 'react';
import { mobile } from '../../../../styles/media';

const SelectChallengeDonationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  .next-button-container {
    width: 900px;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    ${mobile(`width: 100%;`)}
  }
`;

type ValuePiece = Date | null | string;

interface Props {
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  goal: string;
  setGoal: React.Dispatch<React.SetStateAction<string>>;
  timestart: ValuePiece;
  setTimestart: React.Dispatch<React.SetStateAction<ValuePiece>>;
  timeend: ValuePiece;
  setTimeend: React.Dispatch<React.SetStateAction<ValuePiece>>;
  setCreateChallengeSteps: React.Dispatch<React.SetStateAction<any>>;
  isEdit?: boolean;
  setDidDateChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectChallengeDonation: React.FC<Props> = ({
  amount,
  setAmount,
  goal,
  setGoal,
  timestart,
  setTimestart,
  timeend,
  setTimeend,
  setCreateChallengeSteps,
  isEdit,
  setDidDateChanged,
}) => {
  const [message, setMessage] = useState('');
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (
      amount &&
      parseFloat(amount) > 0 &&
      goal &&
      parseFloat(goal) > 0 &&
      timestart &&
      timeend
    ) {
      setActive(true);
    } else {
      if (typeof parseInt(amount) !== 'number')
        setMessage('Please enter amount');
      else if (parseInt(amount) <= 0)
        setMessage('Amount must be greater than 0');
      else if (typeof parseInt(goal) !== 'number')
        setMessage('Please enter goal');
      else if (parseInt(goal) <= 0) setMessage('Goal must be greater than 0');
      else if (!timestart) setMessage('Please select start date');
      else if (!timeend) setMessage('Please select end date');
      setActive(false);
    }
  }, [amount, goal, timeend, timestart]);
  return (
    <SelectChallengeDonationContainer>
      <Header />
      <FormData
        amount={amount}
        setAmount={setAmount}
        goal={goal}
        setGoal={setGoal}
      />
      {/* <CardsContainer />
      <PermissionSection /> */}
      <CalenderSection
        timestart={timestart}
        setTimestart={setTimestart}
        timeend={timeend}
        setTimeend={setTimeend}
        setDidDateChanged={setDidDateChanged}
      />
      <div className="next-button-container">
        <NextButton
          message={message}
          onClick={() => {
            if (!isEdit) {
              setCreateChallengeSteps({
                step1: {
                  number: 1,
                  title: 'Challenge Details',
                  active: false,
                },
                step2: {
                  number: 2,
                  title: 'Select Challenge',
                  active: false,
                },
                step3: {
                  number: 3,
                  title: 'Add Modules',
                  active: true,
                },
                step4: {
                  number: 4,
                  title: 'Review',
                  active: false,
                },
              });
            } else {
              setCreateChallengeSteps({
                step1: {
                  number: 1,
                  title: 'Challenge Details',
                  active: false,
                },
                step2: {
                  number: 2,
                  title: 'Select Challenge',
                  active: false,
                },
                step3: {
                  number: 3,
                  title: 'Add Modules',
                  active: false,
                },
                step4: {
                  number: 4,
                  title: 'Review',
                  active: true,
                },
              });
            }
          }}
          isAllowed={active}
        />
      </div>
    </SelectChallengeDonationContainer>
  );
};

export default SelectChallengeDonation;

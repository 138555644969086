import { useState } from 'react';
import Layout from '../../common/wrappers/Layout';
import Navbar from '../../common/navbar';
import Header from './Header';
import RunReportBody from './Body/RunReport';
import CompletedReportsBody from './Body/CompletedReports';
// import { CSVLink, CSVDownload } from "react-csv";
import LoadingScreen from '../../common/loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import useBodyOverflow from '../../hooks/useBodyOverFlow';

const ReportingLayout = () => {
  const [page, setPage] = useState<number>(1);

  const changePage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  // const csvData = [
  //   ["firstname", "lastname", "email"],
  //   ["Ahmed", "Tomi", "ah@smthing.co.com"],
  //   ["Raed", "Labes", "rl@smthing.co.com"],
  //   ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  // ];
  const { companyLoading } = useSelector((state: RootState) => state.user);
  useBodyOverflow(companyLoading);
  return (
    <Layout>
      {companyLoading && <LoadingScreen />}
      <Navbar active={'Reporting'} />
      {/* <CSVLink data={csvData}>Download me</CSVLink>; */}
      <Header onPageChange={changePage} currentPage={page} />
      {page === 1 && <RunReportBody />}
      {page === 2 && <CompletedReportsBody />}
    </Layout>
  );
};

export default ReportingLayout;

import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface ChallengePageState {
  getChallengeLoading: boolean;
  getChallengeError: string | null;
  getChallengeSuccess: null | {
    challenges: {
      id: number;
      image: string;
      title: string;
      description: string;
      timeend: string;
    }[];
  };

  getNgosLoading: boolean;
  getNgosError: string | null;
  getNgosSuccess: null | {
    ngos: {
      rows: {
        id: number;
        description: string;
        user_id: number;
        url: string;
        logo: string;
        location: string;
        name: string;
      }[];
    };
  };

  createChallengeLoading: boolean;
  createChallengeError: string | null;
  createChallengeSuccess: null | {};

  getChallengeByIdLoading: boolean;
  getChallengeByIdError: string | null;
  getChallengeByIdSuccess: null | {
    challenge: {
      id: number;
      user_id: number;
      types: 'CompanyDonation' | 'Gift';
      winner_id: number | null;
      iscompleted: boolean;
      goal: number;
      streak: number;
      timestart: string;
      timeend: string;
      description: string;
      title: string;
      ispublic: boolean;
      image: string;
      is_approved: boolean;
      isDeleted: boolean;
      is_sponsored: boolean;
      sponsoredby: string | null;
      sponsored_image: string | null;
      createdAt: string;
      updatedAt: string;
      user: {
        id: number;
        email: string;
        password: string;
        phone: string;
        countryCode: string;
        socket_id: string | null;
        role: string;
        token: string;
        isDeleted: boolean;
        createdAt: string;
        updatedAt: string;
        adminprofile: {
          id: number;
          user_id: number;
          username: string;
          createdAt: string;
          updatedAt: string;
        };
      };
      challengesMembers: {
        id: number;
        user_id: number;
        challenges_id: number;
        finished_steps: number;
        createdAt: string;
        updatedAt: string;
      }[];
      giftchallenge: {
        id: number;
        challenge_id: number;
        giftcode: string;
        emaildescription: string;
        winnerNumber: number;
        createdAt: string;
        updatedAt: string;
      };
      rewards: {
        id: number;
        challenge_id: number;
        name: string | null;
        description: string | null;
        type: string;
        amount: number;
        image: string;
        createdAt: string;
        updatedAt: string;
        imagesrewards: any[];
      }[];
      companydonationchallenge: {
        id: number;
        ngo_id: number;
        amount: number;
      };
    };
    message: string;
    statusCode: number;
    success: boolean;
  };

  editChallengeByIdLoading: boolean;
  editChallengeByIdError: string | null;
  editChallengeByIdSuccess: null | {};
}

const initialState: ChallengePageState = {
  getChallengeLoading: false,
  getChallengeError: null,
  getChallengeSuccess: null,

  getNgosLoading: false,
  getNgosError: null,
  getNgosSuccess: null,

  createChallengeLoading: false,
  createChallengeError: null,
  createChallengeSuccess: null,

  getChallengeByIdLoading: true,
  getChallengeByIdError: null,
  getChallengeByIdSuccess: null,

  editChallengeByIdLoading: false,
  editChallengeByIdError: null,
  editChallengeByIdSuccess: null,
};

export const challengePageSlice = createSlice({
  name: 'createChallengePage',
  initialState,
  reducers: {
    getChallengeRequest: (state) => {
      state.getChallengeLoading = true;
      state.getChallengeError = null;
      state.getChallengeSuccess = null;
    },
    getChallengeSuccess: (state, action) => {
      state.getChallengeLoading = false;
      state.getChallengeError = null;
      state.getChallengeSuccess = action.payload;
    },
    getChallengeFailure: (state, action) => {
      state.getChallengeLoading = false;
      state.getChallengeError = action.payload;
    },

    getNgosRequest: (state) => {
      state.getNgosLoading = true;
      state.getNgosError = null;
      state.getNgosSuccess = null;
    },
    getNgosSuccess: (state, action) => {
      state.getNgosLoading = false;
      state.getNgosError = null;
      state.getNgosSuccess = action.payload;
    },
    getNgosFailure: (state, action) => {
      state.getNgosLoading = false;
      state.getNgosError = action.payload;
    },

    createChallengeRequest: (state) => {
      state.createChallengeLoading = true;
      state.createChallengeError = null;
      state.createChallengeSuccess = null;
    },
    createChallengeSuccess: (state, action) => {
      state.createChallengeLoading = false;
      state.createChallengeError = null;
      state.createChallengeSuccess = action.payload;
    },
    createChallengeFailure: (state, action) => {
      state.createChallengeLoading = false;
      state.createChallengeError = action.payload;
    },

    getChallengeByIdRequest: (state) => {
      state.getChallengeByIdLoading = true;
      state.getChallengeByIdError = null;
      state.getChallengeByIdSuccess = null;
    },
    getChallengeByIdSuccess: (state, action) => {
      state.getChallengeByIdLoading = false;
      state.getChallengeByIdError = null;
      state.getChallengeByIdSuccess = action.payload;
    },
    getChallengeByIdFailure: (state, action) => {
      state.getChallengeByIdLoading = false;
      state.getChallengeByIdError = action.payload;
    },

    editChallengeByIdRequest: (state) => {
      state.editChallengeByIdLoading = true;
      state.editChallengeByIdError = null;
      state.editChallengeByIdSuccess = null;
    },
    editChallengeByIdSuccess: (state, action) => {
      state.editChallengeByIdLoading = false;
      state.editChallengeByIdError = null;
      state.editChallengeByIdSuccess = action.payload;
    },
    editChallengeByIdFailure: (state, action) => {
      state.editChallengeByIdLoading = false;
      state.editChallengeByIdError = action.payload;
    },
  },
});

export const {
  getChallengeRequest,
  getChallengeSuccess,
  getChallengeFailure,
  getNgosRequest,
  getNgosSuccess,
  getNgosFailure,
  createChallengeRequest,
  createChallengeSuccess,
  createChallengeFailure,
  getChallengeByIdRequest,
  getChallengeByIdSuccess,
  getChallengeByIdFailure,
  editChallengeByIdRequest,
  editChallengeByIdSuccess,
  editChallengeByIdFailure,
} = challengePageSlice.actions;

export const selectChallengePage = (state: RootState) =>
  state.createChallengePage;

export default challengePageSlice.reducer;

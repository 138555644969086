import styled from '@emotion/styled';
import React from 'react';
import CalenderIcon from '../../icons/CalenderIcon';
import SVG from '../SVG';
import { useTheme } from '@emotion/react';
import SelectIcon from '../SelectIcon';
import { mobile } from '../../../styles/media';

interface PropsType {
  isVertical?: boolean;
}

const SelectedMultipleDateContainer = styled.div<PropsType>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  gap: 1rem;
  ${({ isVertical }) =>
    isVertical &&
    mobile(`
        flex-direction: column;
    `)}
  input {
    width: 100%;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s};
    text-align: center;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 1;
  }
`;

interface Props {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  isVertical?: boolean;
}

const SelectedMultipleDate: React.FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isVertical,
}) => {
  const theme = useTheme();

  return (
    <SelectedMultipleDateContainer isVertical={isVertical}>
      <SVG
        icon={<CalenderIcon />}
        width="20px"
        height="20px"
        color={theme.colors.text}
      />
      <SelectIcon
        children={
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        }
      />
      <SelectIcon
        children={
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        }
      />
    </SelectedMultipleDateContainer>
  );
};

export default SelectedMultipleDate;

import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';

export const TableWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 300px auto;
  padding: 1rem;
  gap: 1rem;
  min-height: 260px;
  ${mobile(`
        grid-template-columns: auto !important;
    `)}
  .filters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    .filter {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.white};
      padding: 1rem;
      border-radius: ${({ theme }) => theme.borderRadius.s};
      display: flex;
      flex-direction: column;
      justify-content: center;
      .filter-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          font-size: ${({ theme }) => theme.fontSizes.xl};
          color: ${({ theme }) => theme.colors.text};
        }
      }
      .input-filter {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        input {
          width: 100%;
        }
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      list-style-type: none;
      li {
        display: flex;
        align-items: center;
        gap: 1rem;
        span {
          color: ${({ theme }) => theme.colors.text};
        }
      }
    }
  }
  .table {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      background-color: ${({ theme }) => theme.colors.primary};
      border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
      border-top-left-radius: ${({ theme }) => theme.borderRadius.s};
      h3 {
        color: ${({ theme }) => theme.colors.white};
      }
      .center-button {
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .icon-container {
        width: 150px;
      }
    }
    .center-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 40px;
    }
    .view-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .super-app-theme--header {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.white};
    }
    .MuiDataGrid-virtualScroller {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .MuiDataGrid-columnHeader {
      background-color: ${({ theme }) => theme.colors.text};
    }
  }
  .image-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .MuiDataGrid-filler {
    display: none;
  }
`;

import { useTheme } from '@emotion/react';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import SVG from '../common/SVG';
import IconButton from '../common/buttons/IconButton';
import MinusIcon from '../icons/MinusIcon';

export const useUserTable = () => {
  const theme = useTheme();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'avatar',
      headerName: 'Avatar',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (record) => (
        <div className="image-container">
          <img src={record.value} alt="User" />
        </div>
      ),
    },
    {
      field: 'userprofile',
      headerName: 'View Profile',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (record) => (
        <div className="view-container">
          <Link to={`/user/${record.value}`} className="center-content">
            <IconButton
              border="none"
              text="View"
              icon={
                <SVG
                  icon={<MinusIcon />}
                  color={theme.colors.white}
                  width="20px"
                  height="20px"
                />
              }
            />
          </Link>
        </div>
      ),
    },
  ];

  return { columns };
};

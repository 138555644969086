import React from 'react';

interface AvatarProps {
  firstName: string;
  width?: string;
  height?: string;
  fontSize?: string;
}

const stringToColor = (string: string): string => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color;
};

const Avatar: React.FC<AvatarProps> = ({
  firstName,
  width,
  height,
  fontSize,
}) => {
  const backgroundColor = stringToColor(firstName);
  const initial = firstName.charAt(0).toUpperCase();

  const avatarStyle: React.CSSProperties = {
    width: width ?? '40px',
    height: height ?? '40px',
    borderRadius: '50%',
    backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    fontSize: fontSize ?? '18px',
    userSelect: 'none',
  };

  return <div style={avatarStyle}>{initial}</div>;
};

export default Avatar;

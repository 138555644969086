const FilterIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4_13)">
        <path
          d="M133.333 166.667H533.333"
          stroke="#0C0310"
          strokeWidth="66.6667"
          strokeLinecap="round"
        />
        <path
          d="M133.333 400H333.333"
          stroke="#0C0310"
          strokeWidth="66.6667"
          strokeLinecap="round"
        />
        <path
          d="M466.667 400H666.667"
          stroke="#0C0310"
          strokeWidth="66.6667"
          strokeLinecap="round"
        />
        <path
          d="M266.667 633.333H666.667"
          stroke="#0C0310"
          strokeWidth="66.6667"
          strokeLinecap="round"
        />
        <path
          d="M600 233.333C636.819 233.333 666.667 203.486 666.667 166.667C666.667 129.848 636.819 100 600 100C563.181 100 533.333 129.848 533.333 166.667C533.333 203.486 563.181 233.333 600 233.333Z"
          stroke="#0C0310"
          strokeWidth="66.6667"
          strokeLinecap="round"
        />
        <path
          d="M400 466.667C436.819 466.667 466.667 436.819 466.667 400C466.667 363.181 436.819 333.333 400 333.333C363.181 333.333 333.333 363.181 333.333 400C333.333 436.819 363.181 466.667 400 466.667Z"
          stroke="#0C0310"
          strokeWidth="66.6667"
          strokeLinecap="round"
        />
        <path
          d="M200 700C236.819 700 266.667 670.152 266.667 633.333C266.667 596.514 236.819 566.667 200 566.667C163.181 566.667 133.333 596.514 133.333 633.333C133.333 670.152 163.181 700 200 700Z"
          stroke="#0C0310"
          strokeWidth="66.6667"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_13">
          <rect width="800" height="800" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIcon;

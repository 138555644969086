import axios from 'axios';
import {
  companyFailure,
  companyRequest,
  companySuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
} from './userReducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginFormInput } from '../../types/auth/login';

export const login = createAsyncThunk(
  'user/login',
  async (data: LoginFormInput, { dispatch }) => {
    try {
      dispatch(loginRequest());
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/loginAdmin`,
        data,
      );

      if (response && response.data && response.data.data) {
        dispatch(loginSuccess(response.data.data));
        localStorage.setItem('id', response?.data?.data?.company?.id);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(loginFailure(err?.response?.data?.message));
      } else {
        dispatch(loginFailure((err as Error).message));
      }
    }
  },
);

export const getCompanyByToken = createAsyncThunk(
  'company/getCompanybytoken',
  async (data, { dispatch }) => {
    try {
      dispatch(companyRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/company/getCompanybytoken`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      localStorage.setItem('id', response?.data?.data?.companyprofile?.id);

      if (response && response.data && response.data.data) {
        dispatch(companySuccess(response.data.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(companyFailure(err?.response?.data?.message));
      } else {
        dispatch(companyFailure((err as Error).message));
      }
    }
  },
);

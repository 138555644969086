import styled from '@emotion/styled';
import SearchInput from '../../../common/inputs/SearchInput';
import IconButton from '../../../common/buttons/IconButton';
import SVG from '../../../common/SVG';
import { useTheme } from '@emotion/react';
import { mobile } from '../../../../styles/media';
import PlusIcon from '../../../icons/PlusIcon';
import { Link } from 'react-router-dom';

const ChallengeSearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.link};
  ${mobile(`
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        align-items: flex-start;
        width: 100%;
    `)}
  h1 {
    color: ${({ theme }) => theme.colors.white};
    width: 20%;
  }
  .search-container {
    width: 80%;
    ${mobile(`width: 100%;`)}
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 80%;
    ${mobile(`width: 100%;`)}
  }
  .button-container {
    width: 250px;
    height: 60px;
  }
`;
interface Props {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const ChallengeSearch: React.FC<Props> = ({ search, setSearch }) => {
  const theme = useTheme();
  return (
    <ChallengeSearchContainer>
      <h1>Challenges</h1>
      <div className="content">
        <SearchInput
          placeholder="Search challenge"
          value={search}
          setValue={setSearch}
        />
        <Link to="/create-challenge" className="button-container">
          <IconButton
            icon={
              <SVG
                icon={<PlusIcon />}
                stockColor={theme.colors.white}
                width="20px"
                height="20px"
                cursor="pointer"
              />
            }
            text="Create Challenge"
            backgroundColor={theme.colors.green}
            border={``}
            onClick={() => {}}
          />
        </Link>
      </div>
    </ChallengeSearchContainer>
  );
};

export default ChallengeSearch;

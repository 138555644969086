import React, { useState } from 'react';
import SubmitButton from '../../../common/buttons/SubmitButton';
import { useTheme } from '@emotion/react';
import { BodyWrapper } from './style';
import Avatar from '../../../common/DefaultAvatar';

interface Props {
  userName: string;
  phone: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  image: File | undefined;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  existImage?: string;
  handleSubmit: () => void;
  emailChangeStatus: boolean;
  setEmailChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  editUserLoading: boolean;
}

const BodyContainer: React.FC<Props> = ({
  userName,
  phone,
  setUserName,
  setPassword,
  email,
  setEmail,
  password,
  setPhone,
  image,
  handleImageChange,
  existImage,
  handleSubmit,
  setEmailChangeStatus,
  editUserLoading,
}) => {
  const [activeOption, setActiveOption] = useState(1);
  const theme = useTheme();

  const changePage = (pageNumber: number) => {
    setActiveOption(pageNumber);
  };

  return (
    <BodyWrapper>
      <div className="options-container">
        <span
          className={activeOption === 1 ? 'active' : ''}
          onClick={() => changePage(1)}
        >
          Organization settings
        </span>
        <span className={activeOption === 2 ? 'active' : ''} onClick={() => 2}>
          App preferences
        </span>
        <span
          className={activeOption === 3 ? 'active' : ''}
          onClick={() => changePage(3)}
        >
          Subscription plan management
        </span>
      </div>

      {activeOption === 1 && (
        <div className="form-container">
          <h3>Organization settings</h3>

          <div className="form-section-title">Organization Logo</div>
          <div className="image-input-container">
            <input
              type="file"
              className="form-logo-field"
              onChange={(e) => handleImageChange(e)}
            />
            {existImage === '' && !image ? (
              <Avatar firstName={userName} />
            ) : (
              <img
                src={image ? URL.createObjectURL(image) : existImage}
                alt="User"
              />
            )}
          </div>

          <div className="form-section-title">Organization Name</div>
          <input
            type="text"
            maxLength={50}
            className="form-name-field"
            placeholder="Organization Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />

          <div className="form-section-title">Organization Email</div>
          <input
            type="text"
            maxLength={50}
            className="form-name-field"
            placeholder="Organization Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailChangeStatus(true);
            }}
          />

          <div className="form-section-title">Organization Phone</div>
          <input
            type="text"
            maxLength={50}
            className="form-name-field"
            placeholder="Organization Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <div className="form-section-title">Organization Password</div>
          <input
            type="text"
            maxLength={50}
            className="form-name-field"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="submit-button">
            <SubmitButton
              label={'Save'}
              loading={editUserLoading}
              onClick={handleSubmit}
              border={`2px solid ${theme.colors.text};`}
              cl={theme.colors.text}
              bg={theme.colors.white}
            />
          </div>
        </div>
      )}
      {activeOption === 2 && <div className="form-container"></div>}
      {activeOption === 3 && <div className="form-container"></div>}
    </BodyWrapper>
  );
};

export default BodyContainer;

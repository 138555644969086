import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { mobile, tablet } from '../../../styles/media';

export const NavBarContainer = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  ${mobile(`
  height: 115px;
  flex-direction: column;
`)}
  ${tablet(`
  height: 120px;
  flex-direction: column;
`)}
background-color: ${({ theme }) => theme.colors.primary};
  .upper-part-container {
    width: 100%;
    height: 75px;
    ${mobile(`
  height: 35px;
`)}
    ${tablet(`
  height: 45px;
`)}
  display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary};
    .logo-container {
      width: 140px;
      display: flex;
      gap: 0.5rem;
      flex-direction: row;
      align-items: center;
      ${mobile(`
     width: 110px;
      `)}
      img {
        width: 35%;
        height: 35%;
      }
      span {
        font-size: ${({ theme }) => theme.fontSizes.l};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: ${({ theme }) => theme.colors.white};

        ${({ theme }) =>
          mobile(`
        font-size:  ${theme.fontSizes.s};
      `)}
      }
    }
    .button-section {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      align-items: center;
    }

    .settings-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;

      span {
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 1rem;
        ${mobile(`
      font-size: 0.77rem;`)}
        color: ${({ theme }) => theme.colors.white};
        border-radius: ${({ theme }) => theme.borderRadius.xs};
      }
    }
    .avatar-container {
      width: 70px;
      display: flex;
      padding: 0.8rem;
      justify-content: center;
      align-items: center;
      ${mobile(`
    padding: 0.5rem;
    width: 50px;
      `)}

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .settings-wrapper {
      display: flex;

      flex-direction: row;
      align-items: center;
    }
  }
  .mobile-button-section {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  padding: 10px 15px;
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.colors.primary};
  ${mobile(`
    padding: 5px;
    font-size: 0.77rem;`)}
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.link};
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.link};
  }
`;

export const StyledAvatarLink = styled(Link)`
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  &.active {
    background-color: ${({ theme }) => theme.colors.link};
  }

  ${mobile(`
    padding-left: 5px;
  padding-right: 5px;`)}
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.link};
  }
`;

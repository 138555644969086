import styled from '@emotion/styled';
import SVG from '../../../../../common/SVG';
import CloseIcon from '../../../../../icons/CloseIcon';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';

const ModelContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .black-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .content {
    width: 500px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.l};
    z-index: 1;
    padding: 2rem 2rem 7rem 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .icon-container {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
    }
    h1 {
      text-align: left;
      width: 100%;
    }
    .text-container {
      width: 100%;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.5rem;
      p {
        a {
          width: 100px !important;
        }
      }
    }
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      aspect-ratio: 1;
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius.l};
    }
  }
`;

interface props {
  ngo: {
    id: number;
    description: string;
    user_id: number;
    url: string;
    logo: string;
    location: string;
    name: string;
  };
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  active: boolean;
}

const Model: React.FC<props> = ({ ngo, setActive, active }) => {
  const theme = useTheme();
  return (
    <ModelContainer>
      <div className="content">
        <div className="icon-container" onClick={() => setActive(false)}>
          <SVG
            icon={<CloseIcon />}
            color={theme.colors.text}
            width="40px"
            height="40px"
            cursor="pointer"
          />
        </div>
        <h1>Details:</h1>
        <div className="text-container">
          <p>
            <b>name:</b> {ngo.name}
          </p>
          <p>
            <b>description:</b>{' '}
            {ngo.description.length > 50
              ? ngo.description.substring(0, 200) + '...'
              : ngo.description.substring(0, 200)}
          </p>
          <p>
            <b>location:</b> {ngo.location}
          </p>
          <p>
            <b>url:</b>{' '}
            <Link to={ngo.url}>
              {ngo.url.length > 50
                ? ngo.url.substring(0, 50) + '...'
                : ngo.url.substring(0, 50)}
            </Link>
          </p>
        </div>
        <img src={`${process.env.REACT_APP_URL}/ngo/${ngo.logo}`} alt="logo" />
      </div>
      <div className="black-background" onClick={() => setActive(false)} />
    </ModelContainer>
  );
};

export default Model;

import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';
import Card from './Card';

const SuperStarsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary};
  .wrapper {
    width: 90%;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    .line {
      width: 90%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.lightGray};
    }
    .header {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 1rem 1rem 1rem;
      h1 {
        font-size: ${({ theme }) => theme.fontSizes.xl};
        color: ${({ theme }) => theme.colors.text};
        ${({ theme }) => mobile(`font-size: ${theme.fontSizes.m}`)}
      }
    }
    .body {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 2rem;
    }
  }
`;

const SuperStars = () => {
  return (
    <SuperStarsContainer>
      <div className="wrapper">
        <div className="line" />
        <div className="header">
          <h1>SuperStars</h1>
          <div className="calender-container">
            {/* <SelectedMultipleDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} isVertical={true} /> */}
          </div>
        </div>
        <div className="body">
          <Card />
        </div>
      </div>
    </SuperStarsContainer>
  );
};

export default SuperStars;

import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface UserPageState {
  userStatisticLoading: boolean;
  userStatisticError: any;
  userStatisticSuccess: null | {};
  employeesLoading: boolean;
  employeesError: any;
  employeesSuccess: any;
  departmentsLoading: boolean;
  departmentsError: any;
  departmentsSuccess:
    | null
    | {
        id: number;
        createdAt: string;
        department_id: number;
        department: {
          id: number;
          name: string;
        };
      }[];
  getEmployeeByIdLoading: boolean;
  getEmployeeByIdError: any;
  getEmployeeByIdSuccess: null | {
    Employee: {
      id: number;
      username: string;
      lastConnection: string;
      location: string;
      createdAt: string;
      avatar: string;
      user: {
        email: string;
      };
    };
    todaySteps: number;
    averageSteps: number;
    steps: {
      id: number;
      finished_steps: number;
      steps: number;
      createdAt: string;
    }[];
  };
  RemoveEmployeesLoading: boolean;
  RemoveEmployeesSuccess: null | {};
  RemoveEmployeesError: any;
}

const initialState: UserPageState = {
  userStatisticLoading: false,
  userStatisticError: null,
  userStatisticSuccess: {},
  employeesLoading: false,
  employeesError: null,
  employeesSuccess: null,
  departmentsLoading: false,
  departmentsError: null,
  departmentsSuccess: null,
  getEmployeeByIdLoading: false,
  getEmployeeByIdError: null,
  getEmployeeByIdSuccess: null,
  RemoveEmployeesLoading: false,
  RemoveEmployeesSuccess: null,
  RemoveEmployeesError: null,
};

export const userPageSlice = createSlice({
  name: 'userPage',
  initialState,
  reducers: {
    userStatisticRequest: (state) => {
      state.userStatisticLoading = true;
      state.userStatisticError = null;
      state.userStatisticSuccess = {};
    },
    userStatisticSuccess: (state, action) => {
      state.userStatisticLoading = false;
      state.userStatisticError = null;
      state.userStatisticSuccess = action.payload;
    },
    userStatisticFailure: (state, action) => {
      state.userStatisticLoading = false;
      state.userStatisticError = action.payload;
    },
    getEmployeeRequest: (state) => {
      state.employeesLoading = true;
      state.employeesError = null;
      state.employeesSuccess = null;
    },
    getEmployeeSuccess: (state, action) => {
      state.employeesLoading = false;
      state.employeesError = null;
      state.employeesSuccess = action.payload;
    },
    getEmployeeFailure: (state, action) => {
      state.employeesLoading = false;
      state.employeesError = action.payload;
    },
    getDepartmentsRequest: (state) => {
      state.departmentsLoading = true;
      state.departmentsError = null;
      state.departmentsSuccess = null;
    },
    getDepartmentsSuccess: (state, action) => {
      state.departmentsLoading = false;
      state.departmentsError = null;
      state.departmentsSuccess = action.payload;
    },
    getDepartmentsFailure: (state, action) => {
      state.departmentsLoading = false;
      state.departmentsError = action.payload;
    },
    getEmployeeByIdRequest: (state) => {
      state.getEmployeeByIdLoading = true;
      state.getEmployeeByIdError = null;
      state.getEmployeeByIdSuccess = null;
    },
    getEmployeeByIdSuccess: (state, action) => {
      state.getEmployeeByIdLoading = false;
      state.getEmployeeByIdError = null;
      state.getEmployeeByIdSuccess = action.payload;
    },
    getEmployeeByIdFailure: (state, action) => {
      state.getEmployeeByIdLoading = false;
      state.getEmployeeByIdError = action.payload;
    },

    RemoveEmployeesRequest: (state) => {
      state.RemoveEmployeesLoading = true;
      state.RemoveEmployeesError = null;
      state.RemoveEmployeesSuccess = null;
    },
    RemoveEmployeesSuccess: (state, action) => {
      state.RemoveEmployeesLoading = false;
      state.RemoveEmployeesError = null;
      state.RemoveEmployeesSuccess = action.payload;
    },
    RemoveEmployeesFailure: (state, action) => {
      state.RemoveEmployeesLoading = false;
      state.RemoveEmployeesError = action.payload;
    },
  },
});

export const {
  userStatisticRequest,
  userStatisticSuccess,
  userStatisticFailure,
  getEmployeeRequest,
  getEmployeeSuccess,
  getEmployeeFailure,
  getDepartmentsRequest,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getEmployeeByIdRequest,
  getEmployeeByIdSuccess,
  getEmployeeByIdFailure,
  RemoveEmployeesRequest,
  RemoveEmployeesSuccess,
  RemoveEmployeesFailure,
} = userPageSlice.actions;

export const selectUserPage = (state: RootState) => state.userPage;

export default userPageSlice.reducer;

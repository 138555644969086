import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';
import SVG from '../../SVG';
import LoadingIconButton from '../../../icons/LoadingIconButton';

interface propsType {
  bg?: string;
  cl?: string;
  border?: string;
  width?: string;
}

const SubmitButtonContainer = styled.button<propsType>`
  color: ${({ theme, cl }) => (cl ? cl : theme.colors.white)};
  background-color: ${({ theme, bg }) => (bg ? bg : theme.colors.black)};
  border: ${({ border }) => (border ? border : `none`)};
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  width: ${({ width }) => (width ? width : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${mobile(`
        padding: 0.5rem 0;
    `)}
`;

interface Props {
  label: string;
  type?: 'button' | 'submit';
  loading?: boolean;
  onClick?: () => void;
  bg?: string;
  cl?: string;
  border?: string;
  width?: string;
}

const SubmitButton: React.FC<Props> = ({
  label,
  type,
  loading,
  onClick,
  bg,
  cl,
  border,
  width,
}) => {
  return (
    <SubmitButtonContainer
      type={type}
      disabled={loading}
      onClick={loading ? () => {} : onClick}
      bg={bg}
      cl={cl}
      border={border}
      width={width}
    >
      {loading ? (
        <SVG
          icon={<LoadingIconButton />}
          width={'20px'}
          height={'20x'}
          color={cl ? cl : 'white'}
          stockColor={cl ? cl : 'white'}
        />
      ) : (
        label
      )}
    </SubmitButtonContainer>
  );
};

export default SubmitButton;

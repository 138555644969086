import { useEffect } from 'react';

const useBodyOverflow = (isLoading: boolean) => {
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);
};

export default useBodyOverflow;

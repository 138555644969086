import logo from '../../../assets/donalebLogo.png';
import { navbarMapping } from '../../../types/mapping/navbar.object';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { NavBarContainer, StyledAvatarLink, StyledLink } from './style';
import Avatar from '../DefaultAvatar';

interface Props {
  active: string;
}
const Navbar: React.FC<Props> = ({ active }) => {
  const { isMobile, isTablet } = useBreakpoint();
  const { companySuccess } = useSelector((state: RootState) => state.user);

  return (
    <NavBarContainer>
      <div className="upper-part-container">
        <div className="logo-container">
          <img src={logo} alt="Donaleb" />
          <span>DonaLeb</span>
        </div>
        {isMobile === false && isTablet === false && (
          <div className="button-section">
            {Object.values(navbarMapping).map((item, index) => (
              <StyledLink
                key={index}
                to={item.path}
                className={active === item.name ? 'active' : ''}
              >
                {item.name}
              </StyledLink>
            ))}
          </div>
        )}
        <div className="settings-wrapper">
          <div className="settings-container">
            <StyledAvatarLink to="/settings">
              <span>{companySuccess?.companyprofile.username}</span>
            </StyledAvatarLink>
          </div>
          <StyledAvatarLink
            to="/settings"
            className={active === 'Settings' ? 'active' : ''}
          >
            <div className="avatar-container">
              {companySuccess?.companyprofile.image ? (
                <img src={companySuccess?.companyprofile.image} alt="User" />
              ) : (
                <Avatar
                  firstName={companySuccess?.companyprofile.username ?? 'U'}
                />
              )}
            </div>
          </StyledAvatarLink>
        </div>
      </div>
      {isTablet === true && (
        <div className="mobile-button-section">
          {Object.values(navbarMapping).map((item, index) => (
            <StyledLink
              key={index}
              to={item.path}
              className={active === item.name ? 'active' : ''}
            >
              {item.name}
            </StyledLink>
          ))}
        </div>
      )}
      {isMobile === true && (
        <div className="mobile-button-section">
          {Object.values(navbarMapping).map((item, index) => (
            <StyledLink
              key={index}
              to={item.path}
              className={active === item.name ? 'active' : ''}
            >
              {item.name}
            </StyledLink>
          ))}
        </div>
      )}
    </NavBarContainer>
  );
};

export default Navbar;

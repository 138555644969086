import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const ItemContainer = styled(Link)`
  position: relative;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  .left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 90%;
    padding: 1px 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: 1rem;
  }
`;

interface props {
  index: number;
  avatar: string;
  steps: number;
  name: string;
  id: number;
}

const Item: React.FC<props> = ({ index, avatar, steps, name, id }) => {
  return (
    <ItemContainer to={`/user/${id}`}>
      <div className="left-side">
        <span>{index}.</span>
        <img src={avatar} alt="user" />
        <span>{name}</span>
      </div>
      <div className="right-side">
        <span>{steps}</span>
      </div>
    </ItemContainer>
  );
};

export default Item;

import styled from '@emotion/styled';

interface propsType {
  width: string;
  height: string;
}

const FilterInputContainer = styled.input<propsType>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 0 2rem 0 1rem;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSizes.s};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

interface props {
  width: string;
  height: string;
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const FilterInput: React.FC<props> = ({
  width,
  height,
  placeholder,
  value,
  setValue,
}) => {
  return (
    <FilterInputContainer
      width={width}
      height={height}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default FilterInput;

import React from 'react';
import Layout from '../../common/wrappers/Layout';
import Navbar from '../../common/navbar';
import Header from './Header';
import Line from '../../common/line';
import Body from './Body';
import LoadingScreen from '../../common/loading';
import useBodyOverflow from '../../hooks/useBodyOverFlow';
import useSettingsData from '../../../components/hooks/useSettingData';

const SettingsPage: React.FC = () => {
  const {
    companyLoading,
    userName,
    password,
    setUserName,
    setPassword,
    email,
    setEmail,
    phone,
    setPhone,
    image,
    existImage,
    editUserLoading,
    setIsSubmitting,
    emailChangeStatus,
    setEmailChangeStatus,
    handleImageChange,
    handleSubmit,
  } = useSettingsData();

  useBodyOverflow(companyLoading);

  return (
    <Layout>
      {companyLoading && <LoadingScreen />}
      <Navbar active="Settings" />
      <Header existImage={existImage} userName={userName} />
      <Line />
      <Body
        userName={userName}
        setUserName={setUserName}
        password={password}
        setPassword={setPassword}
        email={email}
        setEmail={setEmail}
        setPhone={setPhone}
        phone={phone}
        image={image}
        handleImageChange={handleImageChange}
        existImage={existImage}
        handleSubmit={handleSubmit}
        emailChangeStatus={emailChangeStatus}
        setEmailChangeStatus={setEmailChangeStatus}
        setIsSubmitting={setIsSubmitting}
        editUserLoading={editUserLoading}
      />
    </Layout>
  );
};

export default SettingsPage;

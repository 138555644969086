import styled from '@emotion/styled';
import Card from '../Card';
import SVG from '../../../../common/SVG';
import TargetIcon from '../../../../icons/TargetIcon';
import LeaderBoardIcon from '../../../../icons/LeaderBoardIcon';
import StreaksIcon from '../../../../icons/StreaksIcon';
import { useTheme } from '@emotion/react';
import { mobile } from '../../../../../styles/media';

const CardsContainerWrapper = styled.div`
  padding: 2rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  width: 90%;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  background-color: ${({ theme }) => theme.colors.secondary};
  ${mobile(`width: 100%;  padding: 1rem;`)}

  h2 {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.l};
  }
  .thick-line {
    width: 100%;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.text};
    margin-top: -0.8rem;
  }
  .cards-container {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
  }
`;

interface Props {
  setChallengeStatusType: React.Dispatch<React.SetStateAction<string>>;
  challengeStatusType: string;
}

const CardsContainer: React.FC<Props> = ({
  setChallengeStatusType,
  challengeStatusType,
}) => {
  const theme = useTheme();
  return (
    <CardsContainerWrapper>
      <h2>Basic Individual Modes</h2>
      <div className="thick-line" />
      <div className="cards-container">
        <Card
          icon={
            <SVG
              icon={<StreaksIcon />}
              width="50px"
              height="50px"
              stockColor={theme.colors.text}
            />
          }
          title="Streaks"
          description="Streak is a challenge mode that encourage healthy habits. Users try to meet a step, minute, or distance goal every day for a specified period of time. Users should try to meet as many goal in a row as possible."
          example="Everyone in the group has a goal to reach 30 active minutes per dat during a 14 day challenge."
          categories={['individual Motivation', 'Competition']}
          active={challengeStatusType === 'Streaks'}
          onClick={() => {
            setChallengeStatusType('Streaks');
          }}
        />
        <Card
          icon={<SVG icon={<TargetIcon />} width="50px" height="50px" />}
          title="Target"
          description="Streak is a challenge mode that encourage healthy habits. Users try to meet a step, minute, or distance goal every day for a specified period of time. Users should try to meet as many goal in a row as possible."
          example="Everyone in the group has a goal to reach 30 active minutes per dat during a 14 day challenge."
          categories={['individual Motivation', 'Competition']}
          active={challengeStatusType === 'Target'}
          onClick={() => {
            setChallengeStatusType('Target');
          }}
        />
      </div>
    </CardsContainerWrapper>
  );
};

export default CardsContainer;

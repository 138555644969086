import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import SVG from '../../../common/SVG';
import RightArrowIcon from '../../../icons/RightArrowIcon';
import { useTheme } from '@emotion/react';
import UserCard from './UserCard';
import PersonIcon from '../../../icons/PersonIcon';
import { mobile } from '../../../../styles/media';
import AddGroupIcon from '../../../icons/AddGroupIcon';
import RefreshIcon from '../../../icons/RefreshIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

const UsersWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 1rem 1rem 1rem;
    ${mobile(` padding: 2rem 0rem 1rem 0rem;`)}
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.xl};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.text};
      text-transform: uppercase;
      white-space: nowrap;
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.m}`)}
    }
    a {
      font-size: ${({ theme }) => theme.fontSizes.s};
      color: ${({ theme }) => theme.colors.text};
      font-weight: ${({ theme }) => theme.fontWeights.light};
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
      white-space: nowrap;
      ${mobile(`
                gap: 0.3rem;
            `)}
      span {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      }
    }
  }
  .cards-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    gap: 1rem;
    ${mobile(`
          flex-direction: column;
        `)}
  }
`;

const FirstCard = () => {
  return (
    <>
      <span>Your user Limit:</span>
      <b>10 users</b>
    </>
  );
};

const SecondCard = () => {
  return (
    <>
      <span>Find out which users</span>
      <span>are not in a challenge</span>
    </>
  );
};

const ThirdCard = () => {
  return <span>See who isn't syncing</span>;
};

const UsersContainer = () => {
  const { userStatisticSuccess, userStatisticLoading } = useSelector(
    (state: RootState) => state.home,
  );
  const theme = useTheme();

  return (
    <UsersWrapper>
      <div className="header">
        <h1>USERS OVERVIEW</h1>
        <Link to="/users">
          <span>View All Users </span>
          <SVG
            width="15px"
            height="15px"
            icon={<RightArrowIcon />}
            color={theme.colors.text}
            cursor="pointer"
            mobileWidth="10px"
            mobileHeight="10px"
          />
        </Link>
      </div>
      <div className="cards-container">
        <UserCard
          color={theme.colors.primary}
          icon={<PersonIcon color={theme.colors.primary} />}
          centerNumber={`${userStatisticLoading ? '...' : userStatisticSuccess && userStatisticSuccess.joinedEmployeeCount}`}
          centerText="Total Users"
          bottomText={<FirstCard />}
          buttonLabel="Edit user limit"
        />
        <UserCard
          color={theme.colors.pink}
          icon={<AddGroupIcon color={theme.colors.pink} />}
          centerNumber={`${userStatisticLoading ? '...' : userStatisticSuccess && (userStatisticSuccess.activeChallengeMembersCount === null ? 0 : userStatisticSuccess.activeChallengeMembersCount) + '%'}`}
          centerText="Users Competing Now"
          bottomText={<SecondCard />}
          buttonLabel="Run user report"
        />
        <UserCard
          color={theme.colors.primary}
          icon={<RefreshIcon color={theme.colors.primary} />}
          centerNumber={`${userStatisticLoading ? '...' : userStatisticSuccess && (userStatisticSuccess.employeesWithDailyStepsCount === null ? 0 : userStatisticSuccess.employeesWithDailyStepsCount) + '%'}`}
          centerText="Recent Syncs (Last 3 Days)"
          bottomText={<ThirdCard />}
          buttonLabel="Run user report"
        />
      </div>
    </UsersWrapper>
  );
};

export default UsersContainer;

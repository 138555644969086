import { useState } from 'react';
import SVG from '../../../../common/SVG';
import AccountIcon from '../../../../icons/AccountIcon';
import { useTheme } from '@emotion/react';
import RewardIcon from '../../../../icons/RewardIcon';
import ReportCard from '../ReportCard';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import FilterIcon from '../../../../icons/FilterIcon';
import { BodyWrapper } from './style';

const RunReportBody = () => {
  const [active, setActive] = useState(false);
  const [activeOption, setActiveOption] = useState(1);
  const theme = useTheme();
  const { isMobile } = useBreakpoint();

  const changePage = (pageNumber: number) => {
    setActiveOption(pageNumber);
  };

  return (
    <BodyWrapper active={active}>
      {isMobile && (
        <div
          style={{
            display: 'flex',
            alignItems: 's',
            justifyContent: 'center',
            padding: '1rem',
          }}
          onClick={() => setActive(true)}
        >
          <SVG
            width="30px"
            height="30px"
            icon={<FilterIcon />}
            color={activeOption === 1 ? theme.colors.white : theme.colors.text}
            cursor="pointer"
            mobileWidth="18px"
            mobileHeight="18px"
            hoverColor={theme.colors.white}
          />
          {/* <span>Filters</span> */}
        </div>
      )}

      <div className="black-background" onClick={() => setActive(false)} />
      <div className={'options-container'}>
        <h3>Categories</h3>

        <div
          className={`option ${activeOption === 1 ? 'active' : ''}`}
          onClick={() => changePage(1)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SVG
              width="22px"
              height="22px"
              icon={<AccountIcon />}
              color={
                activeOption === 1 ? theme.colors.white : theme.colors.text
              }
              cursor="pointer"
              mobileWidth="18px"
              mobileHeight="18px"
              hoverColor={theme.colors.white}
            />
            <span>User Reports</span>
          </div>
        </div>
        <h4>User Report</h4>
        <h4>Most Improved Report</h4>
        <h4>Personal Best Report</h4>
        <h4>Activity Report</h4>
        <div className="line" />
        <div
          className={`option ${activeOption === 2 ? 'active' : ''}`}
          onClick={() => changePage(2)}
        >
          <SVG
            width="22px"
            height="22px"
            icon={<RewardIcon />}
            color={activeOption === 2 ? theme.colors.white : theme.colors.text}
            cursor="pointer"
            mobileWidth="18px"
            mobileHeight="18px"
            hoverColor={theme.colors.white}
          />
          <span>Challenge Reports</span>
        </div>
        <h4>Journey Report</h4>
        <h4>Group Journey Report</h4>
        <h4>Leaderboard Report</h4>
        <h4>Streak Report</h4>
        <div className="line" />
      </div>

      {activeOption === 1 && (
        <div className="report-cards-container">
          {[...Array(3)].map((_, index) => (
            <ReportCard
              key={index}
              title={`User Report ${index + 1}`}
              description={`This is a sample user report description for report ${index + 1}. You can customize it with your own content.`}
            />
          ))}
        </div>
      )}

      {activeOption === 2 && (
        <div className="report-cards-container">
          {[...Array(10)].map((_, index) => (
            <ReportCard
              key={index}
              title={`Challenge Report ${index + 1}`}
              description={`
              This is a sample challenge report description for report ${index + 1}. You can customize it with your own content.`}
            />
          ))}
        </div>
      )}
    </BodyWrapper>
  );
};

export default RunReportBody;

import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import NgoCard from './NgoCard';
import { useTheme } from '@emotion/react';
import NextButton from '../../../common/buttons/NextButton';
import { mobile } from '../../../../styles/media';

const AddModuleDonationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  form {
    width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${mobile(`width: 100%;`)}

    .thick-line {
      width: 100%;
      height: 4px;
      background-color: ${({ theme }) => theme.colors.text};
      margin: 3rem 0;
    }
    .image-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      .image-cover {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        border: 1px solid #ccc;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(
            0,
            0,
            0,
            0.1
          ); /* Semi-transparent black background */
          z-index: 1;
          transition: all 0.3s ease-in-out;
        }
        &:hover::before {
          background-color: rgba(
            0,
            0,
            0,
            0.3
          ); /* Semi-transparent black background */
        }
        .icon {
          font-size: 2rem;
          color: ${({ theme }) => theme.colors.primary};
          z-index: 2; /* Ensure the icon is above the pseudo-element */
        }
        input[type='file'] {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          z-index: 3; /* Ensure the input is clickable */
        }
      }
    }

    .ngo-cards {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      .cards-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }
  .next-button-container {
    width: 900px;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    ${mobile(`width: 100%;`)}
  }
`;

interface props {
  ngoId: string;
  setNgoId: React.Dispatch<React.SetStateAction<string>>;
  setCreateChallengeSteps: any;
  // sponserBy: string
  // setSponserBy: React.Dispatch<React.SetStateAction<string>>
  // sponserImage: File | undefined
  // setSponserImage: React.Dispatch<React.SetStateAction<File | undefined>>
  // sponsoredImageView?: string
  isEdit?: boolean;
}

const AddModuleDonation: React.FC<props> = ({
  ngoId,
  setNgoId,
  setCreateChallengeSteps,
  isEdit,
}) => {
  const [active, setActive] = useState(false);
  const { getNgosSuccess } = useSelector(
    (state: RootState) => state.createChallengePage,
  );
  const theme = useTheme();

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setSponserImage(file); // Set image to File type
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setSponserImage(undefined); // Set image to undefined if no file is selected
  //   }
  // };

  useEffect(() => {
    if (ngoId.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [ngoId]);

  return (
    <AddModuleDonationContainer>
      <form>
        {/* <ChallengeInput
          name="sponsorBy"
          type="text"
          placeholder="Sponsor By *"
          error=""
          value={sponserBy}
          onChange={(e) => setSponserBy(e.target.value)}
        />
        <div className="image-container">
          <h2>Select an Image</h2>
          <div className="image-cover" style={{ backgroundImage: sponserImage ? isEdit ? `url(${URL.createObjectURL(sponserImage)})` : `url(${URL.createObjectURL(sponserImage)})` : 'none' }}>
            {sponserImage ? null : (
              <SVG icon={<CoverPlusIcon />} color={theme.colors.black} width="40px" height="40px" />
            )}
            <input type="file" name="image" id="image" onChange={handleImageChange} />
          </div>
        </div>

        <div className="thick-line" /> */}
        <div className="ngo-cards">
          <h2>Select Ngo: </h2>
          <div className="cards-wrapper">
            {getNgosSuccess?.ngos.rows.map(
              (ngo: {
                id: number;
                name: string;
                user_id: number;
                url: string;
                logo: string;
                location: string;
                description: string;
              }) => {
                return (
                  <NgoCard
                    setNgoId={setNgoId}
                    ngo={ngo}
                    key={ngo.id}
                    isSelected={ngo.user_id.toString() === ngoId}
                  />
                );
              },
            )}
          </div>
        </div>
      </form>
      <br />

      <div className="next-button-container">
        <NextButton
          onClick={() =>
            setCreateChallengeSteps({
              step1: {
                number: 1,
                title: 'Challenge Details',
                active: false,
              },
              step2: {
                number: 2,
                title: 'Select Challenge',
                active: false,
              },
              step3: {
                number: 3,
                title: 'Add Modules',
                active: false,
              },
              step4: {
                number: 4,
                title: 'Review',
                active: true,
              },
            })
          }
          isAllowed={active}
          message="Select Ngo to continue"
        />
      </div>
    </AddModuleDonationContainer>
  );
};

export default AddModuleDonation;

import { useTheme } from '@emotion/react';
import { Chart } from 'react-google-charts';

const StepsBarChart = ({
  data,
}: {
  data: { label: string; percentage: string }[];
}) => {
  const theme = useTheme();

  const filteredData = data.filter((item) => item.percentage !== '0%');

  const chartData = [
    ['Steps', 'Percentage'],
    ...filteredData.map((item) => [item.label, parseFloat(item.percentage)]),
  ];

  const options = {
    title: 'Company Performance',
    chartArea: { width: '50%' },
    hAxis: {
      title: 'Label',
      minValue: 0,
    },
    vAxis: {
      format: 'percent',
      title: 'Percentage',
      minValue: 0,
      maxValue: 1,
      minorGridlines: { count: 1 },
    },
    legend: { position: 'none' },
    colors: [theme.colors.orange],
    bar: { groupWidth: '80%' },
  };

  return (
    <div
      style={{
        width: '100%',
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {filteredData.length > 0 ? (
        <Chart
          chartType="Bar"
          width="100%"
          height="100%"
          data={chartData}
          options={options}
        />
      ) : (
        <div style={{ textAlign: 'center', color: theme.colors.orange }}>
          No data available to display.
        </div>
      )}
    </div>
  );
};

export default StepsBarChart;

import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface UserState {
  loginLoading: boolean;
  loginError: any;
  loginSuccess: any;
  companyLoading: boolean;
  companyError: any;
  companySuccess: null | {
    email: string;
    id: number;
    phone: string;
    companyprofile: {
      id: number;
      image: string;
      user_id: number;
      username: string;
    };
  };
}

const initialState: UserState = {
  loginLoading: false,
  loginError: null,
  loginSuccess: [],
  companySuccess: null,
  companyError: null,
  companyLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loginLoading = true;
      state.loginError = null;
      state.loginSuccess = null;
    },
    loginSuccess: (state, action) => {
      state.loginLoading = false;
      state.loginError = null;
      state.loginSuccess = action.payload;
    },
    loginFailure: (state, action) => {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    companyRequest: (state) => {
      state.companyLoading = true;
      state.companyError = null;
      state.companySuccess = null;
    },
    companySuccess: (state, action) => {
      state.companyLoading = false;
      state.companyError = null;
      state.companySuccess = action.payload;
    },
    companyFailure: (state, action) => {
      state.companyLoading = false;
      state.companyError = action.payload;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  companyRequest,
  companySuccess,
  companyFailure,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;

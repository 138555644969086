import styled from '@emotion/styled';
import React from 'react';
import SVG from '../../../../common/SVG';
import PersonIcon from '../../../../icons/PersonIcon';
import CalenderIcon from '../../../../icons/CalenderIcon';
import { dateFormatter } from '../../../../../utils/dateFormater';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../../../icons/RightArrowIcon';
import { useTheme } from '@emotion/react';

const ChallengeContainer = styled.div`
  width: 95%;
  height: 150px;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  .top {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    h1 {
      color: ${({ theme }) => theme.colors.white};
      font-size: ${({ theme }) => theme.fontSizes.xxl};
    }
    .content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 0.5rem;
      .content-top,
      .content-bottom {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
  .bottom {
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    a {
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.2rem;
      span {
        font-size: ${({ theme }) => theme.fontSizes.s};
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

interface props {
  id: number;
  title: string;
  count: number;
  startDate: string;
  endDate: string;
}

const Challenge: React.FC<props> = ({
  title,
  count,
  startDate,
  endDate,
  id,
}) => {
  return (
    <ChallengeContainer>
      <div className="top">
        <h1>{title}</h1>
        <div className="content">
          <div className="content-top">
            <SVG
              icon={<PersonIcon />}
              color="white"
              width="20px"
              height="20px"
            />
            <span>{count} Participants </span>
          </div>
          <div className="content-bottom">
            <SVG
              icon={<CalenderIcon />}
              color="white"
              width="20px"
              height="20px"
            />
            <span>
              {dateFormatter(startDate)} - {dateFormatter(endDate)}
            </span>
          </div>
        </div>
      </div>
    </ChallengeContainer>
  );
};

export default Challenge;

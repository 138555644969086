import { useAuth } from '../hooks/useAuth';

import { Routes as Router, Route } from 'react-router-dom';
import LoginPage from '../pages/Login';
import HomePage from '../pages/Home';
import SettingsPage from '../pages/Settings';

import Reporting from '../pages/Reporting';
import User from '../pages/User';
import UserProfile from '../pages/UserProfile';
import ChallengePage from '../pages/Challenge';
import ViewChallenge from '../pages/ViewChallenge';
import CreateChallengePage from '../pages/CreateChallengePage';
import { useEffect } from 'react';
import { getCompanyByToken } from '../../store/user/userAction';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import LoadingScreen from '../common/loading';

const AuthenticatedRouter = () => {
  return (
    <Router>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/reporting" element={<Reporting />} />
      <Route path="/users" element={<User />} />
      <Route path="/user/:id" element={<UserProfile />} />
      <Route path="/challenge/:id" element={<ViewChallenge />} />
      <Route path="/create-challenge" element={<CreateChallengePage />} />
      <Route path="/create-challenge/:id" element={<CreateChallengePage />} />
      <Route path="/challenges" element={<ChallengePage />} />
    </Router>
  );
};

const UnauthenticatedRouter = () => {
  return (
    <Router>
      <Route path="/*" element={<LoginPage />} />
    </Router>
  );
};

const Routes = () => {
  const { isLoggedIn, logout } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const { challengeError } = useSelector((state: RootState) => state.home);
  const { companySuccess } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (challengeError === 'invalid signature') {
      logout();
    }
    if (companySuccess !== null) return;
    if (isLoggedIn) dispatch(getCompanyByToken());
  }, [challengeError, companySuccess, dispatch, isLoggedIn, logout]);

  return (
    <>{isLoggedIn ? <AuthenticatedRouter /> : <UnauthenticatedRouter />}</>
  );
};

export default Routes;

import React from 'react';

const MinusIcon = () => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_3)">
        <path
          d="M200 0C310.55 0 400 89.45 400 200C400 310.55 310.55 400 200 400C89.45 400 0 310.55 0 200C0 89.45 89.45 0 200 0ZM200 375.388C296.487 375.388 375 296.475 375 200C375 103.525 296.487 25 200 25C103.513 25 25 103.513 25 200C25 296.487 103.513 375.388 200 375.388ZM186.325 212.5H112.5C105.462 212.5 100 207.025 100 200C100 192.975 105.475 187.5 112.5 187.5H287.5C294.538 187.5 300 192.975 300 200C300 207.025 294.525 212.5 287.5 212.5H186.325Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3">
          <rect width="400" height="400" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MinusIcon;

import styled from '@emotion/styled';
import SVG from '../../../common/SVG';
import FilterIcon from '../../../icons/FilterIcon';
import { useTheme } from '@emotion/react';
import { mobile } from '../../../../styles/media';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';

const FilterHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7rem;
  padding: 2rem 0 0 2rem;
  ${mobile(`gap: 2rem;`)}
  .left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.xxl};
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    .clear-button {
      font-size: ${({ theme }) => theme.fontSizes.s};
      color: ${({ theme }) => theme.colors.primary};
      cursor: pointer;
      text-decoration: underline;
    }
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.xxl};
      color: ${({ theme }) => theme.colors.text};
    }
    span {
      font-size: ${({ theme }) => theme.fontSizes.s};
      color: ${({ theme }) => theme.colors.gray};
    }
  }
`;

interface Props {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
}

const FilterHeader: React.FC<Props> = ({ setSearch, setLocation }) => {
  const { employeesSuccess, employeesLoading, departmentsSuccess } =
    useSelector((state: RootState) => state.userPage);
  const theme = useTheme();
  return (
    <FilterHeaderContainer>
      <div className="left-side">
        <SVG
          icon={<FilterIcon />}
          width="30px"
          height="30px"
          stockColor={theme.colors.text}
        />
        <h1>Filter</h1>
      </div>
      <div className="right-side">
        <div
          className="clear-button"
          onClick={() => {
            setSearch('');
            setLocation('');
          }}
        >
          Clear all
        </div>
        <h1 className="results-count">{employeesSuccess?.length} Results</h1>
      </div>
    </FilterHeaderContainer>
  );
};

export default FilterHeader;

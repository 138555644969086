import styled from '@emotion/styled';
import React from 'react';

const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <LayoutContainer>{children}</LayoutContainer>;
};

export default Layout;

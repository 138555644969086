import styled from '@emotion/styled';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { mobile } from '../../../../styles/media';

const ChallengeTextareaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${mobile(`
        gap: 0.3rem;
    `)}
  .header-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
    .placeholder {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      text-transform: uppercase;
    }
    .character-reminded {
      color: ${({ theme }) => theme.colors.textGray};
      text-transform: uppercase;
      font-size: ${({ theme }) => theme.fontSizes.s};
    }
  }
  textarea {
    width: 100%;
    outline: none;
    border: none;
    color: ${({ theme }) => theme.colors.text};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    padding: 0.5rem 0;
    font-size: ${({ theme }) => theme.fontSizes.l};
    resize: vertical;
  }
`;

interface PropsType {
  name: string;
  type: string;
  placeholder: string;
  characterReminded?: number;
  error: string | undefined;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
}

const ChallengeInput: React.FC<PropsType> = ({
  name,
  value,
  type,
  placeholder,
  error,
  characterReminded,
  onChange,
}) => {
  // const { register } = useFormContext();
  return (
    <ChallengeTextareaContainer>
      <div className="header-input">
        <span className="placeholder">{placeholder}</span>
        {/* <span className="character-reminded">{characterReminded characters remained</span> */}
      </div>
      <textarea name={name} id={name} onChange={onChange} value={value} />
      <span>{error}</span>
    </ChallengeTextareaContainer>
  );
};

export default ChallengeInput;

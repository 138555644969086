import { combineReducers } from '@reduxjs/toolkit';
import UserReducer from './user/userReducer';
import HomeSlice from './home/homeReducer';
import userPageSlice from './userPage/userPageReducer';
import challengePageReducer from './challenge/challengePageReducer';
import challengePageSlice from './createChallenge/createChallengePageReducer';
import reportSlice from './report/reportReducer';
import settingsSlice from './settings/settingsReducer';

export const combineReducer = combineReducers({
  user: UserReducer,
  home: HomeSlice,
  userPage: userPageSlice,
  challengePage: challengePageReducer,
  createChallengePage: challengePageSlice,
  report: reportSlice,
  settings: settingsSlice,
});

import React from 'react';

const StreaksIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M400 241V527.333"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
      <path
        d="M336.333 527.333H463.667"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
      <path
        d="M336.333 304.667H431.667"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
      <path
        d="M400 145.333V50"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
      <path
        d="M400 750V654.667"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
      <path
        d="M654.667 400H750"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
      <path
        d="M50 400H145.333"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
      <path
        d="M750 433.333V400C749.8 317.764 720.677 238.219 667.73 175.295C614.783 112.37 541.388 70.0776 460.397 55.8222C379.406 41.5668 295.981 56.2575 224.731 97.3217C153.481 138.386 98.9476 203.206 70.6806 280.432C42.4136 357.657 42.2144 442.366 70.118 519.723C98.0217 597.081 152.249 662.157 223.305 703.556C294.361 744.955 377.716 760.038 458.774 746.163C539.831 732.289 613.425 690.341 666.667 627.667"
        stroke="#020202"
        strokeWidth="63.6667"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default StreaksIcon;

import styled from '@emotion/styled';
import { mobile } from '../../../../../styles/media';
import React from 'react';
import SVG from '../../../../common/SVG';
import DownloadIcon from '../../../../icons/DownloadIcon';
import { useTheme } from '@emotion/react';

const BodyWrapper = styled.div`
  width: 96%;
  margin: 2rem;
  display: flex;
  padding-bottom: 1rem;
  box-shadow: 0px 0px 25px ${({ theme }) => theme.colors.text};
  justify-content: start;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  background-color: ${({ theme }) => theme.colors.white};
`;

const TileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
`;

const VerticalTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const Description = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 600;
`;
const Date = styled.h4`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 600;
  margin-bottom: 0.4rem;
`;

const Button = styled.div`
  width: 150px;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background-color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  padding: 0.7rem 0;
  border-radius: 0.5rem;
  cursor: pointer;

  ${mobile(`
    padding: 0.5rem 0;
  `)}

  transition: background-color 0.3s;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.link};
  }
`;

const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.iceBlue};
`;

const CompletedReportsBody = () => {
  const theme = useTheme();
  return (
    <BodyWrapper>
      {[...Array(3)].map((_, index) => (
        <React.Fragment key={index}>
          <TileContainer>
            <VerticalTexts>
              <Date>GENERATED AT 10/10/2020</Date>
              <Title>Report Name</Title>
              <Description>Report description</Description>
            </VerticalTexts>
            <VerticalTexts>
              <Description>Created By: Tuker Barnett</Description>
            </VerticalTexts>
            <Button>
              <SVG
                width="22px"
                height="22px"
                icon={<DownloadIcon />}
                color={theme.colors.white}
                cursor="pointer"
                mobileWidth="18px"
                mobileHeight="18px"
              />
              Download
            </Button>
          </TileContainer>
          {index < 2 && <Line />}
        </React.Fragment>
      ))}
    </BodyWrapper>
  );
};

export default CompletedReportsBody;

import styled from '@emotion/styled';
import { mobile, tablet } from '../../../../../styles/media';

interface props {
  active: boolean;
}

export const BodyWrapper = styled.div<props>`
  width: 96%;
  margin: 2rem;
  display: flex;
  padding-bottom: 1rem;
  box-shadow: 0px 0px 25px ${({ theme }) => theme.colors.text};
  justify-content: start;
  align-items: start;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  background-color: ${({ theme }) => theme.colors.white};
  ${mobile(`margin: 1rem;`)}
  .black-background {
    display: none;
  }

  ${({ active }) =>
    active &&
    mobile(`
      .black-background{
        display: block !important;
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-color: rgba(0,0,0,0.5);
      }
  
    `)}

  .options-container {
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.s};
    width: 20%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ active }) =>
      active &&
      mobile(`
        left: 0 !important;
      `)}
    ${mobile(`
        position: fixed;
        border-radius:0;
        width: 40%;
        top: 0;
        left: -90%;
        height: 100%;
        justify-content: flex-start;
      `)}
  }

  .options-container:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    right: 0rem;
    background-color: ${({ theme }) => theme.colors.iceBlue};
  }

  .line {
    height: 2px;
    margin-top: 1rem;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.iceBlue};
  }
  h4 {
    padding: 0.7rem 0.5rem 0.7rem 0.5rem;
    margin-left: 2.5rem;
    font-size: 0.8rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.lightText};
    font-weight: 600;
  }

  h3 {
    padding: 1.3rem;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.s};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
  }
  .option {
    cursor: pointer;
    border-right: 1px solid ${({ theme }) => theme.colors.gray};
    --bg-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.text};
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    background-color: var(--bg-color);
    ${mobile(`
        padding: 15px 10px;
        font-size: 0.7rem;`)}
    ${tablet(`
        padding: 15px 10px;
        font-size: 0.7rem;`)}
        text-decoration: none;
    position: relative;

    span {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -1.55rem;
      width: 0;
      height: 0;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 25px solid var(--bg-color);
      pointer-events: none;
    }

    &:hover,
    &.active {
      z-index: 1;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
      --bg-color: ${({ theme }) => theme.colors.primary};
    }

    &:hover:after,
    &.active:after {
      color: ${({ theme }) => theme.colors.white};
      border-left-color: ${({ theme }) => theme.colors.primary};
    }

    &:hover svg,
    &.active svg {
      fill: ${({ theme }) => theme.colors.white};
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .report-cards-container {
    width: 75%;
    margin-left: 2rem;
    overflow-y: auto;
    height: 90vh;
    ${mobile(`
        width: 100%;
        margin-left: 0;
    }`)}
  }
`;

import styled from '@emotion/styled';
import ChallengeInput from '../../../common/inputs/ChallengeInput';
import ChallengeTextarea from '../../../common/inputs/ChallengeTextarea';
import SVG from '../../../common/SVG';
import CloseIcon from '../../../icons/CloseIcon';
import { useTheme } from '@emotion/react';
import CoverPlusIcon from '../../../icons/CoverPlusIcon';
import NextButton from '../../../common/buttons/NextButton';
import { useEffect, useState } from 'react';
import { mobile } from '../../../../styles/media';

const ChallengeDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xxxxl};
    ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xxl}`)}
  }
  .challenge-type {
    width: 900px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    ${mobile(`width: 100%;`)}
    p {
      font-size: ${({ theme }) => theme.fontSizes.l};
      color: ${({ theme }) => theme.colors.gray};
    }
  }
  .thick-line {
    width: 900px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.text};
    margin-top: 1rem;
    ${mobile(`width: 100%;`)}
  }
  form {
    width: 900px;
    ${mobile(`width: 100%;`)}

    .radio-button-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      h3 {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        span {
          text-transform: uppercase;
        }
      }

    }
    .image-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      .image-cover {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        border: 1px solid #ccc;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(
            0,
            0,
            0,
            0.1
          ); /* Semi-transparent black background */
          z-index: 1;
          transition: all 0.3s ease-in-out;
        }
        &:hover::before {
          background-color: rgba(
            0,
            0,
            0,
            0.3
          ); /* Semi-transparent black background */
        }
        .icon {
          font-size: 2rem;
          color: ${({ theme }) => theme.colors.primary};
          z-index: 2; /* Ensure the icon is above the pseudo-element */
        }
        input[type='file'] {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          z-index: 3; /* Ensure the input is clickable */
        }
      }
    }
  }
  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }
  .next-button-container {
    width: 900px;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    ${mobile(`width: 100%;`)}
  }
  .input-container {
        display: flex;
        gap: 1rem;
        .input-item {
          display: flex;
          align-items: center;
          gap: 0.3rem;
        }
        input {
        }
      }
`;

interface Props {
  setChallengeType: React.Dispatch<React.SetStateAction<string>>;
  challengeType: string;
  title: string;
  description: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  image: File | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  setCreateChallengeSteps: React.Dispatch<React.SetStateAction<any>>;

  //Edit
  isEdit?: boolean;
  challengeImageView?: string;
}

const ChallengeDetails: React.FC<Props> = ({
  setChallengeType,
  challengeType,
  title,
  description,
  setTitle,
  setDescription,
  image,
  setImage,
  setCreateChallengeSteps,

  //Edit
  isEdit = false,

  challengeImageView,
}) => {
  const theme = useTheme();

  const [message, setMessage] = useState('');

  const [active, setActive] = useState(false);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setIsImageChanged(true);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file); // Set image to File type
      };
      reader.readAsDataURL(file);
    } else {
      setImage(undefined); // Set image to undefined if no file is selected
    }
  };

  useEffect(() => {
    if (
      title.length > 0 &&
      description.length > 0 &&
      (image || challengeImageView)
    ) {
      setActive(true);
    } else {
      if (title.length === 0) setMessage('Title cannot be empty');
      else if (description.length === 0)
        setMessage('Description cannot be empty');
      else if (!image && !challengeImageView)
        setMessage('Image cannot be empty');
      setActive(false);
    }
  }, [challengeImageView, description, image, title]);

  return (
    <ChallengeDetailsContainer>
      <h1>Step Your Challenge Details</h1>
      <div className="challenge-type">
        <h3>
          <span>Challenge Type</span>
        </h3>
        <div className="input-container">
          <div className="input-item">
            <input
              type="radio"
              name="challengeType"
              value="gift"
              checked={challengeType === 'gift'}
              onChange={() => setChallengeType('gift')}
              disabled={isEdit}
            />
            <label htmlFor="challenge">Gift</label>
          </div>
          <div className="input-item">
            <input
              type="radio"
              name="challengeType"
              value="donation"
              checked={challengeType === 'donation'}
              onChange={() => setChallengeType('donation')}
              disabled={isEdit}
            />
            <label htmlFor="donation">Donation</label>
          </div>
        </div>
      </div>
      <div className="thick-line" />
      <form>
        <ChallengeInput
          name="title"
          type="text"
          placeholder="Challenge Title *"
          error=""
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <ChallengeTextarea
          name="description"
          type="text"
          placeholder="Challenge Description *"
          value={description}
          error={undefined}
          onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        <div className="image-container">
          <h2>Select an Image</h2>
          <div
            className="image-cover"
            style={{
              backgroundImage: isImageChanged
                ? image && `url(${URL.createObjectURL(image)})`
                : isEdit && challengeImageView
                  ? `url(${challengeImageView})`
                  : 'none',
            }}
          >
            {image ? null : (
              <SVG
                icon={<CoverPlusIcon />}
                color={theme.colors.black}
                width="40px"
                height="40px"
              />
            )}
            <input
              type="file"
              name="image"
              id="image"
              onChange={handleImageChange}
            />
          </div>
        </div>
      </form>
      <br />
      <div className="next-button-container ">
        <NextButton
          onClick={() => {
            setCreateChallengeSteps({
              step1: {
                number: 1,
                title: 'Challenge Details',
                active: false,
              },
              step2: {
                number: 2,
                title: 'Select Challenge',
                active: true,
              },
              step3: {
                number: 3,
                title: 'Add Modules',
                active: false,
              },
              step4: {
                number: 4,
                title: 'Review',
                active: false,
              },
            });
          }}
          isAllowed={active}
          message={message}
        />
      </div>
    </ChallengeDetailsContainer>
  );
};

export default ChallengeDetails;

export const theme = {
  colors: {
    primary: '#4E9DFC',
    link: '#3D7BCC',
    secondary: '#E7EBF1',
    lightPrimary: '#A1CAFB',
    error: '#ff0000',
    success: '#5FBF92',
    warning: '#ffff00',
    info: '#00ffff',
    background: '#000000',
    text: '#435261',
    lightText: '#596879',
    green: '#60BE93',
    pink: '#FD50A5',
    white: '#FFFFFF',
    yellow: '#F6B952',
    black: '#000000',
    orange: '#EC6747',
    gray: '#D0D0D0',
    lightGray: '#DEDFE2',
    lightGrayBackground: '#ECF0F3',
    iceBlue: '#E7EBF1',
    primaryOrange: '#FF8F45',
    textGray: '#A2A5AD',
  },
  fontSizes: {
    xxs: '0.625rem',
    xs: '0.75rem',
    s: '1rem',
    m: '1.2rem',
    l: '1.4rem',
    xl: '1.6rem',
    xxl: '1.8rem',
    xxxl: '2rem',
    xxxxl: '2.2rem',
    xxxxxl: '3rem',
  },
  fontWeights: {
    light: 100,
    normal: 400,
    bold: 700,
    bolder: 900,
  },
  borderRadius: {
    xs: '0.25rem',
    s: '0.5rem',
    m: '1rem',
    l: '2rem',
    xl: '4rem',
  },
};

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import SVG from '../common/SVG';
import EditIcon from '../icons/EditIcon';
import IconButton from '../common/buttons/IconButton';

export const useChallengeTable = () => {
  const theme = useTheme();

  interface BallStatusProps {
    status: string;
  }

  const BallStatus = styled.div<BallStatusProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ theme, status }) => {
      switch (status) {
        case 'active':
          return theme.colors.green;
        case 'expired':
          return theme.colors.error;
        case 'upcoming':
          return theme.colors.green;
        default:
          return theme.colors.primary;
      }
    }};
  `;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      headerClassName: 'super-app-theme--header description-container',
      renderCell: (params) => <div className="description">{params.value}</div>,
    },
    {
      field: 'types',
      headerName: 'Type',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'image',
      headerName: 'Image',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (record) => (
        <div className="image-container">
          <img src={record.value} alt="User" />
        </div>
      ),
    },

    {
      field: 'timeend',
      headerName: 'Status',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (record) => {
        const now = new Date();
        const timeend = new Date(record.value);
        let status = '';

        if (timeend > now) {
          status = 'upcoming';
        } else if (timeend < now) {
          status = 'expired';
        } else {
          status = 'active';
        }

        return (
          <div className="start-content">
            <BallStatus status={status} />
          </div>
        );
      },
    },
    //edit button url
    {
      field: 'view',
      headerName: 'Option',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (record) => (
        <div className="view-container">
          <Link
            to={`/create-challenge/${record.row.id}`}
            className="link-center-content"
          >
            <IconButton
              icon={
                <SVG
                  icon={<EditIcon />}
                  color={theme.colors.white}
                  width="20px"
                  height="20px"
                  cursor="pointer"
                />
              }
              text={'Edit'}
              border={''}
            />
          </Link>
        </div>
      ),
    },
  ];

  return { columns };
};

import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getCompanyByToken } from '../../store/user/userAction';
import { editCompany } from '../../store/settings/settingsAction';

const useSettingsPage = () => {
  const dispatch = useDispatch<AppDispatch>();

  // State variables
  const { companySuccess, companyLoading } = useSelector(
    (state: RootState) => state.user,
  );
  const { editUserSuccess, editUserLoading } = useSelector(
    (state: RootState) => state.settings,
  );
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState<File | undefined>();
  const [existImage, setExistImage] = useState('');
  const [id, setId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailChangeStatus, setEmailChangeStatus] = useState(false);

  // Handle image change
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    if (!validateForm()) return;

    const companyData: any = {
      id,
      phone,
      username: userName,
      image,
      ...(email !== companySuccess?.email && emailChangeStatus && { email }),
      ...(password.length > 0 && { password }),
    };

    dispatch(editCompany(companyData));
    setIsSubmitting(true);
  };

  // Validate form fields
  const validateForm = () => {
    if (!email.length) {
      showError('Email cannot be empty');
      return false;
    }
    if (!phone.length) {
      showError('Phone cannot be empty');
      return false;
    }
    if (!userName.length) {
      showError('Username cannot be empty');
      return false;
    }
    if (password.length > 0 && password.length <= 8) {
      showError('Password must be at least 8 characters long');
      return false;
    }
    if (email === companySuccess?.email && emailChangeStatus) {
      showError('Email already exists');
      return false;
    }
    return true;
  };

  // Show error toast
  const showError = (message: string) => {
    toast.error(message);
  };

  // Effects
  useEffect(() => {
    if (editUserSuccess && isSubmitting) {
      toast.success(editUserSuccess.message);
      dispatch(getCompanyByToken());
    }
  }, [editUserSuccess, isSubmitting, dispatch]);

  useEffect(() => {
    setFormDataFromSuccess(companySuccess);
  }, [companySuccess]);

  // Set form data from success response
  const setFormDataFromSuccess = (data: any) => {
    setUserName(data?.companyprofile.username || '');
    setEmail(data?.email || '');
    setPhone(data?.phone || '');
    setExistImage(data?.companyprofile.image || '');
    setId(data?.companyprofile?.user_id || '');
  };

  return {
    companyLoading,
    companySuccess,
    userName,
    password,
    setUserName,
    setPassword,
    email,
    setEmail,
    phone,
    setPhone,
    image,
    setImage,
    existImage,
    setExistImage,
    id,
    setId,
    editUserSuccess,
    editUserLoading,
    isSubmitting,
    setIsSubmitting,
    emailChangeStatus,
    setEmailChangeStatus,
    handleImageChange,
    handleSubmit,
  };
};

export default useSettingsPage;

import { Global, css, useTheme } from '@emotion/react';

import CalibreRegularOTF from '../assets/fonts/CalibreRegular.otf';

const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'CalibreRegularOTF';
          src: url(${CalibreRegularOTF}) format('opentype');
        }

        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }

        body {
          font-family: Arial, Helvetica, sans-serif; /* Use the custom font */
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        span {
          color: ${theme.colors.text};
        }

        a {
          text-decoration: none;
        }
      `}
    />
  );
};

export default GlobalStyles;

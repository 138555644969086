import React from 'react';

const CloseIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.1 609.767L352.867 400L143.1 190.233C137.028 183.947 133.668 175.527 133.744 166.787C133.82 158.047 137.326 149.686 143.506 143.506C149.686 137.326 158.047 133.82 166.787 133.744C175.527 133.668 183.947 137.028 190.233 143.1L400 352.867L609.767 143.1C612.842 139.916 616.52 137.377 620.587 135.63C624.653 133.883 629.027 132.963 633.453 132.925C637.879 132.887 642.269 133.73 646.365 135.406C650.462 137.082 654.183 139.557 657.313 142.687C660.443 145.817 662.918 149.538 664.594 153.635C666.27 157.731 667.113 162.121 667.075 166.547C667.037 170.973 666.117 175.347 664.37 179.413C662.623 183.48 660.084 187.158 656.9 190.233L447.133 400L656.9 609.767C660.084 612.842 662.623 616.52 664.37 620.587C666.117 624.653 667.037 629.027 667.075 633.453C667.113 637.879 666.27 642.269 664.594 646.365C662.918 650.462 660.443 654.183 657.313 657.313C654.183 660.443 650.462 662.918 646.365 664.594C642.269 666.27 637.879 667.114 633.453 667.075C629.027 667.037 624.653 666.117 620.587 664.37C616.52 662.623 612.842 660.084 609.767 656.9L400 447.133L190.233 656.9C183.947 662.972 175.527 666.332 166.787 666.256C158.047 666.18 149.686 662.674 143.506 656.494C137.326 650.314 133.82 641.953 133.744 633.213C133.668 624.473 137.028 616.053 143.1 609.767Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseIcon;

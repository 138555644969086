import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  RemoveEmployeesFailure,
  RemoveEmployeesRequest,
  RemoveEmployeesSuccess,
  getDepartmentsFailure,
  getDepartmentsRequest,
  getDepartmentsSuccess,
  getEmployeeByIdFailure,
  getEmployeeByIdRequest,
  getEmployeeByIdSuccess,
  getEmployeeFailure,
  getEmployeeRequest,
  getEmployeeSuccess,
  userStatisticFailure,
  userStatisticRequest,
  userStatisticSuccess,
} from './userPageReducer';
import { GridRowId } from '@mui/x-data-grid';

export const userStatic = createAsyncThunk(
  'company/getStatistics/1',
  async (data, { dispatch }) => {
    try {
      dispatch(userStatisticRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/company/getStatistics/${localStorage.getItem('id')}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      if (response && response.data && response.data) {
        dispatch(userStatisticSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(userStatisticFailure(err?.response?.data?.message));
      } else {
        dispatch(userStatisticFailure((err as Error).message));
      }
    }
  },
);
export const getEmployees = createAsyncThunk(
  'company/getEmployees/1',
  async (
    data: {
      department_id: number;
      location: string;
      username: string;
    },
    { dispatch },
  ) => {
    try {
      dispatch(getEmployeeRequest());
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/company/getEmployees/${localStorage.getItem('id')}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data && response.data.Employees) {
        dispatch(getEmployeeSuccess(response.data.Employees));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(getEmployeeFailure(err?.response?.data?.message));
      } else {
        dispatch(getEmployeeFailure((err as Error).message));
      }
    }
  },
);

export const getDepartments = createAsyncThunk(
  'company/getDepartments/13',
  async (data, { dispatch }) => {
    try {
      dispatch(getDepartmentsRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/company/getDepartments/${localStorage.getItem('id')}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data) {
        dispatch(getDepartmentsSuccess(response.data.newDepartment));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(getDepartmentsFailure(err?.response?.data?.message));
      } else {
        dispatch(getDepartmentsFailure((err as Error).message));
      }
    }
  },
);

export const getEmployeeById = createAsyncThunk(
  'company/getDepartments/13',
  async (data: { id: number }, { dispatch }) => {
    try {
      dispatch(getEmployeeByIdRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/company/getEmployee/${data.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data) {
        dispatch(getEmployeeByIdSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(getEmployeeByIdFailure(err?.response?.data?.message));
      } else {
        dispatch(getEmployeeByIdFailure((err as Error).message));
      }
    }
  },
);

export const removeEmployees = createAsyncThunk(
  'company/removeEmployee/13',
  async (data: { ids: GridRowId[] }, { dispatch }) => {
    try {
      dispatch(RemoveEmployeesRequest());
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/company/removeEmployee/${localStorage.getItem('id')}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data) {
        dispatch(RemoveEmployeesSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(RemoveEmployeesFailure(err?.response?.data?.message));
      } else {
        dispatch(RemoveEmployeesFailure((err as Error).message));
      }
    }
  },
);

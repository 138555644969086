import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface HomeState {
  userStatisticLoading: boolean;
  userStatisticError: any;
  userStatisticSuccess: {
    joinedEmployeeCount?: number;
    activeChallengeMembersCount?: number;
    employeesWithDailyStepsCount?: number;
    statusCode?: number;
    success?: boolean;
  };
  stepsRangeLoading: boolean;
  stepsRangeError: any;
  stepsRangeSuccess: {
    stepsSum: number;
  } | null;
  challengeLoading: boolean;
  challengeError: any;
  challengeSuccess: null | {
    challenges: {
      id: number;
      title: string;
      description: string;
      challengesMembers: {
        id: number;
        user_id: number;
        challenges_id: number;
        finished_steps: number;
        createdAt: string;
        updatedAt: string;
      }[];
      timestart: string;
      timeend: string;
    }[];
  };
  stepsGraphDaysLoading: boolean;
  stepsGraphDaysError: any;
  stepsGraphDaysSuccess: null | {
    stepsByDate: {
      steps: any;
      date: string;
      stepsSum: string;
    }[];
  };
  leaderBoardUsersLoading: boolean;
  leaderBoardUsersError: any;
  leaderBoardUsersSuccess: null | {
    users: {
      id: number;
      username: string;
      avatar_url: string;
      steps: number;
    }[];
  };
  UsersDailyLoading: boolean;
  UsersDailySuccess: {
    data: {
      label: string;
      percentage: string;
      count: number;
    }[];
  } | null;
  UsersDailyError: any;
}

const initialState: HomeState = {
  userStatisticLoading: false,
  userStatisticError: null,
  userStatisticSuccess: {},
  stepsRangeLoading: false,
  stepsRangeError: null,
  stepsRangeSuccess: null,
  challengeLoading: false,
  challengeError: null,
  challengeSuccess: null,
  stepsGraphDaysLoading: false,
  stepsGraphDaysError: null,
  stepsGraphDaysSuccess: null,
  leaderBoardUsersLoading: false,
  leaderBoardUsersError: null,
  leaderBoardUsersSuccess: null,
  UsersDailyLoading: false,
  UsersDailySuccess: null,
  UsersDailyError: null,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    userStatisticRequest: (state) => {
      state.userStatisticLoading = true;
      state.userStatisticError = null;
      state.userStatisticSuccess = {};
    },
    userStatisticSuccess: (state, action) => {
      state.userStatisticLoading = false;
      state.userStatisticError = null;
      state.userStatisticSuccess = action.payload;
    },
    userStatisticFailure: (state, action) => {
      state.userStatisticLoading = false;
      state.userStatisticError = action.payload;
    },
    stepsRangeRequest: (state) => {
      state.stepsRangeLoading = true;
      state.stepsRangeSuccess = null;
      state.stepsRangeError = {};
    },
    stepsRangeSuccess: (state, action) => {
      state.stepsRangeLoading = false;
      state.stepsRangeError = null;
      state.stepsRangeSuccess = action.payload;
    },
    stepsRangeFailure: (state, action) => {
      state.stepsRangeLoading = false;
      state.stepsRangeError = action.payload;
    },
    challengeRequest: (state) => {
      state.challengeLoading = true;
      state.challengeError = null;
      state.challengeSuccess = null;
    },
    challengeSuccess: (state, action) => {
      state.challengeLoading = false;
      state.challengeError = null;
      state.challengeSuccess = action.payload;
    },
    challengeFailure: (state, action) => {
      state.challengeLoading = false;
      state.challengeError = action.payload;
    },
    stepsGraphStepsRequest: (state) => {
      state.stepsGraphDaysLoading = true;
      state.stepsGraphDaysError = null;
    },
    stepsGraphStepsSuccess: (state, action) => {
      state.stepsGraphDaysLoading = false;
      state.stepsGraphDaysError = null;
      state.stepsGraphDaysSuccess = action.payload;
    },
    stepsGraphStepsFailure: (state, action) => {
      state.stepsGraphDaysLoading = false;
      state.stepsGraphDaysError = action.payload;
    },
    leaderBoardUsersRequest: (state) => {
      state.leaderBoardUsersLoading = true;
      state.leaderBoardUsersError = null;
    },
    leaderBoardUsersSuccess: (state, action) => {
      state.leaderBoardUsersLoading = false;
      state.leaderBoardUsersError = null;
      state.leaderBoardUsersSuccess = action.payload;
    },
    leaderBoardUsersFailure: (state, action) => {
      state.leaderBoardUsersLoading = false;
      state.leaderBoardUsersError = action.payload;
    },
    UsersDailyRequest: (state) => {
      state.UsersDailyLoading = true;
      state.UsersDailyError = null;
    },
    UsersDailySuccess: (state, action) => {
      state.UsersDailyLoading = false;
      state.UsersDailyError = null;
      state.UsersDailySuccess = action.payload;
    },
    UsersDailyFailure: (state, action) => {
      state.UsersDailyLoading = false;
      state.UsersDailyError = action.payload;
    },
  },
});

export const {
  userStatisticRequest,
  userStatisticSuccess,
  userStatisticFailure,
  stepsRangeRequest,
  stepsRangeSuccess,
  stepsRangeFailure,
  challengeRequest,
  challengeSuccess,
  challengeFailure,
  stepsGraphStepsRequest,
  stepsGraphStepsSuccess,
  stepsGraphStepsFailure,
  leaderBoardUsersRequest,
  leaderBoardUsersSuccess,
  leaderBoardUsersFailure,
  UsersDailyRequest,
  UsersDailySuccess,
  UsersDailyFailure,
} = homeSlice.actions;

export const selectHome = (state: RootState) => state.home;

export default homeSlice.reducer;

import styled from '@emotion/styled';
import { mobile, tablet } from '../../../../styles/media';

export const BodyWrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  ${tablet(`
  // height: 180px;
  `)}
  ${mobile(`
  // height: 150px;
  flex-direction: column;
  `)}
.options-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.iceBlue};
    ${mobile(`
    width: 100%;
  `)}
    span {
      color: ${({ theme }) => theme.colors.text};
      border-bottom: 2px solid ${({ theme }) => theme.colors.gray};
      display: inline-block;
      padding: 25px 15px;
      font-size: 1rem;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      background-color: ${({ theme }) => theme.colors.iceBlue};
      ${mobile(`
    padding: 15px 10px;
    font-size: 0.7rem;`)}
      ${tablet(`
    padding: 15px 10px;
    font-size: 0.7rem;`)}
    text-decoration: none;
      transition: background-color 0.3s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
    span.active {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
  .form-container {
    width: 75%;
    display: flex;
    padding: 1rem 1rem 1rem 2rem;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    ${mobile(`
    width: 100%;
  `)}
    .image-input-container {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: start;
      ${mobile(`flex-direction: column;`)}
      img {
        width: 200px;
        height: 200px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        border-radius: 1rem;
        transform: translateY(-2rem);
        ${mobile(`transform: translateY(0.3rem);`)}
      }
    }
    h3 {
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xl}`)}
      color:  ${({ theme }) => theme.colors.text};
    }
    p {
      font-size: ${({ theme }) => theme.fontSizes.s};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
      color: ${({ theme }) => theme.colors.gray};
      margin-bottom: 0.5rem;
    }
    .link-container {
      width: 90%;
      margin-top: 0.5rem;
      border-radius: ${({ theme }) => theme.borderRadius.m};
      padding: 1.2rem 1rem 1.2rem 1rem;
      ${mobile(`
    padding: 0.7rem 0.5rem 0.7rem 0.5rem;

  `)}
      background-color: ${({ theme }) => theme.colors.lightGray};
      a {
        color: ${({ theme }) => theme.colors.text};
        ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
      }
    }

    .form-section-title {
      padding-top: 3rem;
      ${mobile(`
          padding-top:2rem;

  `)}

      font-size: ${({ theme }) => theme.fontSizes.s};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeights.bold};

      color: ${({ theme }) => theme.colors.text};
      margin-bottom: 0.5rem;
    }

    .form-name-field {
      width: 90%;
      padding: 0.5rem;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      height: 3rem;
      ${mobile(`
          height:2rem;
          width:100%;
  `)}
      border-radius: ${({ theme }) => theme.borderRadius.s};
      font-size: ${({ theme }) => theme.fontSizes.s};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
      color: ${({ theme }) => theme.colors.text};
      &:focus {
        border-color: ${({ theme }) => theme.colors.link};
        outline: none;
      }
    }
    .submit-button {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
    }
    .code-wrapper {
      width: 30rem;
      display: flex;
      flex-direction: row;
      justify-items: start;
      align-items: center;
      ${mobile(`
    flex-direction: column;
    gap: 0.5rem;
    align-items:start;
  `)}
      input {
        width: 40%;
        padding: 0.5rem;
        border: 1px solid ${({ theme }) => theme.colors.gray};
        height: 3rem;
        ${mobile(`
          height:2rem;

  `)}
        border-radius: ${({ theme }) => theme.borderRadius.s};
        font-size: ${({ theme }) => theme.fontSizes.s};
        ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
        color: ${({ theme }) => theme.colors.text};
        &:focus {
          border-color: ${({ theme }) => theme.colors.link};
          outline: none;
        }
      }
      .code-side-paragraph {
        width: 60%;
        text-transform: uppercase;
        margin-left: 0.5rem;
        ${mobile(`
      margin-left:0rem;
  `)}
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: ${({ theme }) => theme.fontSizes.xs};
        ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xxs}`)}
        color: ${({ theme }) => theme.colors.gray};
      }
    }
  }
`;

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  editUserFailure,
  editUserRequest,
  editUserSuccess,
} from './settingsReducer';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

interface EditCompanyData {
  id: number;
  email?: string;
  phone?: string;
  username?: string;
  image: File | undefined;
  password?: string;
}

export const editCompany = createAsyncThunk(
  'company/edit',
  async (data: EditCompanyData, { dispatch }) => {
    try {
      dispatch(editUserRequest());

      const formData = new FormData();
      if (data.email) formData.append('email', data.email);
      if (data.phone) formData.append('phone', data.phone);
      if (data.username) formData.append('username', data.username);
      if (data.image) formData.append('image', data.image);
      if (data.password) formData.append('password', data.password);

      const response = await axios.put(
        `${process.env.REACT_APP_URL}/company/edit/${data.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response && response.data) {
        dispatch(editUserSuccess(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        dispatch(
          editUserFailure(
            capitalizeFirstLetter(err.response?.data?.message) ||
              'An error occurred while editing the company.',
          ),
        );
        toast.error(
          capitalizeFirstLetter(err.response?.data?.message) ||
            'An error occurred.',
        );
      } else {
        dispatch(editUserFailure((err as Error).message));
        toast.error((err as Error).message);
      }
    }
  },
);

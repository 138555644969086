import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { mobile, tablet } from '../../../../styles/media';

const HeaderContainer = styled.div`
  width: 100%;
  height: 170px;
  display: flex;
  box-shadow: 0px 0px 25px ${({ theme }) => theme.colors.text};
  flex-direction: column;
  ${mobile(`  height: 200px;`)}
  h3 {
    font-size: ${({ theme }) => theme.fontSizes.xxxxxl};
    ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xl}`)}
    color:  ${({ theme }) => theme.colors.text};
  }

  .upper-header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid lightgray;
    button {
      text-transform: uppercase;
      font-size: ${({ theme }) => theme.fontSizes.xs};
      color: ${({ theme }) => theme.colors.gray};
      font-weight: 600;
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
      text-decoration: none;
      padding: 1rem;
      border: none;
      background: none;
      cursor: pointer;
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.black};
      }
    }
    .active {
      color: ${({ theme }) => theme.colors.black};
      border-bottom: 5px solid black;
    }
  }
  .lower-header {
    margin: 2.5rem;
    flex-direction: column;
    display: flex;
    align-items: start;
    h4 {
      font-size: ${({ theme }) => theme.fontSizes.m};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.s}`)}
      margin-bottom: 0.5rem;
    }
    span {
      color: ${({ theme }) => theme.colors.gray};
      font-size: ${({ theme }) => theme.fontSizes.s};
      ${({ theme }) => mobile(`font-size: ${theme.fontSizes.xs}`)}
    }
  }
`;

interface Props {
  onPageChange: (pageNumber: number) => void;
  currentPage: number;
}

const Header: React.FC<Props> = ({ onPageChange, currentPage }) => {
  return (
    <HeaderContainer>
      <div className="upper-header">
        <button
          onClick={() => onPageChange(1)}
          className={currentPage === 1 ? 'active' : ''}
        >
          Run A Report
        </button>
        <button
          onClick={() => onPageChange(2)}
          className={currentPage === 2 ? 'active' : ''}
        >
          Completed Reports
        </button>
      </div>
      <div className="lower-header">
        {currentPage === 1 && <h4 className="content">Run A Report</h4>}
        {currentPage === 2 && (
          <div className="lower header">
            <h4 className="content">5 completed Reports</h4>
            <span>Note: Completed reports will be deleted every 90 days</span>
          </div>
        )}
      </div>
    </HeaderContainer>
  );
};

export default Header;

import styled from '@emotion/styled';

const SearchInputContainer = styled.input`
  width: 100%;
  height: 60px;
  padding: 0 2rem 0 3rem;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  outline: none;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

interface props {
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const SearchInput: React.FC<props> = ({ placeholder, value, setValue }) => {
  return (
    <SearchInputContainer
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default SearchInput;
